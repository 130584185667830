import { useDispatch, useSelector } from 'react-redux';
import { loadActions } from '../../_actions';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const classes = {
	autocompleteStyle: {
		width: '25%',
		float: 'right',
		backgroundColor: 'white'
	},
    toolTipStyle: {
        color: '#2c88d9',
        float: 'right',
    }
};

function LoadTemplateSearch(props){
    const { companyId, setLoadTemplate } = props;
    const loading = useSelector(state => state.load.loading);
    const loadTemplatesList = useSelector(state => state.load.loadTemplatesList);
    const dispatch = useDispatch();

    return (
        <>
            <Tooltip arrow title="Create a new load based on a previous load" placement="top" sx={classes.toolTipStyle}>
                <IconButton>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <Autocomplete 
                disablePortal
                onOpen={() => {
                    if(loadTemplatesList.length === 0){
                        dispatch(loadActions.getLoadTemplatesList(companyId));
                    } 
                }}
                isOptionEqualToValue={(option, value) => option.Id === value.Id}
                getOptionLabel={(option) => option.Title}
                options={loadTemplatesList}
                loading={loading}
                onChange={(event, newValue) => {
                    setLoadTemplate(newValue);
                }}
                sx={classes.autocompleteStyle}
                renderInput={(params) => (
                    <TextField 
                        {...params} 
                        label='Load Templates'
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
            />
        </>
    );
}

export default LoadTemplateSearch;