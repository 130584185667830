import { activateAccountConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';


export const activateAccountActions = {
    validateActivationKey,
    resendActivationKey
};

function validateActivationKey(resetKey) {
    return dispatch => {
        dispatch(request());
        userService.validateActivationKey(resetKey)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Account has been activated!'));
                    }
                }
            )
            .catch(
                async error => { 
                    if(error.response){
                        dispatch(failure(JSON.stringify(error.response.data.Error.Message)));
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message)));
                    } else {
                        let errorMsg = "An error has occurred. Could not activate this account."
                        dispatch(failure(errorMsg));
                        dispatch(alertActions.error(errorMsg));
                    }
                }
            );
    }

    function request() { return { type: activateAccountConstants.VALIDATE_ACTIVATION_KEY_REQUEST } }
    function success() { return { type: activateAccountConstants.VALIDATE_ACTIVATION_KEY_SUCCESS } }
    function failure(error) { return { type: activateAccountConstants.VALIDATE_ACTIVATION_KEY_FAILURE, error } }
}

function resendActivationKey(resetKey) {
    return dispatch => {
        dispatch(request());
        userService.resendActivationKey(resetKey)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Activation key has been resent!'));
                        window.history.pushState({}, undefined, `/login`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => { 
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error('Unable to resend activation key'));
                    }
                }
            );
    }

    function request() { return { type: activateAccountConstants.RESEND_ACTIVATION_REQUEST } }
    function success() { return { type: activateAccountConstants.RESEND_ACTIVATION_SUCCESS } }
    function failure(error) { return { type: activateAccountConstants.RESEND_ACTIVATION_FAILURE, error } }
}