export const userConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    USER_LOGOUT: 'USER_LOGOUT',

    SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

    SIGN_UP_FROM_INVITE_REQUEST: 'SIGN_UP_FROM_INVITE_REQUEST',
    SIGN_UP_FROM_INVITE_SUCCESS: 'SIGN_UP_FROM_INVITE_SUCCESS',
    SIGN_UP_FROM_INVITE_FAILURE: 'SIGN_UP_FROM_INVITE_FAILURE',

    GET_WELCOME_SUMMARY_REQUEST: 'GET_WELCOME_SUMMARY_REQUEST',
    GET_WELCOME_SUMMARY_SUCCESS: 'GET_WELCOME_SUMMARY_SUCCESS',
    GET_WELCOME_SUMMARY_FAILURE: 'GET_WELCOME_SUMMARY_FAILURE',

    GET_PERSONAL_INFO_REQUEST: 'GET_PERSONAL_INFO_REQUEST',
    GET_PERSONAL_INFO_SUCCESS: 'GET_PERSONAL_INFO_SUCCESS',
    GET_PERSONAL_INFO_FAILURE: 'GET_PERSONAL_INFO_FAILURE',

    UPDATE_PERSONAL_INFO_REQUEST: 'UPDATE_PERSONAL_INFO_REQUEST',
    UPDATE_PERSONAL_INFO_SUCCESS: 'UPDATE_PERSONAL_INFO_SUCCESS',
    UPDATE_PERSONAL_INFO_FAILURE: 'UPDATE_PERSONAL_INFO_FAILURE',

    GET_COMPANY_INFO_REQUEST: 'GET_COMPANY_INFO_REQUEST',
    GET_COMPANY_INFO_SUCCESS: 'GET_COMPANY_INFO_SUCCESS',
    GET_COMPANY_INFO_FAILURE: 'GET_COMPANY_INFO_FAILURE',

    UPDATE_COMPANY_INFO_REQUEST: 'UPDATE_COMPANY_INFO_REQUEST',
    UPDATE_COMPANY_INFO_SUCCESS: 'UPDATE_COMPANY_INFO_SUCCESS',
    UPDATE_COMPANY_INFO_FAILURE: 'UPDATE_COMPANY_INFO_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    INVITE_USER_REQUEST: 'INVITE_USER_REQUEST',
    INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
    INVITE_USER_FAILURE: 'INVITE_USER_FAILURE',

    GET_USERS_TO_MANAGE_REQUEST: 'GET_USERS_TO_MANAGE_REQUEST',
    GET_USERS_TO_MANAGE_SUCCESS: 'GET_USERS_TO_MANAGE_SUCCESS',
    GET_USERS_TO_MANAGE_FAILURE: 'GET_USERS_TO_MANAGE_FAILURE',

    SEND_CONTACT_US_FORM_REQUEST: 'SEND_CONTACT_US_FORM_REQUEST',
    SEND_CONTACT_US_FORM_SUCCESS: 'SEND_CONTACT_US_FORM_SUCCESS',
    SEND_CONTACT_US_FORM_FAILURE: 'SEND_CONTACT_US_FORM_FAILURE',

    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    GET_COMPANY_LOGO_REQUEST: 'GET_COMPANY_LOGO_REQUEST',
    GET_COMPANY_LOGO_SUCCESS: 'GET_COMPANY_LOGO_SUCCESS',
    GET_COMPANY_LOGO_FAILURE: 'GET_COMPANY_LOGO_FAILURE',

    RESET_MANAGE_USER_PASSWORD_REQUEST: 'RESET_MANAGE_USER_PASSWORD_REQUEST',
    RESET_MANAGE_USER_PASSWORD_SUCCESS: 'RESET_MANAGE_USER_PASSWORD_SUCCESS',
    RESET_MANAGE_USER_PASSWORD_FAILURE: 'RESET_MANAGE_USER_PASSWORD_FAILURE',

    SEND_RESET_PASSWORD_LINK_REQUEST: 'SEND_RESET_PASSWORD_LINK_REQUEST',
    SEND_RESET_PASSWORD_LINK_SUCCESS: 'SEND_RESET_PASSWORD_LINK_SUCCESS',
    SEND_RESET_PASSWORD_LINK_FAILURE: 'SEND_RESET_PASSWORD_LINK_FAILURE',

    VALIDATE_RESET_PASSWORD_KEY_REQUEST: 'VALIDATE_RESET_PASSWORD_KEY_REQUEST',
    VALIDATE_RESET_PASSWORD_KEY_SUCCESS: 'VALIDATE_RESET_PASSWORD_KEY_SUCCESS',
    VALIDATE_RESET_PASSWORD_KEY_FAILURE: 'VALIDATE_RESET_PASSWORD_KEY_FAILURE',

    RESET_PASSWORD_WITH_KEY_REQUEST: 'RESET_PASSWORD_WITH_KEY_REQUEST',
    RESET_PASSWORD_WITH_KEY_SUCCESS: 'RESET_PASSWORD_WITH_KEY_SUCCESS',
    RESET_PASSWORD_WITH_KEY_FAILURE: 'RESET_PASSWORD_WITH_KEY_FAILURE',
};