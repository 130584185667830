import React from 'react';
import { Navigate } from 'react-router-dom';


function PrivateRoute({ children }) {
    // return localStorage.getItem('user') 
    //     ?   JSON.parse(localStorage.getItem('user')).Active === 'False' ? <Navigate to="/notactive" /> : <>{children}</>
    //     :   <Navigate to="/login" />;
    if(localStorage.getItem('user')){
        if(JSON.parse(localStorage.getItem('user')).Active === 'False'){
            window.history.pushState({}, undefined, `/notactive`);
            window.location.reload();
            //<Navigate to="/notactive" />
        }else{
            return <>{children}</>;
        }
    }else{
        window.history.pushState({}, undefined, `/login`);
        window.location.reload();
        //<Navigate to="/login" />;
    }
}

export { PrivateRoute };