import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { numberWithCommas } from '../_helpers';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

function PriceIndicatorBar({ lowerBound, upperBound, userQuote }) {
    // Calculate percentage position of the user's quote on the bar
    const calculatePosition = (quote) => {
        if (quote <= lowerBound) return 0; // Position at the start if quote is less than or equal to lower bound
        if (quote >= upperBound) return 100; // Position at the end if quote is greater than or equal to upper bound
        const percentage = ((quote - lowerBound) / (upperBound - lowerBound)) * 100;
        return percentage; // Ensure it's between 0% and 100%
    };

    const circlePosition = calculatePosition(userQuote);

    const IconWithTooltip = () => (
        <Tooltip arrow title="Click on a row in the Quotes Received table to see how the quote compares to others" placement="top" sx={{ color: '#2c88d9' }}>
                <InfoIcon />
        </Tooltip>
    );

    return (
        <Box sx={{ width: '100%'}}>
            {/* Label above the bar */}
            <Typography variant="h6" sx={{ mb: 5, textAlign: 'center' }}>
                Selected Quote Compared to Others <span><IconWithTooltip /></span>
            </Typography>
    
            {/* Container for the circle and bar */}
            <Box sx={{ position: 'relative', width: '100%' }}>
                {/* Text displaying userQuote above the circle */}
                {userQuote !== 'N/A' &&
                <Typography
                    variant="body1"
                    sx={{
                        position: 'absolute',
                        left: circlePosition === 100 ? 'calc(100% - 15px)' : `${circlePosition + 1}%`,
                        transform: 'translateX(-50%)',
                        bottom: '20px', // Adjust the vertical position above the circle
                        color: 'black',
                        fontWeight: 'bold',
                    }}
                >
                    ${numberWithCommas(userQuote)}
                </Typography>
                }

                {/* Circle representing user quote */}
                {userQuote !== 'N/A' &&
                <Box
                    sx={{
                    position: 'absolute',
                    left: circlePosition === 100 ? 'calc(100% - 15px)' : `${circlePosition}%`, // Adjust position for right edge
                    transform: 'translateX(-50%)', // Center the circle
                    top: '50%', // Center vertically
                    width: '25px', // Circle diameter
                    height: '25px', // Circle diameter
                    borderRadius: '50%',
                    border: '5px solid #1A74E7', // Thicker blue outline
                    backgroundColor: 'white',
                    transform: 'translateY(-50%)', // Center vertically along the bar
                    }}
                />
                }
    
                {/* Bar container with solid colors */}
                <Box sx={{ display: 'flex', height: '10px', borderRadius: '5px', overflow: 'hidden' }}>
                    {/* Green Section */}
                    <Box
                        sx={{
                            flex: 1,
                            backgroundColor: '#34A853', // Solid green
                        }}
                    />
                    {/* Yellow Section */}
                    <Box
                        sx={{
                            flex: 1,
                            backgroundColor: '#FCBC07', // Solid yellow
                        }}
                    />
                    {/* Red Section */}
                    <Box
                        sx={{
                            flex: 1,
                            backgroundColor: '#E94335', // Solid red
                        }}
                    />
                </Box>
    
                {/* Labels for Good and Bad */}
                <Typography
                    variant="body2"
                    sx={{ position: 'absolute', left: '-120px', top: '50%', transform: 'translateY(-50%)', color: '#1A74E7' }}
                >
                    Lowest (${numberWithCommas(lowerBound)})
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ position: 'absolute', right: '-135px', top: '50%', transform: 'translateY(-50%)', color: '#E94335' }}
                >
                    Highest (${numberWithCommas(upperBound)})
                </Typography>
            </Box>
        </Box>
    );
}

export default PriceIndicatorBar;