import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { compareUtil } from '../../_helpers';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import LinearProgress from '@mui/material/LinearProgress';
import EditUserModal from './EditUserModal';

const headCells = [
    { id: 'FirstName', numeric: false, label: 'First Name' },
    { id: 'LastName', numeric: false, label: 'Last Name' },
    { id: 'Email', numeric: false, label: 'Email' },
    { id: 'PhoneNumber', numeric: false, label: 'Phone Number' },
    { id: 'Role', numeric: false, label: 'Role' },
    { id: 'ResetPassword', numeric: false, label: 'Reset Password' },
];

const classes = {
    usersTablePaper: {
        marginBottom: 5
    },
    editUserText: {
        float: 'right'
    },
    formPaper: {
        padding: 2,
        width: '75%',
    },
    currentUsersHeader: {
        fontSize: 20
    },
    tableStyle: {
        whiteSpace: 'nowrap',
        maxHeight: 500
    },
    tableHeaderStyle: {
		fontWeight: 'bold'
	},
    inviteUserHeader: {
        fontSize: 20
    },
    formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
    sendInviteButton: {
		textTransform: 'none',
		left: '20%',
		paddingLeft: 3,
		paddingRight: 3,
		marginTop: 3,
        backgroundColor: '#1565C0',
		'&:hover': {
			backgroundColor: '#1565C0',
		},
	},
    resetPasswordButton: {
        textTransform: 'none',
        backgroundColor: 'green',
		'&:hover': {
			backgroundColor: 'green',
		},
    }
};

const invalidDomains = ['gmail', 'yahoo', 'hotmail', 'outlook', 'icloud', 'aol', 'msn'];

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email is required')
        .matches(/.+@.+\..+/, 'Please enter a valid email')
        .test(
			'Email Domain Mismatch',
			'The email domain of the user being invited must match your email domain',
			function test(value, {path}) {
				const { user } = this.options.context;

				// Logic with the accessed variable context

                // This is the full domain of the invitee
                const inviteeDomainFull = value.substring(
                    value.indexOf('@') + 1,
                );

                // This is the full domain of the person sending the invite
                const inviterDomainFull = user.UserId.substring(
                    user.UserId.indexOf('@') + 1,
                );

				return inviteeDomainFull === inviterDomainFull;
			}
		)
        .test(
            'Unauthorized Email Domain',
            'An unauthorized domain was entered. Please use your work email and avoid personal email services',
            (value) => {
                const currentDomain = value.substring(
                    value.indexOf('@') + 1,
                    value.indexOf('.')
                );

                return !invalidDomains.includes(currentDomain);
            }
        ),
});

function ManageUsers() {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('LastName');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    // Next 4 are for editing Users
	const [openEditUserModal, setOpenEditUserModal] = useState(false);
	const handleOpenEditUserModal = () => setOpenEditUserModal(true);
	//const handleCloseEditUserModal = () => setOpenEditUserModal(false);
	const [currentUser, setCurrentUser] = useState({});
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const usersToManage = useSelector(state => state.authentication.usersToManage);
    const loading = useSelector(state => state.authentication.loading);

    const { control, handleSubmit, reset, register, watch, formState: { errors, submitCount }, getValues, setValue, trigger } = useForm({
        resolver: yupResolver(validationSchema),
        context: {
            user, // Variable being passed to Yup schema
        },
    });

    useEffect(() => { 
        dispatch(userActions.getUsersToManage(user.CompanyId));
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onRequestSort = (event, property) => {
        handleRequestSort(event, property);
    };

    const inviteUser = (data) => {
        data.inviteFromEmail = user.UserId;
        data.companyId = user.CompanyId;
        dispatch(userActions.inviteUser(data));
    };

    const openEditUserForm = userToEdit => {
        handleOpenEditUserModal();
        setCurrentUser({...userToEdit});
	};

    const handleCloseEditUserModal = (event, reason) => {
        if(reason && reason === "backdropClick") {
            return;
        }
        
        setOpenEditUserModal(false);
    };

    const resetManageUserPassword = (email) => {
        dispatch(userActions.resetManageUserPassword(email));
    };

    return (
        <Container>
            {user.Role === "ADMIN" ?
                !loading && usersToManage.length > 0 ?
                <>
                    <Typography sx={classes.editUserText}>Click on a row to edit user</Typography>
                    <Typography sx={classes.currentUsersHeader}>Current Users: {usersToManage.length}</Typography>
                    <Paper sx={classes.usersTablePaper}>
                        <TableContainer sx={classes.tableStyle}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {headCells.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={'left'}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={createSortHandler(headCell.id)}
                                                    sx={classes.tableHeaderStyle}
                                                >
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {usersToManage && usersToManage.length > 0 ?
                                        compareUtil.stableSort(usersToManage, compareUtil.getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <TableRow hover sx={{ cursor: 'pointer' }} key={row.Id}>
                                                <TableCell align="left" onClick={() => openEditUserForm(row)}>{row.FirstName}</TableCell>
                                                <TableCell align="left" onClick={() => openEditUserForm(row)}>{row.LastName}</TableCell>
                                                <TableCell align="left" onClick={() => openEditUserForm(row)}>{row.Email}</TableCell>
                                                <TableCell align="left" onClick={() => openEditUserForm(row)}>{row.PhoneNumber}</TableCell>
                                                <TableCell align="left" onClick={() => openEditUserForm(row)}>{row.Role}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        type="button"
                                                        variant="contained"
                                                        sx={classes.resetPasswordButton}
                                                        onClick={() => resetManageUserPassword(row.Email)}
                                                    > 
                                                        Reset Password
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={usersToManage.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </>
                : <LinearProgress />
            : null}
            <Typography sx={classes.inviteUserHeader}>Invite Users</Typography>
            <Paper sx={classes.formPaper}>
                <form onSubmit={handleSubmit(inviteUser)}>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Email" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.email ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.email?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    {!loading ? 
                    <Button
                        type="submit"
                        variant="contained"
                        sx={classes.sendInviteButton}
                        startIcon={<AddIcon sx={{marginRight: -0.5}}/>}
                    > 
                        Send Invite
                    </Button>
                    : <LinearProgress />}
                </form>
                <EditUserModal openEditUserModal={openEditUserModal} handleCloseEditUserModal={handleCloseEditUserModal} currentUser={currentUser} />
            </Paper>
        </Container>
    );
}

export default ManageUsers;