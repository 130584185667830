import React, { useEffect, useState, useMemo } from 'react';
import { compareUtil } from '../../_helpers';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import PickupModal from './PickupModal';
import EditPickupModal from './EditPickupModal';

const headCells = [
    {id: 'pickupRefNum', numeric: true, label: 'Pickup Ref #'},
    {id: 'companyName', numeric: false, label: 'Company Name'},
    {id: 'pickupDate', numeric: false, label: 'Pickup Date'},
    {id: 'pickupPalletCount', numeric: true, label: 'Pallet Count'},
    {id: 'pickupWeight', numeric: true, label: 'Weight (Lbs)'},
    {id: 'pickupCity', numeric: false, label: 'City'},
    {id: 'address1', numeric: false, label: 'Address 1'},
    {id: 'address2', numeric: false, label: 'Address 2'},
    {id: 'pickupPostalZip', numeric: false, label: 'Postal/Zip Code'},
    {id: 'pickupStateProv', numeric: false, label: 'Province/State'},
    {id: 'pickupCountry', numeric: false, label: 'Country'},
    {id: 'contactName', numeric: false, label: 'Contact Name'},
    {id: 'contactPhoneNumber', numeric: false, label: 'Contact Phone'},
    {id: 'contactEmail', numeric: false, label: 'Contact Email'},
    {id: 'pickupContactInfo', numeric: false, label: 'Contact Info'},
    {id: 'pickupInstructions', numeric: false, label: 'Instructions'},
];

const classes = {
    tableSection: {
        marginBottom: 2
    },
    pickupListHeader: {
        fontSize: 20
    },
	modalButton: {
		textTransform: 'none',
		margin: 1,
        backgroundColor: '#0D9276',
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	},
    tableStyle: {
        whiteSpace: 'nowrap',
        maxHeight: 335
    },
	tableHeaderStyle: {
		fontWeight: 'bold'
	}
};

function PickupList(props) {
    const { pickups, setPickups, validatePickups } = props;
    // Next 3 are for opening/closing the modals used to add Pickups
    const [openPickupModal, setOpenPickupModal] = useState(false);
    const handleOpenPickupModal = () => setOpenPickupModal(true);
    const handleClosePickupModal = () => setOpenPickupModal(false);
    // Next 4 are for editing Pickups
	const [openEditPickupModal, setOpenEditPickupModal] = useState(false);
	const handleOpenEditPickupModal = () => setOpenEditPickupModal(true);
	// const handleCloseEditPickupModal = () => setOpenEditPickupModal(false);
	const [currentPickup, setCurrentPickup] = useState({});
    // Standard React state variables
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('pickupDate');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const openEditPickupForm = pickup => {
		handleOpenEditPickupModal();
		setCurrentPickup({...pickup});
	};

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onRequestSort = (event, property) => {
        handleRequestSort(event, property);
    };

    const handleCloseEditPickupModal = (event, reason) => {
        if(reason && reason === "backdropClick") {
            return;
        }

        setOpenEditPickupModal(false);
    };
    
    return (
        <>
            <Typography sx={classes.pickupListHeader}>Pickup List</Typography>
            <Paper elevation={3} sx={classes.tableSection}>
                <Button sx={classes.modalButton} variant="contained" startIcon={<AddIcon sx={{marginRight: -0.5}}/>} onClick={handleOpenPickupModal}>Add</Button>
                <TableContainer sx={classes.tableStyle}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={'left'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                            sx={classes.tableHeaderStyle}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pickups && pickups.length > 0 ?
                            compareUtil.stableSort(pickups, compareUtil.getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow hover sx={{ cursor: 'pointer' }} key={row.id} onClick={() => openEditPickupForm(row)}>
                                    <TableCell align="left">{row.pickupRefNum ? row.pickupRefNum : ''}</TableCell>
                                    <TableCell align="left">{row.companyName}</TableCell>
                                    <TableCell align="left">{row.pickupDate ? dayjs(row.pickupDate).format('MMM DD, YYYY') : ''}</TableCell>
                                    <TableCell align="left">{row.pickupPalletCount ? row.pickupPalletCount : ''}</TableCell>
                                    <TableCell align="left">{row.pickupWeight ? row.pickupWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</TableCell>
                                    <TableCell align="left">{row.pickupCity}</TableCell>
                                    <TableCell align="left">{row.address1}</TableCell>
                                    <TableCell align="left">{row.address2}</TableCell>
                                    <TableCell align="left">{row.pickupPostalZip}</TableCell>
                                    <TableCell align="left">{row.pickupStateProv}</TableCell>
                                    <TableCell align="left">{row.pickupCountry}</TableCell>
                                    <TableCell align="left">{row.contactName}</TableCell>
                                    <TableCell align="left">{row.contactPhoneNumber}</TableCell>
                                    <TableCell align="left">{row.contactEmail}</TableCell>
                                    <TableCell align="left">{row.pickupContactInfo}</TableCell>
                                    <TableCell align="left">{row.pickupInstructions}</TableCell>
                                </TableRow>
                            ))
                            : null}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={pickups.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <PickupModal openPickupModal={openPickupModal} handleClosePickupModal={handleClosePickupModal} pickups={pickups} setPickups={setPickups} validatePickups={validatePickups}/>
            <EditPickupModal openEditPickupModal={openEditPickupModal} handleCloseEditPickupModal={handleCloseEditPickupModal} pickups={pickups} setPickups={setPickups} currentPickup={currentPickup} validatePickups={validatePickups}/>
        </>
    );
}

export default PickupList;