import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { jwtDecode } from "jwt-decode";

export const userActions = {
    login,
    logout,
    signUp,
    signUpFromInvite,
    getWelcomeSummary,
    getPersonalInfo,
    updatePersonalInfo,
    getCompanyInfo,
    updateCompanyInfo,
    resetPassword,
    inviteUser,
    getUsersToManage,
    sendContactUsForm,
    editUser,
    deleteUser,
    getCompanyLogo,
    resetManageUserPassword,
    sendResetPasswordLink,
    validateResetPasswordKey,
    resetPasswordWithKey
};

function login(userID, password) {
    return dispatch => {
        dispatch(request());
        userService.login(userID, password)
            .then(
                async response => {
                    if(response.status === 200){
                        var decodedJwt = jwtDecode(response.data.JwtToken);
                        var encodedJwt = response.data.JwtToken;
                        localStorage.setItem('user', JSON.stringify(decodedJwt));
                        localStorage.setItem('jwtBearer', JSON.stringify(encodedJwt));
                        dispatch(success(decodedJwt));
                        dispatch(alertActions.success('Log in was successful!'));
                        window.history.pushState({}, undefined, `/welcome`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure() { return { type: userConstants.LOGIN_FAILURE } }
}

function logout() {
    userService.logout();
    return { type: userConstants.USER_LOGOUT };
}

function signUp(data) {
    return dispatch => {
        dispatch(request());
        userService.signUp(data)
            .then(
                async response => {
                    if(response.ok){
                        dispatch(success());
                        dispatch(alertActions.success('Sign up was successful!'));
                        window.history.pushState({}, undefined, `/login`);
                        window.location.reload();
                    }else{
                        let body = await response.json();
                        dispatch(failure(response));
                        dispatch(alertActions.error(JSON.stringify(body.Error.Message)));
                    }
                }
            )
    }

    function request() { return { type: userConstants.SIGN_UP_REQUEST } }
    function success() { return { type: userConstants.SIGN_UP_SUCCESS } }
    function failure() { return { type: userConstants.SIGN_UP_FAILURE } }
}

function signUpFromInvite(data) {
    return dispatch => {
        dispatch(request());
        userService.signUpFromInvite(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Sign up was successful!'));
                        window.history.pushState({}, undefined, `/login`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.SIGN_UP_FROM_INVITE_REQUEST } }
    function success() { return { type: userConstants.SIGN_UP_FROM_INVITE_SUCCESS } }
    function failure() { return { type: userConstants.SIGN_UP_FROM_INVITE_FAILURE } }
}

function getWelcomeSummary(companyId) {
    return dispatch => {
        dispatch(request());
        userService.getWelcomeSummary(companyId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.GET_WELCOME_SUMMARY_REQUEST } }
    function success(welcomeSummary) { return { type: userConstants.GET_WELCOME_SUMMARY_SUCCESS, welcomeSummary } }
    function failure() { return { type: userConstants.GET_WELCOME_SUMMARY_FAILURE } }
}

function getPersonalInfo(email) {
    return dispatch => {
        dispatch(request());
        userService.getPersonalInfo(email)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.GET_PERSONAL_INFO_REQUEST } }
    function success(personalInfo) { return { type: userConstants.GET_PERSONAL_INFO_SUCCESS, personalInfo } }
    function failure() { return { type: userConstants.GET_PERSONAL_INFO_FAILURE } }
}

function updatePersonalInfo(data) {
    return dispatch => {
        dispatch(request());
        userService.updatePersonalInfo(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Personal information has been updated!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.UPDATE_PERSONAL_INFO_REQUEST } }
    function success() { return { type: userConstants.UPDATE_PERSONAL_INFO_SUCCESS } }
    function failure() { return { type: userConstants.UPDATE_PERSONAL_INFO_FAILURE } }
}

function getCompanyInfo(email) {
    return dispatch => {
        dispatch(request());
        userService.getCompanyInfo(email)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.GET_COMPANY_INFO_REQUEST } }
    function success(companyInfo) { return { type: userConstants.GET_COMPANY_INFO_SUCCESS, companyInfo } }
    function failure() { return { type: userConstants.GET_COMPANY_INFO_FAILURE } }
}

function updateCompanyInfo(data) {
    return dispatch => {
        dispatch(request());
        userService.updateCompanyInfo(data)
            .then(
                async response => {
                    if(response.ok){
                        dispatch(success());
                        dispatch(alertActions.success('Company information has been updated!'));
                        window.location.reload();
                    }else{
                        let body = await response.json();
                        dispatch(failure(response));
                        dispatch(alertActions.error(JSON.stringify(body.Error.Message)));
                    }
                }
            )
    }

    function request() { return { type: userConstants.UPDATE_COMPANY_INFO_REQUEST } }
    function success() { return { type: userConstants.UPDATE_COMPANY_INFO_SUCCESS } }
    function failure() { return { type: userConstants.UPDATE_COMPANY_INFO_FAILURE } }
}

function resetPassword(data) {
    return dispatch => {
        dispatch(request());
        userService.resetPassword(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Password has been reset!'));
                        window.history.pushState({}, undefined, `/login`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.RESET_PASSWORD_REQUEST } }
    function success() { return { type: userConstants.RESET_PASSWORD_SUCCESS } }
    function failure() { return { type: userConstants.RESET_PASSWORD_FAILURE } }
}

function inviteUser(data) {
    return dispatch => {
        dispatch(request());
        userService.inviteUser(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('User has been invited!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.INVITE_USER_REQUEST } }
    function success() { return { type: userConstants.INVITE_USER_SUCCESS } }
    function failure() { return { type: userConstants.INVITE_USER_FAILURE } }
}

function getUsersToManage(companyId) {
    return dispatch => {
        dispatch(request());
        userService.getUsersToManage(companyId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.GET_USERS_TO_MANAGE_REQUEST } }
    function success(usersToManage) { return { type: userConstants.GET_USERS_TO_MANAGE_SUCCESS, usersToManage } }
    function failure() { return { type: userConstants.GET_USERS_TO_MANAGE_FAILURE } }
}

function sendContactUsForm(data) {
    return dispatch => {
        dispatch(request());
        userService.sendContactUsForm(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Inquiry has been sent!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.SEND_CONTACT_US_FORM_REQUEST } }
    function success() { return { type: userConstants.SEND_CONTACT_US_FORM_SUCCESS } }
    function failure() { return { type: userConstants.SEND_CONTACT_US_FORM_FAILURE } }
}

function editUser(data) {
    return dispatch => {
        dispatch(request());
        userService.editUser(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('User has been edited!'));
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.EDIT_USER_REQUEST } }
    function success() { return { type: userConstants.EDIT_USER_SUCCESS } }
    function failure() { return { type: userConstants.EDIT_USER_FAILURE } }
}

function deleteUser(userId) {
    return dispatch => {
        dispatch(request());
        userService.deleteUser(userId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('User has been deleted!'));
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.DELETE_USER_REQUEST } }
    function success() { return { type: userConstants.DELETE_USER_SUCCESS } }
    function failure() { return { type: userConstants.DELETE_USER_FAILURE } }
}

function getCompanyLogo(companyId) {
    return dispatch => {
        dispatch(request());
        userService.getCompanyLogo(companyId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        //dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.GET_COMPANY_LOGO_REQUEST } }
    function success(companyLogo) { return { type: userConstants.GET_COMPANY_LOGO_SUCCESS, companyLogo } }
    function failure() { return { type: userConstants.GET_COMPANY_LOGO_FAILURE } }
}

function resetManageUserPassword(email) {
    return dispatch => {
        dispatch(request());
        userService.resetManageUserPassword(email)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('An email has been sent to the user to reset their password!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.RESET_MANAGE_USER_PASSWORD_REQUEST } }
    function success() { return { type: userConstants.RESET_MANAGE_USER_PASSWORD_SUCCESS } }
    function failure() { return { type: userConstants.RESET_MANAGE_USER_PASSWORD_FAILURE } }
}

function sendResetPasswordLink(data) {
    return dispatch => {
        dispatch(request());
        userService.sendResetPasswordLink(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('A link to reset your password has been emailed!'));
                        window.history.pushState({}, undefined, `/login`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.SEND_RESET_PASSWORD_LINK_REQUEST } }
    function success() { return { type: userConstants.SEND_RESET_PASSWORD_LINK_SUCCESS } }
    function failure() { return { type: userConstants.SEND_RESET_PASSWORD_LINK_FAILURE } }
}

function validateResetPasswordKey(resetKey) {
    return dispatch => {
        dispatch(request());
        userService.validateResetPasswordKey(resetKey)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure(JSON.stringify(error.response.data.Error.Message)));
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.VALIDATE_RESET_PASSWORD_KEY_REQUEST } }
    function success() { return { type: userConstants.VALIDATE_RESET_PASSWORD_KEY_SUCCESS } }
    function failure(error) { return { type: userConstants.VALIDATE_RESET_PASSWORD_KEY_FAILURE, error } }
}

function resetPasswordWithKey(data, key) {
    return dispatch => {
        dispatch(request());
        userService.resetPasswordWithKey(data, key)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Password changed. Please login with new password.'));
                        window.history.pushState({}, undefined, `/login`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.RESET_PASSWORD_WITH_KEY_REQUEST } }
    function success() { return { type: userConstants.RESET_PASSWORD_WITH_KEY_SUCCESS } }
    function failure() { return { type: userConstants.RESET_PASSWORD_WITH_KEY_FAILURE } }
}