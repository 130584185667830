export const loadConstants = {
    CREATE_LOAD_REQUEST: 'CREATE_LOAD_REQUEST',
    CREATE_LOAD_SUCCESS: 'CREATE_LOAD_SUCCESS',
    CREATE_LOAD_FAILURE: 'CREATE_LOAD_FAILURE',

    GET_LOAD_TEMPLATES_LIST_REQUEST: 'GET_LOAD_TEMPLATES_LIST_REQUEST',
    GET_LOAD_TEMPLATES_LIST_SUCCESS: 'GET_LOAD_TEMPLATES_LIST_SUCCESS',
    GET_LOAD_TEMPLATES_LIST_FAILURE: 'GET_LOAD_TEMPLATES_LIST_FAILURE',

    GET_LOAD_TEMPLATE_DETAILS_REQUEST: 'GET_LOAD_TEMPLATE_DETAILS_REQUEST',
    GET_LOAD_TEMPLATE_DETAILS_SUCCESS: 'GET_LOAD_TEMPLATE_DETAILS_SUCCESS',
    GET_LOAD_TEMPLATE_DETAILS_FAILURE: 'GET_LOAD_TEMPLATE_DETAILS_FAILURE',

    GET_PICKUP_TEMPLATES_LIST_REQUEST: 'GET_PICKUP_TEMPLATES_LIST_REQUEST',
    GET_PICKUP_TEMPLATES_LIST_SUCCESS: 'GET_PICKUP_TEMPLATES_LIST_SUCCESS',
    GET_PICKUP_TEMPLATES_LIST_FAILURE: 'GET_PICKUP_TEMPLATES_LIST_FAILURE',

    GET_PICKUP_TEMPLATE_DETAILS_REQUEST: 'GET_PICKUP_TEMPLATE_DETAILS_REQUEST',
    GET_PICKUP_TEMPLATE_DETAILS_SUCCESS: 'GET_PICKUP_TEMPLATE_DETAILS_SUCCESS',
    GET_PICKUP_TEMPLATE_DETAILS_FAILURE: 'GET_PICKUP_TEMPLATE_DETAILS_FAILURE',

    GET_DELIVERY_TEMPLATES_LIST_REQUEST: 'GET_DELIVERY_TEMPLATES_LIST_REQUEST',
    GET_DELIVERY_TEMPLATES_LIST_SUCCESS: 'GET_DELIVERY_TEMPLATES_LIST_SUCCESS',
    GET_DELIVERY_TEMPLATES_LIST_FAILURE: 'GET_DELIVERY_TEMPLATES_LIST_FAILURE',

    GET_DELIVERY_TEMPLATE_DETAILS_REQUEST: 'GET_DELIVERY_TEMPLATE_DETAILS_REQUEST',
    GET_DELIVERY_TEMPLATE_DETAILS_SUCCESS: 'GET_DELIVERY_TEMPLATE_DETAILS_SUCCESS',
    GET_DELIVERY_TEMPLATE_DETAILS_FAILURE: 'GET_DELIVERY_TEMPLATE_DETAILS_FAILURE',

    GET_COMPANY_LOADS_REQUEST: 'GET_COMPANY_LOADS_REQUEST',
    GET_COMPANY_LOADS_SUCCESS: 'GET_COMPANY_LOADS_SUCCESS',
    GET_COMPANY_LOADS_FAILURE: 'GET_COMPANY_LOADS_FAILURE',

    GET_LOAD_DETAILS_REQUEST: 'GET_LOAD_DETAILS_REQUEST',
    GET_LOAD_DETAILS_SUCCESS: 'GET_LOAD_DETAILS_SUCCESS',
    GET_LOAD_DETAILS_FAILURE: 'GET_LOAD_DETAILS_FAILURE',

    EDIT_LOAD_DETAILS_REQUEST: 'EDIT_LOAD_DETAILS_REQUEST',
    EDIT_LOAD_DETAILS_SUCCESS: 'EDIT_LOAD_DETAILS_SUCCESS',
    EDIT_LOAD_DETAILS_FAILURE: 'EDIT_LOAD_DETAILS_FAILURE',

    CANCEL_LOAD_REQUEST: 'CANCEL_LOAD_REQUEST',
    CANCEL_LOAD_SUCCESS: 'CANCEL_LOAD_SUCCESS',
    CANCEL_LOAD_FAILURE: 'CANCEL_LOAD_FAILURE',

    DELETE_LOAD_REQUEST: 'DELETE_LOAD_REQUEST',
    DELETE_LOAD_SUCCESS: 'DELETE_LOAD_SUCCESS',
    DELETE_LOAD_FAILURE: 'DELETE_LOAD_FAILURE',

    EDIT_PICKUPS_AND_DELIVERIES_REQUEST: 'EDIT_PICKUPS_AND_DELIVERIES_REQUEST',
    EDIT_PICKUPS_AND_DELIVERIES_SUCCESS: 'EDIT_PICKUPS_AND_DELIVERIES_SUCCESS',
    EDIT_PICKUPS_AND_DELIVERIES_FAILURE: 'EDIT_PICKUPS_AND_DELIVERIES_FAILURE',

    ADD_PICKUP_TO_DELETE: 'ADD_PICKUP_TO_DELETE',
    ADD_DELIVERY_TO_DELETE: 'ADD_DELIVERY_TO_DELETE',
    CLEAR_PICKUPS_DELIVERIES_TO_DELETE: 'CLEAR_PICKUPS_DELIVERIES_TO_DELETE',
};