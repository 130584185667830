import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadActions } from '../../_actions';
import { provinceList } from '../../_helpers';
import { stateList } from '../../_helpers';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import NumericFormatCustom from '../NumericFormatCustom';
import { phoneNumberAutoFormat } from '../../_helpers';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const classes = {
	formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
	datePickerStyle: {
        marginTop: 2,
    },
    // ORIGINAL STYLING
	// modalStyle: {
	// 	position: 'absolute',
	// 	top: '50%',
	// 	left: '50%',
	// 	transform: 'translate(-50%, -50%)',
	// 	width: 600,
	// 	bgcolor: 'background.paper',
	// 	border: '2px solid #000',
	// 	boxShadow: 24,
	// 	p: 4,
	// },
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Default width for smaller screens
        maxWidth: 600, // Maximum width for larger screens
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '95vh', // Maximum height set to 95% of viewport height
        overflowY: 'auto', // Enable vertical scrolling if content overflows
        '@media (min-width: 600px)': { // Media query for larger screens
          width: 600,
        },
    },
    closeIcon: {
        float: 'right'
    },
	addButton: {
		textTransform: 'none',
		left: '37%',
		paddingLeft: 3,
		paddingRight: 3,
		marginTop: 3
	},
	saveButton: {
		marginRight: 2,
		marginLeft: '16%',
		paddingLeft: 3,
		paddingRight: 3,
		textTransform: 'none',
        backgroundColor: '#0D9276',
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	},
	cancelButton: {
		textTransform: 'none',
		marginRight: 2,
	},
	deleteButton: {
		textTransform: 'none',
	},
};

const validationSchema5 = Yup.object().shape({
    deliveryCity: Yup.string()
        .required('City is required'),
	deliveryRefNum: Yup.string()
        .required('Delivery ref # is required'),
    companyName: Yup.string()
        .required('Company name is required'),
    address1: Yup.string()
        .required('Address 1 is required'),
    deliveryPostalZip: Yup.string()
        .required('Postal/Zip Code is required'),
    deliveryStateProv: Yup.string()
        .required('Province/State is required'),
    deliveryCountry: Yup.string()
        .required('Country is required'),
    contactName: Yup.string()
        .required('Contact Name is required'),
    contactEmail: Yup.string()
        .required('Contact Email is required'),
    contactPhoneNumber: Yup.string()
        .required('Contact Phone is required'),
	deliveryDate: Yup.date()
        .required('Delivery date is required')
		.typeError('Date is required'),
	deliveryPalletCount: Yup.number()
		.typeError('Please enter a number')
		.test(
            'ends-with-0-or-.5',
            'Pallet count must contain 2 decimal points maximum',
            (number) => Number.isInteger(number * (10 ** 2))
        ),
	deliveryWeight:  Yup.number()
        .max(60000, 'Weight has exceeded the maximum limit')
		.typeError('Please enter a number'),
	deliveryContactInfo: Yup.string()
        .required('Contact info is required'),
	// deliveryInstructions: Yup.string()
    //     .required('Instructions is required'),
});

function EditDeliveryModal(props){
    const { openEditDeliveryModal, handleCloseEditDeliveryModal, deliveries, setDeliveries, currentDelivery, validateDeliveries } = props;
    const [selectedCountry, setSelectedCountry] = useState('Canada');
    const dispatch = useDispatch();

	const { control: control5, handleSubmit: handleSubmit5, reset: reset5, register: register5, watch: watch5, formState: { errors: errors5, submitCount: submitCount5 }, getValues: getValues5, setValue: setValue5, trigger: trigger5} = useForm({
        resolver: yupResolver(validationSchema5)
    });

    useEffect(() => { 
        if(selectedCountry){
            renderProvinceStateList();
        }
	}, [selectedCountry]);

    useEffect(() => { 
        if(currentDelivery){
            setSelectedCountry(currentDelivery.deliveryCountry);
        }
	}, [currentDelivery]);

    const renderProvinceStateList = () => {
		if(selectedCountry === 'Canada'){
			return provinceList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Code }>{ state.Name }</MenuItem>);
			});
		}else if(selectedCountry === 'USA'){
			return stateList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Code }>{ state.Name }</MenuItem>);
			});
		}
    };

	const editDelivery = data => {
		if(data.deliveryDate){
			data.deliveryDate = data.deliveryDate.toJSON();
		}
		const updatedDeliveries = deliveries.map((delivery) => 
			delivery.id === data.id ? data : delivery
		);

		setDeliveries(updatedDeliveries);
        validateDeliveries(updatedDeliveries);
		reset5();
		handleCloseEditDeliveryModal();
	};

	const cancelEditDelivery = () => {
		reset5();
		handleCloseEditDeliveryModal();
	};

	const deleteDelivery = () => {
        if(window.confirm('Are you sure you want to delete this delivery?')){
            setDeliveries(deliveries.filter(d => d.id !== currentDelivery.id));
            const updatedDeliveries = deliveries.filter(d => d.id !== currentDelivery.id);
            validateDeliveries(updatedDeliveries);
            dispatch(loadActions.addDeliveryToDelete(currentDelivery.id));
            reset5();
            handleCloseEditDeliveryModal();
        }
	};

    const onPhoneNumberChange = ({target: {value}}) => {
        let formattedNumber = phoneNumberAutoFormat(value);
        setValue5('contactPhoneNumber', formattedNumber);
    };

    const closeIconClick = () => {
        reset5();
        handleCloseEditDeliveryModal();
    };

    return (
        <Modal open={openEditDeliveryModal} onClose={handleCloseEditDeliveryModal}>
            <Box sx={classes.modalStyle}>
                <IconButton onClick={() => closeIconClick()} sx={classes.closeIcon}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h4" sx={{textAlign:'center'}}>Edit Delivery Details</Typography>
                <form key={5} onSubmit={handleSubmit5(editDelivery)}>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="id"
                                control={control5}
                                defaultValue={currentDelivery.id}
                                render={({ field }) => 
                                    <TextField 
                                        type="hidden"
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={{display: 'none'}} 
                                        {...field} 
                                    />
                                }
                            />
                            <Controller
                                name="deliveryRefNum"
                                control={control5}
                                defaultValue={currentDelivery.deliveryRefNum ? currentDelivery.deliveryRefNum : ''}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Delivery Ref #" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.deliveryRefNum ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.deliveryRefNum?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryDate"
                                control={control5}
                                defaultValue={currentDelivery.deliveryDate ? dayjs(currentDelivery.deliveryDate) : ''}
                                render={({ field }) => 
                                    <FormControl fullWidth sx={classes.datePickerStyle}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                slotProps={{
                                                    textField: {
                                                        error: false,
                                                        size: 'small'
                                                    },
                                                }}
                                                id="deliveryDate"
                                                {...field}
                                                error={errors5.deliveryDate ? true : false}
                                                label="Delivery Date"
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.deliveryDate?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryWeight"
                                control={control5}
                                defaultValue={currentDelivery.deliveryWeight ? currentDelivery.deliveryWeight : ''}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Total Weight (Lbs)" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.deliveryWeight ? true : false}
                                        InputProps={{
                                            inputComponent: NumericFormatCustom,
                                        }}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.deliveryWeight?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryPalletCount"
                                control={control5}
                                defaultValue={currentDelivery.deliveryPalletCount ? currentDelivery.deliveryPalletCount : ''}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Pallet Count" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.deliveryPalletCount ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.deliveryPalletCount?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="companyName"
                                control={control5}
                                defaultValue={currentDelivery.companyName}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Company Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.companyName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.companyName?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryCity"
                                control={control5}
                                defaultValue={currentDelivery.deliveryCity}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="City" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.deliveryCity ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.deliveryCity?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="address1"
                                control={control5}
                                defaultValue={currentDelivery.address1}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Address 1" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.address1 ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.address1?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="address2"
                                control={control5}
                                defaultValue={currentDelivery.address2 ? currentDelivery.address2 : ''}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Address 2" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.address2 ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.address2?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryCountry"
                                control={control5}
                                defaultValue={currentDelivery.deliveryCountry}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Country" 
                                        variant="outlined" 
                                        margin="normal" 
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.deliveryCountry ? true : false}
                                        onChange={(e) => {
                                            field.onChange(e); 
                                            setSelectedCountry(e.target.value); 
                                            setValue5('deliveryStateProv', '');
                                            if (submitCount5 > 0)
                                                trigger5('deliveryStateProv');
                                        }}
                                    >
                                        <MenuItem value="Canada">Canada</MenuItem>
                                        <MenuItem value="USA">USA</MenuItem>
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.deliveryCountry?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryStateProv"
                                control={control5}
                                defaultValue={currentDelivery.deliveryStateProv}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Province/State" 
                                        variant="outlined" 
                                        margin="normal" 
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.deliveryStateProv ? true : false}
                                    >
                                        {renderProvinceStateList()}
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.deliveryStateProv?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryPostalZip"
                                control={control5}
                                defaultValue={currentDelivery.deliveryPostalZip}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Postal/Zip Code" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.deliveryPostalZip ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.deliveryPostalZip?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactName"
                                control={control5}
                                defaultValue={currentDelivery.contactName}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.contactName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.contactName?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactPhoneNumber"
                                control={control5}
                                defaultValue={currentDelivery.contactPhoneNumber}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Phone" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.contactPhoneNumber ? true : false}
                                        onChange={onPhoneNumberChange}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.contactPhoneNumber?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactEmail"
                                control={control5}
                                defaultValue={currentDelivery.contactEmail}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Email" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors5.contactEmail ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.contactEmail?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="deliveryContactInfo"
                                control={control5}
                                defaultValue={currentDelivery.deliveryContactInfo}
                                render={({ field }) => 
                                    <TextField 
                                        label="Contact Info" 
                                        variant="outlined" 
                                        multiline
                                        rows={3}
                                        margin="normal" 
                                        sx={classes.formInput}
                                        {...field} 
                                        error={errors5.deliveryContactInfo ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.deliveryContactInfo?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="deliveryInstructions"
                                control={control5}
                                defaultValue={currentDelivery.deliveryInstructions}
                                render={({ field }) => 
                                    <TextField 
                                        label="delivery Instructions" 
                                        variant="outlined" 
                                        multiline
                                        rows={3}
                                        margin="normal" 
                                        sx={classes.formInput}
                                        {...field} 
                                        error={errors5.deliveryInstructions ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors5.deliveryInstructions?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div style={{marginTop: 25}}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={classes.saveButton}
                            startIcon={<CheckIcon />}
                        > 
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            sx={classes.cancelButton}
                            startIcon={<CloseIcon />}
                            onClick={() => cancelEditDelivery()}
                        > 
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={classes.deleteButton}
                            startIcon={<DeleteIcon />}
                            color="error"
                            onClick={() => deleteDelivery()}
                        > 
                            Delete
                        </Button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
}

export default EditDeliveryModal;