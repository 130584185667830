export const quoteConstants = {
    POST_LOAD_FOR_QUOTING_REQUEST: 'POST_LOAD_FOR_QUOTING_REQUEST',
    POST_LOAD_FOR_QUOTING_SUCCESS: 'POST_LOAD_FOR_QUOTING_SUCCESS',
    POST_LOAD_FOR_QUOTING_FAILURE: 'POST_LOAD_FOR_QUOTING_FAILURE',

    POST_LOAD_FOR_INDIVIDUAL_QUOTING_REQUEST: 'POST_LOAD_FOR_INDIVIDUAL_QUOTING_REQUEST',
    POST_LOAD_FOR_INDIVIDUAL_QUOTING_SUCCESS: 'POST_LOAD_FOR_INDIVIDUAL_QUOTING_SUCCESS',
    POST_LOAD_FOR_INDIVIDUAL_QUOTING_FAILURE: 'POST_LOAD_FOR_INDIVIDUAL_QUOTING_FAILURE',

    GET_QUOTES_REQUEST: 'GET_QUOTES_REQUEST',
    GET_QUOTES_SUCCESS: 'GET_QUOTES_SUCCESS',
    GET_QUOTES_FAILURE: 'GET_QUOTES_FAILURE',

    GET_WINNING_QUOTE_REQUEST: 'GET_WINNING_QUOTE_REQUEST',
    GET_WINNING_QUOTE_SUCCESS: 'GET_WINNING_QUOTE_SUCCESS',
    GET_WINNING_QUOTE_FAILURE: 'GET_WINNING_QUOTE_FAILURE',

    AWARD_QUOTE_REQUEST: 'AWARD_QUOTE_REQUEST',
    AWARD_QUOTE_SUCCESS: 'AWARD_QUOTE_SUCCESS',
    AWARD_QUOTE_FAILURE: 'AWARD_QUOTE_FAILURE',

    REVOKE_WINNING_QUOTE_REQUEST: 'REVOKE_WINNING_QUOTE_REQUEST',
    REVOKE_WINNING_QUOTE_SUCCESS: 'REVOKE_WINNING_QUOTE_SUCCESS',
    REVOKE_WINNING_QUOTE_FAILURE: 'REVOKE_WINNING_QUOTE_FAILURE',

    GET_QUOTE_INFO_FOR_PDF_REQUEST: 'GET_QUOTE_INFO_FOR_PDF_REQUEST',
    GET_QUOTE_INFO_FOR_PDF_SUCCESS: 'GET_QUOTE_INFO_FOR_PDF_SUCCESS',
    GET_QUOTE_INFO_FOR_PDF_FAILURE: 'GET_QUOTE_INFO_FOR_PDF_FAILURE',

    GET_EXCHANGE_RATES_REQUEST: 'GET_EXCHANGE_RATES_REQUEST',
    GET_EXCHANGE_RATES_SUCCESS: 'GET_EXCHANGE_RATES_SUCCESS',
    GET_EXCHANGE_RATES_FAILURE: 'GET_EXCHANGE_RATES_FAILURE',

    SEND_SHIPPING_INSTRUCTIONS_EMAIL_REQUEST: 'SEND_SHIPPING_INSTRUCTIONS_EMAIL_REQUEST',
    SEND_SHIPPING_INSTRUCTIONS_EMAIL_SUCCESS: 'SEND_SHIPPING_INSTRUCTIONS_EMAIL_SUCCESS',
    SEND_SHIPPING_INSTRUCTIONS_EMAIL_FAILURE: 'SEND_SHIPPING_INSTRUCTIONS_EMAIL_FAILURE',
};