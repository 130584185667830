const API_URL = process.env.REACT_APP_API_ENDPOINT;

export const getUserActivationKey = async (userId) => {
    let response = await fetch(`${API_URL}/User/getActivationKey?UserId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
    });

    let activationKey = await response.json();
    console.log('Activation Key: ' + JSON.stringify(activationKey));
    if(activationKey.Error){
        return [];
    }
    return activationKey.Result.ActivationKey;
}