import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import Header from '../../components/Header';
import Container from '@mui/material/Container';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Footer from '../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
	accordianHeader: {
        fontWeight: 'bold'
    },
	heading: {
		marginTop: 5
	},
	formSection: {
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        marginBottom: 2,
    },
	formInput: {
        width: '100%',
    },
	uploadButton: {
		textTransform: 'none',
        width: '25%',
		color: 'white',
        backgroundColor: '#1EBBD7',
        '&:hover': {
			backgroundColor: '#1EBBD7',
		},
		left: '35%'
	}
};

const validationSchema = Yup.object().shape({
	firstName: Yup.string()
        .required('First name is required'),
	lastName: Yup.string()
        .required('Last name is required'),
	email: Yup.string()
        .required('Email is required')
		.matches(/.+@.+\..+/, 'Please enter a valid email'),
	body: Yup.string()
        .required('This is required'),
});

function HelpPage() {
    const dispatch = useDispatch();
	const loading = useSelector(state => state.authentication.loading);

	const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => { 
        document.title = "DFQ - Help";
    }, []);

	const onSubmit = data => {
        dispatch(userActions.sendContactUsForm(data));
    };

	return (
		<>
		<CssBaseline />
        <Header />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Container>
				<Typography variant="h2" sx={classes.heading}>FAQ</Typography>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={classes.accordianHeader}>What is the difference between a current load and a past load?</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>Current loads are loads where the first pickup date has not passed. Loads that have been awarded will still be displayed on this page. If a load is cancelled it will be moved to the past loads page. The past loads page will also have loads where the first pickup date has passed.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={classes.accordianHeader}>Sed ut perspiciatis</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={classes.accordianHeader}>At vero eos et accusamus et iusto odio</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={classes.accordianHeader}>Officiis debitis aut rerum necessitatibus</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography sx={classes.accordianHeader}>Similique sunt in culpa</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</Typography>
					</AccordionDetails>
				</Accordion>
				<Typography variant="h2" sx={classes.heading}>Contact Us</Typography>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Typography sx={{marginTop: '15%'}}>Need to get in touch with us? Either fill out the form with your inquiry or email <a href="mailto:CustomerSupport@DirectFreightQuotes.com">CustomerSupport@DirectFreightQuotes.com</a> directly</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Paper elevation={3} sx={classes.formSection}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Grid container spacing={1}>
									<Grid item xs={12} sm={6}>
										<Controller
											name="firstName"
											control={control}
											defaultValue=""
											render={({ field }) => 
												<TextField 
													label="First Name" 
													variant="outlined"
													margin="normal" 
													size="small"
													sx={classes.formInput}
													{...field} 
													error={errors.firstName ? true : false}
												/>
											}
										/>
										<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
											{errors.firstName?.message}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Controller
											name="lastName"
											control={control}
											defaultValue=""
											render={({ field }) => 
												<TextField 
													label="Last Name" 
													variant="outlined"
													margin="normal" 
													size="small"
													sx={classes.formInput}
													{...field} 
													error={errors.lastName ? true : false}
												/>
											}
										/>
										<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
											{errors.lastName?.message}
										</Typography>
									</Grid>
								</Grid>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Controller
											name="email"
											control={control}
											defaultValue=""
											render={({ field }) => 
												<TextField 
													label="Email" 
													variant="outlined"
													margin="normal" 
													size="small"
													sx={classes.formInput}
													{...field} 
													error={errors.email ? true : false}
												/>
											}
										/>
										<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
											{errors.email?.message}
										</Typography>
									</Grid>
								</Grid>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Controller
											name="body"
											control={control}
											defaultValue=""
											render={({ field }) => 
												<TextField 
													label="What can we help you with?" 
													variant="outlined"
													margin="normal" 
													size="small"
													multiline
													rows={3}
													sx={classes.formInput}
													{...field} 
													error={errors.body ? true : false}
												/>
											}
										/>
										<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
											{errors.body?.message}
										</Typography>
									</Grid>
								</Grid>
								{!loading ?
								<Button type="submit" sx={classes.uploadButton} startIcon={<UploadIcon />} > 
									Submit
								</Button>
								: <LinearProgress />}
							</form>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</Container>
        {/* <Footer /> */}
		</>
	);
}

export { HelpPage };