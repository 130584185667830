import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { loadActions } from '../../_actions';
import PickupList from '../NewLoad/PickupList';
import DeliveryList from '../NewLoad/DeliveryList';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

const classes = {
	editButton: {
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
		left: '43%',
		marginTop: 3,
		paddingLeft: 3.5,
		paddingRight: 3.5,
	}
};

function GeneralTab(){
    let { loadId } = useParams();
    const [pickups, setPickups] = useState([]);
	const [deliveries, setDeliveries] = useState([]);
	const [validPickups, setValidPickups] = useState(false);
	const [validDeliveries, setValidDeliveries] = useState(false);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.load.loading);
    const loadDetails = useSelector(state => state.load.loadDetails);
    const pickupsToDelete = useSelector(state => state.load.pickupsToDelete);
    const deliveriesToDelete = useSelector(state => state.load.deliveriesToDelete);
    const user = useSelector(state => state.authentication.user);

    useEffect(() => { 
        dispatch(loadActions.clearPickupsDeliveriesToDelete());
		if(loadDetails){
			if(loadDetails.PickupList){
				setPickups(loadDetails.PickupList);
				setDeliveries(loadDetails.DeliveryList);
				validatePickups(loadDetails.PickupList);
				validateDeliveries(loadDetails.DeliveryList);
			}else{
				setPickups([]);
				setDeliveries([]);
			}
		}else{
            dispatch(loadActions.getLoadDetails(loadId));
        }
    }, [loadDetails]);

    const validatePickups = (updatedPickups) => {
        var keepGoing = true;
        var i = 0;
        while(keepGoing && i < updatedPickups.length){
            if(!updatedPickups[i].pickupRefNum || !updatedPickups[i].pickupDate || !updatedPickups[i].pickupPalletCount || !updatedPickups[i].pickupWeight){
                setValidPickups(false);
                keepGoing = false;
            }else{
                setValidPickups(true);
                i++;
            }
        }
	};

	const validateDeliveries = (updatedDeliveries) => {
        var keepGoing = true;
        var i = 0;
        while(keepGoing && i < updatedDeliveries.length){
            if(!updatedDeliveries[i].deliveryRefNum || !updatedDeliveries[i].deliveryDate || !updatedDeliveries[i].deliveryPalletCount || !updatedDeliveries[i].deliveryWeight){
                setValidDeliveries(false);
                keepGoing = false;
            }else{
                setValidDeliveries(true);
                i++;
            }
        }
	};

    const editPickupsDeliveries = () => {
        dispatch(loadActions.editPickupsDeliveries(pickups, deliveries, pickupsToDelete, deliveriesToDelete, loadDetails.Id, user.UserId));
    };

    return (
        <>
            <PickupList pickups={pickups} setPickups={setPickups} validatePickups={validatePickups}/>
            <DeliveryList deliveries={deliveries} setDeliveries={setDeliveries} validateDeliveries={validateDeliveries}/>
            {!loading ? 
            <Button
                disabled={pickups.length == 0 || deliveries.length == 0 || !validPickups || !validDeliveries}
                variant="contained"
                sx={classes.editButton}
                startIcon={<EditIcon />}
                onClick={() => editPickupsDeliveries()}
            > 
                Edit
            </Button>
            : <LinearProgress />}
        </>
    );
}

export default GeneralTab;