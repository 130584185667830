import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { phoneNumberAutoFormat } from '../../_helpers';
import CheckIcon from '@mui/icons-material/Check';
import LinearProgress from '@mui/material/LinearProgress';

const classes = {
    formPaper: {
        padding: 2,
        width: '50%'
    },
    formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
    updateButton: {
		textTransform: 'none',
		left: '40%',
		paddingLeft: 3,
		paddingRight: 3,
		marginTop: 3,
        backgroundColor: '#1565C0',
		'&:hover': {
			backgroundColor: '#1565C0',
		},
	},
};

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First name is required'),
    lastName: Yup.string()
        .required('Last name is required'),
    phoneNumber: Yup.string()
        .required('Phone number is required'),
});

function PersonalInformation() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.authentication.loading);
    const personalInfo = useSelector(state => state.authentication.personalInfo); 

    const { control, handleSubmit, reset, register, watch, formState: { errors, submitCount }, getValues, setValue, trigger } = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => { 
        dispatch(userActions.getPersonalInfo(user.UserId));
    }, []);

    const onPhoneNumberChange = ({target: {value}}) => {
        let formattedNumber = phoneNumberAutoFormat(value);
        setValue('phoneNumber', formattedNumber); 
    };

    const editPersonalInfo = (data) => {
        dispatch(userActions.updatePersonalInfo(data));
    };

    return (
        <Container>
            {!loading && personalInfo ?
            <Paper sx={classes.formPaper}>
                <form onSubmit={handleSubmit(editPersonalInfo)}>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={personalInfo.FirstName}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="First Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.firstName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.firstName?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={personalInfo.LastName}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Last Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.lastName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.lastName?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={personalInfo.Email}
                                render={({ field }) => 
                                    <TextField
                                        size="small"
                                        label="Email" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.email ? true : false}
                                        disabled
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.email?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue={personalInfo.PhoneNumber}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Phone Number" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.phoneNumber ? true : false}
                                        onChange={onPhoneNumberChange}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.phoneNumber?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    {!loading ? 
                    <Button
                        type="submit"
                        variant="contained"
                        sx={classes.updateButton}
                        startIcon={<CheckIcon sx={{marginRight: -0.5}}/>}
                    > 
                        Update
                    </Button>
                    : <LinearProgress />}
                </form>
            </Paper>
            : null}
        </Container>
    );
}

export default PersonalInformation;