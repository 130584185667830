import { locationConstants } from '../_constants';

const initialState = {
    loading: false,
    locationList: [],
};

export function location(state = initialState, action) {
    switch(action.type) {
        case locationConstants.GET_ALL_LOCATIONS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case locationConstants.GET_ALL_LOCATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                locationList: action.locationList
            };
        case locationConstants.GET_ALL_LOCATIONS_FAILURE:
            return {
                ...state,
                loading: false
            };
        case locationConstants.ADD_LOCATION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case locationConstants.ADD_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case locationConstants.ADD_LOCATION_FAILURE:
            return {
                ...state,
                loading: false
            };
        case locationConstants.EDIT_LOCATION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case locationConstants.EDIT_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case locationConstants.EDIT_LOCATION_FAILURE:
            return {
                ...state,
                loading: false
            };
        case locationConstants.DELETE_LOCATION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case locationConstants.DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case locationConstants.DELETE_LOCATION_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}