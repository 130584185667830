export const provinceList = [
	{ Code: 'AB', Name: 'Alberta' },
	{ Code: 'BC', Name: 'British Columbia' },
	{ Code: 'MB', Name: 'Manitoba' },
	{ Code: 'NB', Name: 'New Brunswick' },
	{ Code: 'NL', Name: 'Newfoundland and Labrador' },
	{ Code: 'NS', Name: 'Nova Scotia' },
	{ Code: 'ON', Name: 'Ontario' },
	{ Code: 'PE', Name: 'Prince Edward Island' },
	{ Code: 'QC', Name: 'Quebec' },
	{ Code: 'SK', Name: 'Saskatchewan' },
	{ Code: 'NT', Name: 'Northwest Territories' },
	{ Code: 'NU', Name: 'Nunavut' },
	{ Code: 'YT', Name: 'Yukon' },
];