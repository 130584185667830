import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { phoneNumberAutoFormat } from '../../_helpers';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

const classes = {
    formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
    // ORIGINAL STYLING
	// modalStyle: {
	// 	position: 'absolute',
	// 	top: '50%',
	// 	left: '50%',
	// 	transform: 'translate(-50%, -50%)',
	// 	width: 600,
	// 	bgcolor: 'background.paper',
	// 	border: '2px solid #000',
	// 	boxShadow: 24,
	// 	p: 4,
	// },
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Default width for smaller screens
        maxWidth: 600, // Maximum width for larger screens
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '95vh', // Maximum height set to 95% of viewport height
        overflowY: 'auto', // Enable vertical scrolling if content overflows
        '@media (min-width: 600px)': { // Media query for larger screens
          width: 600,
        },
    },
    closeIcon: {
        float: 'right'
    },
	addButton: {
		textTransform: 'none',
		left: '40%',
		paddingLeft: 3,
		paddingRight: 3,
		marginTop: 3,
        backgroundColor: '#0D9276',
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	},
    radioButtons: {
        marginTop: 1,
        marginLeft: 1
    },
    saveButton: {
		marginRight: 2,
		marginLeft: '16%',
		paddingLeft: 3,
		paddingRight: 3,
		textTransform: 'none',
        backgroundColor: '#0D9276',
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	},
	cancelButton: {
		textTransform: 'none',
		marginRight: 2,
	},
	deleteButton: {
		textTransform: 'none',
	},
};

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First name is required'),
    lastName: Yup.string()
        .required('Last name is required'),
    // email: Yup.string()
    //     .required('Email is required')
    //     .matches(/.+@.+\..+/, 'Please enter a valid email'),
    phoneNumber: Yup.string()
        .required('Phone number is required'),
});

function EditUserModal(props){
    const { openEditUserModal, handleCloseEditUserModal, currentUser } = props;
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);

    const { control, handleSubmit, reset, register, watch, formState: { errors, submitCount }, getValues, setValue, trigger } = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => { 

	}, []);

    const onPhoneNumberChange = ({target: {value}}) => {
        let formattedNumber = phoneNumberAutoFormat(value);
        setValue('phoneNumber', formattedNumber);
    };

    const editUser = data => {
        data.id = currentUser.Id;
        data.email = currentUser.Email;
        dispatch(userActions.editUser(data));
        reset();
		handleCloseEditUserModal();
    };

    const cancelEditUser = () => {
		reset();
		handleCloseEditUserModal();
	};

    const deleteUser = () => {
        if(window.confirm('Are you sure you want to delete this user?')){
            var userId = currentUser.Id;
            dispatch(userActions.deleteUser(userId));
            reset();
            handleCloseEditUserModal();
        }
	};
    
    const closeIconClick = () => {
        reset();
        handleCloseEditUserModal();
    };

    return (
        <Modal open={openEditUserModal} onClose={handleCloseEditUserModal}>
            <Box sx={classes.modalStyle}>
                <IconButton onClick={() => closeIconClick()} sx={classes.closeIcon}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h4" sx={{textAlign:'center'}}>Edit User</Typography>
                <form onSubmit={handleSubmit(editUser)}>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={currentUser.FirstName}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="First Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.firstName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.firstName?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={currentUser.LastName}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Last Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.lastName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.lastName?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={currentUser.Email}
                                disabled
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Email" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.email ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.email?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue={currentUser.PhoneNumber}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Phone Number" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.phoneNumber ? true : false}
                                        onChange={onPhoneNumberChange}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.phoneNumber?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div style={{marginTop: 25}}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={classes.saveButton}
                            startIcon={<CheckIcon />}
                        > 
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            sx={classes.cancelButton}
                            startIcon={<CloseIcon />}
                            onClick={() => cancelEditUser()}
                        > 
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={classes.deleteButton}
                            startIcon={<DeleteIcon />}
                            color="error"
                            onClick={() => deleteUser()}
                        > 
                            Delete
                        </Button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
}

export default EditUserModal;