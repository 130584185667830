import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import DfqSampleLogo from '../../_resources/DFQ-Sample-Logo.png';
import Footer from '../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
	slogan: {
		fontWeight: 'bold',
		fontSize: 18
	},
	linkToDetails: {
		textDecoration: 'none',
		color: '#2c88d9',
		fontWeight: 'bold'
	},
	loginForm: {
		padding: 3,
		width: '50%'
	},
	loginButton: {
		width: '100%',
		marginTop: 3,
		backgroundColor: '#308cdc',
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15
	},
	signUpButton: {
		backgroundColor: '#20ac9c',
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
		marginTop: 1
    },
};

function LoginPage() {
	const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();

    // Reset login status
    useEffect(() => { 
        document.title = "DFQ - Log in";
        dispatch(userActions.logout()); 
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (username && password) {
            dispatch(userActions.login(username, password));
        }
    }

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Grid
				container
				justify="flex-end"
				alignItems="center"
				sx={{ minHeight: '60vh'}}
			>
				<Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
					<img src={DfqSampleLogo} height={120} alt='logo'/>
					<Typography sx={classes.slogan}>A simple and efficient quoting system<br />so you can focus on what you do best</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Paper elevation={3} sx={classes.loginForm}>
                        <Grid item>
                            <form onSubmit={handleSubmit} style={{textAlign: 'center'}}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Email"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    error={submitted && !username }
                                    value={username}
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    error={submitted && !password }
                                    value={password}
                                    onChange={handleChange}
                                />
                                {!loggingIn &&        
                                    <>  
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={classes.loginButton}
                                        > 
                                            Log In 
                                        </Button>
                                    </>   
                                    }
                                {loggingIn && <CircularProgress />}
                                <br />
                                <br />
								<Link to="/forgotpassword" style={classes.linkToDetails}>Forgot Password?</Link>
								<hr />
								<Link to="/signup">
									<Button
										variant="contained"
										sx={classes.signUpButton}
									>
										Create new account
									</Button>
								</Link>
                            </form>
                        </Grid>
                    </Paper>
				</Grid>
			</Grid>
		</Container>
		<Footer />
		</>
	);
}

export { LoginPage };