import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

const classes = {
    formPaper: {
        padding: 2,
        width: '75%'
    },
    formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
    resetPasswordButton: {
		textTransform: 'none',
		left: '40%',
		paddingLeft: 3,
		paddingRight: 3,
		marginTop: 3,
        backgroundColor: '#1565C0',
		'&:hover': {
			backgroundColor: '#1565C0',
		},
	},
};

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
        .max(100, 'Password must not exceed 100 characters'),
    confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
});

function ResetPassword() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.authentication.loading);
    //const personalInfo = useSelector(state => state.authentication.personalInfo); 

    const { control, handleSubmit, reset, register, watch, formState: { errors, submitCount }, getValues, setValue, trigger } = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => { 

    }, []);

    const resetPassword = (data) => {
        data.email = user.UserId;
        //console.log(data);
        dispatch(userActions.resetPassword(data));
    };

    return (
        <Container>
            <Paper sx={classes.formPaper}>
                <form onSubmit={handleSubmit(resetPassword)}>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        type="password"
                                        size="small"
                                        label="Password" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.password ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.password?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="confirmPassword"
                                control={control}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        type="password"
                                        size="small"
                                        label="Confirm Password" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.confirmPassword ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.confirmPassword?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    {!loading ? 
                    <Button
                        type="submit"
                        variant="contained"
                        sx={classes.resetPasswordButton}
                        startIcon={<CheckIcon sx={{marginRight: -0.5}}/>}
                    > 
                        Reset
                    </Button>
                    : <LinearProgress />}
                </form>
                <Alert severity="info" sx={{marginTop: 2, fontWeight: 'bold'}}>*Password must be at least 8 characters and must contain an upper case letter, lower case letter, and a number</Alert>
                <Alert severity="info" sx={{marginTop: 2, fontWeight: 'bold'}}>*You will be prompted to login with your new password when it is changed</Alert>
            </Paper>
        </Container>
    );
}

export default ResetPassword;