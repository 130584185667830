import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from '../../_actions';
import { provinceList } from '../../_helpers';
import { stateList } from '../../_helpers';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { phoneNumberAutoFormat } from '../../_helpers';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

const classes = {
    formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
    // ORIGINAL STYLING
	// modalStyle: {
	// 	position: 'absolute',
	// 	top: '50%',
	// 	left: '50%',
	// 	transform: 'translate(-50%, -50%)',
	// 	width: 600,
	// 	bgcolor: 'background.paper',
	// 	border: '2px solid #000',
	// 	boxShadow: 24,
	// 	p: 4,
	// },
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Default width for smaller screens
        maxWidth: 600, // Maximum width for larger screens
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '95vh', // Maximum height set to 95% of viewport height
        overflowY: 'auto', // Enable vertical scrolling if content overflows
        '@media (min-width: 600px)': { // Media query for larger screens
          width: 600,
        },
    },
    closeIcon: {
        float: 'right'
    },
	addButton: {
		textTransform: 'none',
		left: '40%',
		paddingLeft: 3,
		paddingRight: 3,
		marginTop: 3,
        backgroundColor: '#0D9276',
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	},
    radioButtons: {
        marginTop: 1,
        marginLeft: 1
    },
    saveButton: {
		marginRight: 2,
		marginLeft: '16%',
		paddingLeft: 3,
		paddingRight: 3,
		textTransform: 'none',
        backgroundColor: '#0D9276',
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	},
	cancelButton: {
		textTransform: 'none',
		marginRight: 2,
	},
	deleteButton: {
		textTransform: 'none',
	},
};

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    city: Yup.string()
        .required('City is required'),
    companyName: Yup.string()
        .required('Company name is required'),
    address1: Yup.string()
        .required('Address 1 is required'),
    postalZip: Yup.string()
        .required('Postal/Zip Code is required'),
    stateProv: Yup.string()
        .required('Province/State is required'),
    country: Yup.string()
        .required('Country is required'),
    contactName: Yup.string()
        .required('Contact Name is required'),
    contactEmail: Yup.string()
        .required('Contact Email is required'),
    contactPhoneNumber: Yup.string()
        .required('Contact Phone is required'),
	contactInfo: Yup.string()
        .required('Contact info is required'),
});

function EditLocationModal(props){
    const { openEditLocationModal, handleCloseEditLocationModal, currentLocation } = props;
    const [selectedCountry, setSelectedCountry] = useState('Canada');
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);

    const { control, handleSubmit, reset, register, watch, formState: { errors, submitCount }, getValues, setValue, trigger } = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => { 
        if(selectedCountry){
            renderProvinceStateList();
        }
	}, [selectedCountry]);

    useEffect(() => { 
        if(currentLocation){
            setSelectedCountry(currentLocation.Country);
        }
	}, [currentLocation]);

    const renderProvinceStateList = () => {
		if(selectedCountry === 'Canada'){
			return provinceList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Code }>{ state.Name }</MenuItem>);
			});
		}else if(selectedCountry === 'USA'){
			return stateList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Code }>{ state.Name }</MenuItem>);
			});
		}
    };

    const onPhoneNumberChange = ({target: {value}}) => {
        let formattedNumber = phoneNumberAutoFormat(value);
        setValue('contactPhoneNumber', formattedNumber);
    };

    const editLocation= data => {
        data.id = currentLocation.Id;
        data.modifiedBy = user.UserId;
        dispatch(locationActions.editLocation(data));
        reset();
		handleCloseEditLocationModal();
    };

    const cancelEditLocation = () => {
		reset();
		handleCloseEditLocationModal();
	};

    const deleteLocation = () => {
        if(window.confirm('Are you sure you want to delete this location?')){
            var locationId = currentLocation.Id;
            var modifiedBy = user.UserId;
            dispatch(locationActions.deleteLocation(locationId, modifiedBy));
            reset();
            handleCloseEditLocationModal();
        }
	};

    const closeIconClick = () => {
        reset();
        handleCloseEditLocationModal();
    };

    return (
        <Modal open={openEditLocationModal} onClose={handleCloseEditLocationModal}>
            <Box sx={classes.modalStyle}>
                <IconButton onClick={() => closeIconClick()} sx={classes.closeIcon}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h4" sx={{textAlign:'center'}}>Edit Location</Typography>
                <form onSubmit={handleSubmit(editLocation)}>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={currentLocation.Name}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.name ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.name?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="companyName"
                                control={control}
                                defaultValue={currentLocation.CompanyName}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Company Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.companyName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyName?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="city"
                                control={control}
                                defaultValue={currentLocation.City}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="City" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.city ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.city?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="address1"
                                control={control}
                                defaultValue={currentLocation.Address1}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Address 1" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.address1 ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.address1?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="address2"
                                control={control}
                                render={({ field }) => 
                                    <TextField 
                                        defaultValue={currentLocation.Address2} // Need to defaultValue here bc it will not be set since it's not part of the Yup validation
                                        size="small"
                                        label="Address 2" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="country"
                                control={control}
                                defaultValue={currentLocation.Country}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Country" 
                                        variant="outlined" 
                                        margin="normal" 
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.country ? true : false}
                                        onChange={(e) => {
                                            field.onChange(e); 
                                            setSelectedCountry(e.target.value); 
                                            setValue('stateProv', '');
                                            if (submitCount > 0)
                                                trigger('stateProv');
                                        }}
                                    >
                                        <MenuItem value="Canada">Canada</MenuItem>
                                        <MenuItem value="USA">USA</MenuItem>
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.country?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="stateProv"
                                control={control}
                                defaultValue={currentLocation.StateProv}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Province/State" 
                                        variant="outlined" 
                                        margin="normal" 
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.stateProv ? true : false}
                                    >
                                        {renderProvinceStateList()}
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.stateProv?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="postalZip"
                                control={control}
                                defaultValue={currentLocation.PostalZip}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Postal/Zip Code" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.postalZip ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.postalZip?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactName"
                                control={control}
                                defaultValue={currentLocation.ContactName}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.contactName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.contactName?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactPhoneNumber"
                                control={control}
                                defaultValue={currentLocation.ContactPhone}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Phone" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.contactPhoneNumber ? true : false}
                                        onChange={onPhoneNumberChange}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.contactPhoneNumber?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactEmail"
                                control={control}
                                defaultValue={currentLocation.ContactEmail}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Email" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.contactEmail ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.contactEmail?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="contactInfo"
                                control={control}
                                defaultValue={currentLocation.ContactInfo}
                                render={({ field }) => 
                                    <TextField 
                                        label="Contact Info" 
                                        variant="outlined" 
                                        multiline
                                        rows={3}
                                        margin="normal" 
                                        sx={classes.formInput}
                                        {...field} 
                                        error={errors.contactInfo ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.contactInfo?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="instructions"
                                control={control}
                                render={({ field }) => 
                                    <TextField 
                                        defaultValue={currentLocation.Instructions} // Need to defaultValue here bc it will not be set since it's not part of the Yup validation
                                        label="Instructions" 
                                        variant="outlined" 
                                        multiline
                                        rows={3}
                                        margin="normal" 
                                        sx={classes.formInput}
                                        {...field} 
                                        error={errors.instructions ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.instructions?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div style={{marginTop: 25}}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={classes.saveButton}
                            startIcon={<CheckIcon />}
                        > 
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            sx={classes.cancelButton}
                            startIcon={<CloseIcon />}
                            onClick={() => cancelEditLocation()}
                        > 
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={classes.deleteButton}
                            startIcon={<DeleteIcon />}
                            color="error"
                            onClick={() => deleteLocation()}
                        > 
                            Delete
                        </Button>
                    </div>
                </form>
            </Box>
        </Modal>
    );
}

export default EditLocationModal;