import axiosInstance from '../_helpers/axiosInstance';

export const locationService = {
    getLocationList,
    addLocation,
    editLocation,
    deleteLocation
};

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function getLocationList(companyId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/location/LocationList?CompanyId=${companyId}`,
    });
}

function addLocation(locationInfo){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/location/AddLocation`,
        data: {
            CompanyId: locationInfo.companyId,
            LocationType: locationInfo.locationType,
            Name: locationInfo.name,
            CompanyName: locationInfo.companyName,
            City: locationInfo.city,
            Address1: locationInfo.address1,
            Address2: locationInfo.address2,
            StateProv: locationInfo.stateProv,
            PostalZip: locationInfo.postalZip,
            Country: locationInfo.country,
            ContactName: locationInfo.contactName,
            ContactPhone: locationInfo.contactPhoneNumber,
            ContactEmail: locationInfo.contactEmail,
            ContactInfo: locationInfo.contactInfo,
            Instructions: locationInfo.instructions,
            CreatedBy: locationInfo.createdBy
        }
    });
}

function editLocation(locationInfo){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/location/EditLocation`,
        data: {
            Id: locationInfo.id,
            LocationType: locationInfo.locationType,
            Name: locationInfo.name,
            CompanyName: locationInfo.companyName,
            City: locationInfo.city,
            Address1: locationInfo.address1,
            Address2: locationInfo.address2,
            StateProv: locationInfo.stateProv,
            PostalZip: locationInfo.postalZip,
            Country: locationInfo.country,
            ContactName: locationInfo.contactName,
            ContactPhone: locationInfo.contactPhoneNumber,
            ContactEmail: locationInfo.contactEmail,
            ContactInfo: locationInfo.contactInfo,
            Instructions: locationInfo.instructions,
            ModifiedBy: locationInfo.modifiedBy
        }
    });
}

function deleteLocation(locationId, modifiedBy){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/location/DeleteLocation`,
        data: {
            LocationId: locationId,
            ModifiedBy: modifiedBy
        }
    });
}