import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadActions } from '../../../_actions';
import { useNavigate } from "react-router-dom";
import Header from '../../../components/Header';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import LoadTemplateSearch from '../../../components/NewLoad/LoadTemplateSearch';
import LoadInfoForm from '../../../components/NewLoad/LoadInfoForm';
import PickupList from '../../../components/NewLoad/PickupList';
import DeliveryList from  '../../../components/NewLoad/DeliveryList';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import Footer from '../../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden',
		padding: '2.5%',
	},
	pageHeader: {
		color: '#2c88d9',
		marginBottom: 3
	},
	createButton: {
		textTransform: 'none',
		marginTop: 3,
		fontSize: 18,
		width: '10%',
		left: '45%',
		paddingLeft: '6%',
		paddingRight: '6%',
		backgroundColor: '#0D9276',
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	},
};

const validationSchema = Yup.object().shape({
	shipmentType: Yup.string()
		.required('Shipment type is required'),
	goodsClassification: Yup.string()
		.required('Classification of goods is required'),
	equipmentType: Yup.string()
		.required('Equipment type is required'),
	loadDescription: Yup.string()
		.required('Load description is required'),
	serviceLevel: Yup.string()
		.required('Service Level is required'),
	palletHeight: Yup.string()
		.required('Pallet Height is required'),
	palletDimensions: Yup.string()
		.required('Pallet dimensions is required'),
	trailerType: Yup.string()
		.required('Trailer type is required'),
});

function NewLoadPage() {
	const [pickups, setPickups] = useState([]);
	const [deliveries, setDeliveries] = useState([]);
	const [validPickups, setValidPickups] = useState(false);
	const [validDeliveries, setValidDeliveries] = useState(false);
	// Current load template
	const [loadTemplate, setLoadTemplate] = useState(null);
	const loading = useSelector(state => state.load.loading);
	const user = useSelector(state => state.authentication.user);
	const loadTemplateDetails = useSelector(state => state.load.loadTemplateDetails);
    const dispatch = useDispatch();
	const navigate = useNavigate();

	// HAVING YUP VALIDATIONSCHEMA THATS NOT BEING USED WILL CAUSE THE FORM TO NOT SUBMIT
	const methods = useForm({
        resolver: yupResolver(validationSchema)
    });

	useEffect(() => { 
        document.title = "DFQ - New Load";
		if(loadTemplateDetails){
			if(loadTemplateDetails.PickupList){
				setPickups(loadTemplateDetails.PickupList);
				setDeliveries(loadTemplateDetails.DeliveryList);
				validatePickups(loadTemplateDetails.PickupList);
				validateDeliveries(loadTemplateDetails.DeliveryList);
			}else{
				setPickups([]);
				setDeliveries([]);
			}
		}
    }, [loadTemplateDetails]);

	const submitNewLoad = data => {
		// Needed to add this function bc if we loaded a template and then made some changes to it
		// and the back button is pressed from the review page, the changes would not persist.
		// The original load template from the server would be loaded bc that's what was saved in Redux
        dispatch(loadActions.confirmLoadDetails(data, pickups, deliveries));
		navigate('/loads/review', { state: { loadInfo: data }});
	};

	const validatePickups = (updatedPickups) => {
        var keepGoing = true;
        var i = 0;
        while(keepGoing && i < updatedPickups.length){
            if(!updatedPickups[i].pickupRefNum || !updatedPickups[i].pickupDate || !updatedPickups[i].pickupPalletCount || !updatedPickups[i].pickupWeight){
                setValidPickups(false);
                keepGoing = false;
            }else{
                setValidPickups(true);
                i++;
            }
        }
	};

	const validateDeliveries = (updatedDeliveries) => {
        var keepGoing = true;
        var i = 0;
        while(keepGoing && i < updatedDeliveries.length){
            if(!updatedDeliveries[i].deliveryRefNum || !updatedDeliveries[i].deliveryDate || !updatedDeliveries[i].deliveryPalletCount || !updatedDeliveries[i].deliveryWeight){
                setValidDeliveries(false);
                keepGoing = false;
            }else{
                setValidDeliveries(true);
                i++;
            }
        }
	};

	return (
		<>
		<CssBaseline />
        <Header />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Container>
				<LoadTemplateSearch companyId={user.CompanyId} setLoadTemplate={setLoadTemplate}/>
				<Typography variant="h4" sx={classes.pageHeader}>Enter New Load Details For Required Quote</Typography>
				<FormProvider {...methods}>
					<form>
						<LoadInfoForm loadTemplate={loadTemplate} loadTemplateDetails={loadTemplateDetails}/>
						<PickupList pickups={pickups} setPickups={setPickups} validatePickups={validatePickups}/>
						<DeliveryList deliveries={deliveries} setDeliveries={setDeliveries} validateDeliveries={validateDeliveries}/>
						<Button
							disabled={pickups.length == 0 || deliveries.length == 0 || !validPickups || !validDeliveries}
							type="submit"
							variant="contained"
							sx={classes.createButton}
							startIcon={<CheckIcon />}
							onClick={methods.handleSubmit(submitNewLoad)}
						> 
							Create
						</Button>
					</form>
				</FormProvider>
			</Container>
		</Container>
        {/* <Footer /> */}
		</>
	);
}

export { NewLoadPage };