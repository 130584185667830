import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { load } from './load.reducer';
import { location } from './location.reducer';
import { quote } from './quote.reducer';
import { activateAccount } from './activateAccount.reducer';
import { alert } from './alert.reducer';

const appReducer = combineReducers({
    authentication,
    load,
    location,
    quote,
    activateAccount,
    alert,
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'USER_LOGOUT') {
      state = undefined;
    }
  
    return appReducer(state, action);
};

export default rootReducer;