import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadActions } from '../../../_actions';
import Header from '../../../components/Header';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden',
		padding: '2.5%'
	},
	pageHeader: {
		color: '#2c88d9',
		marginBottom: 5
	},
	formSection: {
		paddingLeft: 2,
		paddingRight: 1,
		paddingBottom: 1,
	},
	formSubHeaders: {
		fontSize: 20
	},
	formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
	selectInput: {
		width: '100%',
		marginTop: 2,
	},
	datePickerStyle: {
        marginTop: 2,
    },
	tableStyle: {
		whiteSpace: 'nowrap'
	},
	modalButton: {
		textTransform: 'none',
		margin: 1
	},
	modalStyle: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	},
	addButton: {
		textTransform: 'none',
		left: '37%',
		paddingLeft: 3,
		paddingRight: 3,
		marginTop: 3
	},
	createButton: {
		textTransform: 'none',
		marginTop: 3,
		fontSize: 18,
		width: '10%',
		left: '45%',
		paddingLeft: '6%',
		paddingRight: '6%',
	},
	editButton: {
		marginRight: 2,
		marginLeft: '8%',
		paddingLeft: 3,
		paddingRight: 3,
		textTransform: 'none',
	},
	cancelButton: {
		textTransform: 'none',
		marginRight: 2,
	},
	deleteButton: {
		textTransform: 'none',
	},
	autocompleteStyle: {
		width: '25%',
		float: 'right',
		backgroundColor: 'white'
	}
};

const validationSchema = Yup.object().shape({
    shipmentType: Yup.string()
        .required('Type of shipment is required'),
	equipmentType: Yup.string()
		.required('Equipment type is required'),
	expeditedService: Yup.string()
		.required('Expedited service is required'),
	trailerType: Yup.string()
		.required('Trailer type is required'),
	from: Yup.string()
		.required('From location is required'),
	to: Yup.string()
		.required('To location is required'),
	scheduledPickupDate: Yup.string()
		.required('Scheduled pickup date is required'),
	scheduledDeliveryDate: Yup.string()
		.required('Scheduled delivery date is required'),
	// loadPallets: Yup.number()
	// 	.typeError('Please enter a whole number')
	// 	.integer('Please enter a whole number')
	// 	.required('Please enter a whole number'),
	// pickups: Yup.number()
	// 	.typeError('Please enter a whole number')
	// 	.integer('Please enter a whole number')
	// 	.required('Please enter a whole number'),
	// goodsClassification: Yup.string()
	// 	.required('Classification of goods is required'),
	// loadWeight: Yup.number()
	// 	.typeError('Please enter a number')
	// 	.required('Please enter a whole number'),
	// drops: Yup.number()
	// 	.typeError('Please enter a whole number')
	// 	.integer('Please enter a whole number')
	// 	.required('Please enter a whole number'),
	// loadDescription: Yup.string()
	// 	.required('Load description is required'),
	// generalComments: Yup.string()
	// 	.required('General comments is required'),
});

const validationSchema2 = Yup.object().shape({
    pickupCity: Yup.string()
        .required('City is required'),
	pickupRefNum: Yup.string()
        .required('Pickup ref # is required'),
	pickupDate: Yup.date()
        .required('Pickup date is required')
		.typeError('Date is required'),
	pickupPalletCount: Yup.number()
		.typeError('Please enter a whole number')
		.integer('Please enter a whole number'),
	pickupWeight:  Yup.number()
		.typeError('Please enter a number'),
	pickupContactInfo: Yup.string()
        .required('Contact info is required'),
	// pickupInstructions: Yup.string()
    //     .required('Instructions is required'),
});

const validationSchema3 = Yup.object().shape({
    deliveryCity: Yup.string()
        .required('City is required'),
	deliveryRefNum: Yup.string()
        .required('Delivery ref # is required'),
	deliveryDate: Yup.date()
        .required('Delivery date is required')
		.typeError('Date is required'),
	deliveryPalletCount: Yup.number()
		.typeError('Please enter a whole number')
		.integer('Please enter a whole number'),
	deliveryWeight: Yup.number()
		.typeError('Please enter a number'),
	deliveryContactInfo: Yup.string()
        .required('Contact info is required'),
	// deliveryInstructions: Yup.string()
    //     .required('Instructions is required'),
});

const validationSchema4 = Yup.object().shape({
    pickupCity: Yup.string()
        .required('City is required'),
	pickupRefNum: Yup.string()
        .required('Pickup ref # is required'),
	pickupDate: Yup.date()
        .required('Pickup date is required')
		.typeError('Date is required'),
	pickupPalletCount: Yup.number()
		.typeError('Please enter a whole number')
		.integer('Please enter a whole number'),
	pickupWeight: Yup.number()
		.typeError('Please enter a number'),
	pickupContactInfo: Yup.string()
        .required('Contact info is required'),
	// pickupInstructions: Yup.string()
    //     .required('Instructions is required'),
});

const validationSchema5 = Yup.object().shape({
    deliveryCity: Yup.string()
        .required('City is required'),
	deliveryRefNum: Yup.string()
        .required('Delivery ref # is required'),
	deliveryDate: Yup.date()
        .required('Delivery date is required')
		.typeError('Date is required'),
	deliveryPalletCount: Yup.number()
		.typeError('Please enter a whole number')
		.integer('Please enter a whole number'),
	deliveryWeight: Yup.number()
		.typeError('Please enter a number'),
	deliveryContactInfo: Yup.string()
        .required('Contact info is required'),
	// deliveryInstructions: Yup.string()
    //     .required('Instructions is required'),
});

function OLDNewLoadPage() {
	// Next 6 are for opening/closing the modals used to add deliveries/pickups
	const [openPickupModal, setOpenPickupModal] = useState(false);
	const handleOpenPickupModal = () => setOpenPickupModal(true);
	const handleClosePickupModal = () => setOpenPickupModal(false);
	const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
	const handleOpenDeliveryModal = () => setOpenDeliveryModal(true);
	const handleCloseDeliveryModal = () => setOpenDeliveryModal(false);
	// Next 5 are for editing Pickups
	const [openEditPickupModal, setOpenEditPickupModal] = useState(false);
	const handleOpenEditPickupModal = () => setOpenEditPickupModal(true);
	const handleCloseEditPickupModal = () => setOpenEditPickupModal(false);
	const [currentPickup, setCurrentPickup] = useState({});
	const [pickups, setPickups] = useState([]);
	// Next 5 are for editing Deliveries
	const [openEditDeliveryModal, setOpenEditDeliveryModal] = useState(false);
	const handleOpenEditDeliveryModal = () => setOpenEditDeliveryModal(true);
	const handleCloseEditDeliveryModal = () => setOpenEditDeliveryModal(false);
	const [currentDelivery, setCurrentDelivery] = useState({});
	const [deliveries, setDeliveries] = useState([]);
	// Standard Redux variables/functions
	const loading = useSelector(state => state.load.loading);
	const loadTemplatesList = useSelector(state => state.load.loadTemplatesList);
	const loadingTemplate = useSelector(state => state.load.loadingTemplate);
	const loadTemplateDetails = useSelector(state => state.load.loadTemplateDetails);
	const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();
	// Current load template
	const [loadTemplate, setLoadTemplate] = useState(null);
	// Total pallet count of the load
	const [palletCount, setPalletCount] = useState(0);
	// Total weight of the load
	const [loadWeight, setLoadWeight] = useState(0);

	const { control, handleSubmit, reset, register, watch, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema),
    });

	const { control: control2, handleSubmit: handleSubmit2, reset: reset2, register: register2, watch: watch2, formState: { errors: errors2, submitCount: submitCount2 }, getValues: getValues2, setValue: setValue2, trigger: trigger2} = useForm({
        resolver: yupResolver(validationSchema2)
    });
	//const watchNewPickupPalletCount = watch2('pickupPalletCount');

	const { control: control3, handleSubmit: handleSubmit3, reset: reset3, register: register3, watch: watch3, formState: { errors: errors3, submitCount: submitCount3 }, getValues: getValues3, setValue: setValue3, trigger: trigger3} = useForm({
        resolver: yupResolver(validationSchema3)
    });

	const { control: control4, handleSubmit: handleSubmit4, reset: reset4, register: register4, watch: watch4, formState: { errors: errors4, submitCount: submitCount4 }, getValues: getValues4, setValue: setValue4, trigger: trigger4} = useForm({
        resolver: yupResolver(validationSchema4)
    });

	const { control: control5, handleSubmit: handleSubmit5, reset: reset5, register: register5, watch: watch5, formState: { errors: errors5, submitCount: submitCount5 }, getValues: getValues5, setValue: setValue5, trigger: trigger5} = useForm({
        resolver: yupResolver(validationSchema5)
    });

	useEffect(() => { 
        document.title = "DFQ - OLD New Load";
		reset(loadTemplateDetails);
		if(loadTemplateDetails){
			if(loadTemplateDetails.PickupList){
				setPickups(loadTemplateDetails.PickupList);
				setDeliveries(loadTemplateDetails.DeliveryList);
				// Updating the total pallet count of the load based on the templates Pickup List
				setValue('loadPallets', getPalletCount(loadTemplateDetails.PickupList));
				setPalletCount(getPalletCount(loadTemplateDetails.PickupList));
				// Updating the total weight of the load based on the templates Pickup List
				setValue('loadWeight', getLoadWeight(loadTemplateDetails.PickupList));
				setLoadWeight(getLoadWeight(loadTemplateDetails.PickupList));
			}else{
				setPickups([]);
				setDeliveries([]);
			}
		}
    }, [loadTemplateDetails]);

	useEffect(() => { 
		if(loadTemplate){
			reset();
			dispatch(loadActions.getLoadTemplateDetails(loadTemplate.Id));
		}
    }, [loadTemplate]);

	const submitNewLoad = data => {
        dispatch(loadActions.createLoad(data, pickups, deliveries));
    };

	const addPickup = data => {
		const updatePickups = [
			...pickups,
			{
				id: pickups.length + 1,
				pickupCity: data.pickupCity,
				pickupRefNum: data.pickupRefNum,
				pickupDate: data.pickupDate ? data.pickupDate.toJSON() : "",
				pickupPalletCount: data.pickupPalletCount,
				pickupWeight: data.pickupWeight,
				pickupContactInfo: data.pickupContactInfo,
				pickupInstructions: data.pickupInstructions
			}
		];
		setPickups(updatePickups);
		// Resets the form data so next time we open the Modal the form is clear
		reset2();
		// Updating the total pallet count of the load when a new pickup is added
		setValue('loadPallets', palletCount + data.pickupPalletCount);
		setPalletCount(palletCount + data.pickupPalletCount);
		// Updating the total weight of the load based on the templates Pickup List
		setValue('loadWeight', loadWeight + data.pickupWeight);
		setLoadWeight(loadWeight + data.pickupWeight);
		handleClosePickupModal();
    };

	const openEditPickupForm = pickup => {
		handleOpenEditPickupModal();
		setCurrentPickup({...pickup});
	};

	const editPickup = data => {
		if(data.pickupDate){
			data.pickupDate = data.pickupDate.toJSON();
		}
		const updatedPickups = pickups.map((pickup) => 
			pickup.id === data.id ? data : pickup
		);

		setPickups(updatedPickups);
		reset4();
		// Updating the total pallet count of the load when a pickup is edited
		setValue('loadPallets', getPalletCount(updatedPickups));
		setPalletCount(getPalletCount(updatedPickups));
		// Updating the total weight of the load based on the templates Pickup List
		setValue('loadWeight', getLoadWeight(updatedPickups));
		setLoadWeight(getLoadWeight(updatedPickups));
		handleCloseEditPickupModal();
	};

	const cancelEditPickup = () => {
		reset4();
		handleCloseEditPickupModal();
	};

	const deletePickup = () => {
		setPickups(pickups.filter(p => p.id !== currentPickup.id));
		reset4();
		// Updating the total pallet count of the load when a pickup is deleted
		setValue('loadPallets', palletCount - currentPickup.pickupPalletCount);
		setPalletCount(palletCount - currentPickup.pickupPalletCount);
		// Updating the total weight of the load based on the templates Pickup List
		setValue('loadWeight', loadWeight - currentPickup.pickupWeight);
		setLoadWeight(loadWeight - currentPickup.pickupWeight);
		handleCloseEditPickupModal();
	};

	const addDelivery = data => {
		const updateDeliveries = [
			...deliveries,
			{
				id: deliveries.length + 1,
				deliveryCity: data.deliveryCity,
				deliveryRefNum: data.deliveryRefNum,
				deliveryDate: data.deliveryDate ? data.deliveryDate.toJSON() : "",
				deliveryPalletCount: data.deliveryPalletCount,
				deliveryWeight: data.deliveryWeight,
				deliveryContactInfo: data.deliveryContactInfo,
				deliveryInstructions: data.deliveryInstructions
			}
		];
		setDeliveries(updateDeliveries);
		// Resets the form data so next time we open the Modal the form is clear
		reset3();
		handleCloseDeliveryModal();
    };

	const openEditDeliveryForm = delivery => {
		handleOpenEditDeliveryModal();
		setCurrentDelivery({...delivery});
	};

	const editDelivery = data => {
		if(data.deliveryDate){
			data.deliveryDate = data.deliveryDate.toJSON();
		}
		const updatedDeliveries = deliveries.map((delivery) => 
			delivery.id === data.id ? data : delivery
		);

		setDeliveries(updatedDeliveries);
		reset5();
		handleCloseEditDeliveryModal();
	};

	const cancelEditDelivery = () => {
		reset5();
		handleCloseEditDeliveryModal();
	};

	const deleteDelivery = () => {
		setDeliveries(deliveries.filter(d => d.id !== currentDelivery.id));
		reset5();
		handleCloseEditDeliveryModal();
	};

	const getPalletCount = pickupList => {
		return pickupList.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.pickupPalletCount), 0);
	};

	const getLoadWeight = pickupList => {
		return pickupList.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.pickupWeight), 0);
	};

	return (
		<>
		<CssBaseline />
        <Header />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Autocomplete 
				disablePortal
				onOpen={() => {
					if(loadTemplatesList.length === 0){
						dispatch(loadActions.getLoadTemplatesList(user.CompanyId));
					} 
				}}
				isOptionEqualToValue={(option, value) => option.Id === value.Id}
				getOptionLabel={(option) => option.Title}
				options={loadTemplatesList}
				loading={loading}
				onChange={(event, newValue) => {
					setLoadTemplate(newValue);
				}}
				sx={classes.autocompleteStyle}
				renderInput={(params) => (
					<TextField 
						{...params} 
						label='Load Templates'
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							)
						}}
					/>
				)}
			/>
			<Typography variant="h4" sx={classes.pageHeader}>OLD New Load Details</Typography>
			{!loadingTemplate ? 
			<>
			<form key={1} onSubmit={handleSubmit(submitNewLoad)}>
				<Typography sx={classes.formSubHeaders}>Shipping Information</Typography>
				<Paper elevation={3} sx={classes.formSection}>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item xs={12} sm={3}>
							<Controller
								name="shipmentType"
								control={control}
								defaultValue={loadTemplateDetails ? loadTemplateDetails.ShipmentType : ""}
								render={({ field }) => 
									<FormControl fullWidth>
										<InputLabel id="shipmentTypeLabel" sx={{marginTop: 2}}>Type of Shipment</InputLabel>
										<Select labelId="shipmentTypeLabel" id="shipmentType" sx={classes.selectInput} {...field} error={errors.shipmentType ? true : false} label="Type of Shipment">
											<MenuItem value="tlRoad">TL Road</MenuItem>
											<MenuItem value="tlIntermodal">TL Intermodal</MenuItem>
											<MenuItem value="ltl">LTL</MenuItem>
										</Select>
									</FormControl>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.shipmentType?.message}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Controller
								name="equipmentType"
								control={control}
								defaultValue={loadTemplateDetails ? loadTemplateDetails.EquipmentType : ""}
								render={({ field }) => 
									<FormControl fullWidth>
										<InputLabel id="equipmentTypeLabel" sx={{marginTop: 2}}>Equipment Type</InputLabel>
										<Select labelId="equipmentTypeLabel" id="equipmentType" sx={classes.selectInput} {...field} error={errors.equipmentType ? true : false} label="Equipment Type">
											<MenuItem value="dryVan">Dry Van</MenuItem>
											<MenuItem value="flatBed">Flat Bed</MenuItem>
											<MenuItem value="skirted">Skirted</MenuItem>
											<MenuItem value="pupTruck">Pup Truck</MenuItem>
										</Select>
									</FormControl>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.equipmentType?.message}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Controller
								name="expeditedService"
								control={control}
								defaultValue={loadTemplateDetails ? loadTemplateDetails.ExpeditedService : ""}
								render={({ field }) => 
									<FormControl fullWidth>
										<InputLabel id="expeditedServiceLabel" sx={{marginTop: 2}}>Expedited Service</InputLabel>
										<Select labelId="expeditedServiceLabel" id="expeditedService" sx={classes.selectInput} {...field} error={errors.expeditedService ? true : false} label="Expedited Service">
											<MenuItem value="regular">Regular</MenuItem>
											<MenuItem value="expedited">Expedited</MenuItem>
											<MenuItem value="team">Team</MenuItem>
										</Select>
									</FormControl>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.expeditedService?.message}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Controller
								name="trailerType"
								control={control}
								defaultValue={loadTemplateDetails ? loadTemplateDetails.TrailerType : ""}
								render={({ field }) => 
									<FormControl fullWidth>
										<InputLabel id="trailerTypeLabel" sx={{marginTop: 2}}>Trailer Type</InputLabel>
										<Select labelId="trailerTypeLabel" id="trailerType" sx={classes.selectInput} {...field} error={errors.trailerType ? true : false} label="Trailer Type">
											<MenuItem value="48Tandem">48' Tandem</MenuItem>
											<MenuItem value="48Tridem">48' Tridem</MenuItem>
											<MenuItem value="48Or53Tandem">48' or 53' Tandem</MenuItem>
											<MenuItem value="53Tridem">53' Tridem</MenuItem>
											<MenuItem value="superB">Super B</MenuItem>
										</Select>
									</FormControl>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.trailerType?.message}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item xs={12} sm={3}>
							<Controller
								name="from"
								control={control}
								defaultValue={loadTemplateDetails ? loadTemplateDetails.From : ""}
								render={({ field }) => 
									<TextField 
										label="From" 
										variant="outlined" 
										margin="normal" 
										sx={classes.formInput} 
										{...field} 
										error={errors.from ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.from?.message}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Controller
								name="to"
								control={control}
								defaultValue={loadTemplateDetails ? loadTemplateDetails.To : ""}
								render={({ field }) => 
									<TextField 
										label="To" 
										variant="outlined" 
										margin="normal" 
										sx={classes.formInput} 
										{...field} 
										error={errors.to ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.to?.message}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Controller
								name="scheduledPickupDate"
								control={control}
								defaultValue={loadTemplateDetails ? dayjs(loadTemplateDetails.ScheduledPickupDate).isValid() ? dayjs(loadTemplateDetails.ScheduledPickupDate) : null : null}
								render={({ field }) => 
									<FormControl fullWidth sx={classes.datePickerStyle}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												slotProps={{
													textField: {
														error: false,
													},
												}}
												id="scheduledPickupDate"
												{...field}
												error={errors.scheduledPickupDate ? true : false}
												label="Scheduled Pickup Date"
											/>
										</LocalizationProvider>
									</FormControl>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.scheduledPickupDate?.message}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Controller
								name="scheduledDeliveryDate"
								control={control}
								defaultValue={loadTemplateDetails ? dayjs(loadTemplateDetails.ScheduledDeliveryDate).isValid() ? dayjs(loadTemplateDetails.ScheduledDeliveryDate) : null : null}
								render={({ field }) => 
									<FormControl fullWidth sx={classes.datePickerStyle}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												slotProps={{
													textField: {
														error: false,
													},
												}}
												id="scheduledDeliveryDate"
												{...field}
												error={errors.scheduledDeliveryDate ? true : false}
												label="Scheduled Delivery Date"
											/>
										</LocalizationProvider>
									</FormControl>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.scheduledDeliveryDate?.message}
							</Typography>
						</Grid>
					</Grid>
				</Paper>
				<br />
				<Typography sx={classes.formSubHeaders}>Load Information</Typography>
				<Paper elevation={3} sx={classes.formSection}>
					<Grid container direction="row">
						<Grid item xs={12} sm={3}>
							<Grid container sx={{paddingRight: 1}}>
								<Grid item xs={12}>
									<Controller
										name="loadPallets"
										control={control}
										defaultValue={0}
										disabled
										render={({ field }) => 
											<TextField 
												label="Load Pallets" 
												variant="outlined" 
												margin="normal" 
												sx={classes.formInput}
												{...field} 
												error={errors.loadPallets ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.loadPallets?.message}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Controller
										name="loadWeight"
										control={control}
										defaultValue=""
										disabled
										render={({ field }) => 
											<TextField 
												label="Load Weight (Lbs)" 
												variant="outlined" 
												margin="normal" 
												sx={classes.formInput} 
												{...field} 
												error={errors.loadWeight ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.loadWeight?.message}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Controller
										name="generalComments"
										control={control}
										defaultValue={loadTemplateDetails ? loadTemplateDetails.GeneralComments : ""}
										render={({ field }) => 
											<TextField 
												label="General Comments" 
												variant="outlined" 
												multiline
												rows={4}
												margin="normal" 
												sx={classes.formInput}
												{...field} 
												error={errors.generalComments ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.generalComments?.message}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Grid container sx={{paddingRight: 1}}>
								<Grid item xs={12}>
									<Controller
										name="pickups"
										control={control}
										defaultValue=""
										disabled
										render={({ field }) => 
											<TextField 
												label="Pickups" 
												variant="outlined" 
												margin="normal" 
												sx={classes.formInput} 
												{...field} 
												error={errors.pickups ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.pickups?.message}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Controller
										name="drops"
										control={control}
										defaultValue=""
										disabled
										render={({ field }) => 
											<TextField 
												label="Drops" 
												variant="outlined" 
												margin="normal" 
												sx={classes.formInput} 
												{...field} 
												error={errors.drops ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.drops?.message}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Grid container sx={{paddingRight: 1}}>
								<Grid item xs={12}>
									<Controller
										name="goodsClassification"
										control={control}
										defaultValue={loadTemplateDetails ? loadTemplateDetails.GoodsClassification : ""}
										render={({ field }) => 
											<FormControl fullWidth>
												<InputLabel id="goodsClassificationLabel" sx={{marginTop: 2}}>Classification of Goods</InputLabel>
												<Select labelId="goodsClassificationLabel" id="goodsClassification" sx={classes.selectInput} {...field} error={errors.goodsClassification ? true : false} label="Classification of Goods">
													<MenuItem value="50">50 - Durable freight that fits on a standard 4' x 4' pallet</MenuItem>
													<MenuItem value="55">55 - Bricks, cement, hardwood flooring, construction materials</MenuItem>
													<MenuItem value="60">60 - Car accessories, car parts</MenuItem>
													<MenuItem value="65">65 - Car accessories and parts, boxed books, bottled drinks</MenuItem>
													<MenuItem value="70">70 - Car accessories and parts, auto engines, food items</MenuItem>
													<MenuItem value="77.5">77.5 - Tires, bathroom fixtures</MenuItem>
													<MenuItem value="85">85 - Crated machinery, cast iron stoves</MenuItem>
													<MenuItem value="92.5">92.5 - Computers, monitors, refrigerators</MenuItem>
													<MenuItem value="100">100 - Car covers, canvas, boat covers, wine cases, caskets</MenuItem>
													<MenuItem value="110">110 - Cabinets, framed art, table saws</MenuItem>
													<MenuItem value="125">125 - Small home appliances</MenuItem>
													<MenuItem value="150">150 - Auto sheet metal, bookcases</MenuItem>
													<MenuItem value="175">175 - Clothing, couches, stuffed furniture</MenuItem>
													<MenuItem value="200">200 - Sheet metal parts, aluminum tables, packaged mattresses, aircraft parts</MenuItem>
													<MenuItem value="250">250 - Mattresses and box springs, plasma TVs, bamboo furniture</MenuItem>
													<MenuItem value="300">300 - Model boats, assembled chairs, tables, wood cabinets</MenuItem>
													<MenuItem value="400">400 - Deer antlers</MenuItem>
													<MenuItem value="500">500 - Gold dust, ping pong balls</MenuItem>
												</Select>
											</FormControl>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.goodsClassification?.message}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Controller
										name="loadDescription"
										control={control}
										defaultValue={loadTemplateDetails ? loadTemplateDetails.LoadDescription : ""}
										render={({ field }) => 
											<TextField 
												label="Load Description" 
												variant="outlined" 
												margin="normal" 
												sx={{width: '100%', marginTop: 3}} 
												{...field} 
												error={errors.loadDescription ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.loadDescription?.message}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={3} sx={{paddingTop: 1, paddingLeft: 10, paddingRight: 11}}>
							<Grid style={{ height: '100%'}}>
								<FormControlLabel
									label="Pallet Jack Required"
									control={
										<Controller 
											name="palletJackRequired"
											control={control}
											defaultValue={loadTemplateDetails ? loadTemplateDetails.PalletJackRequired : false}
											render={({ field }) => 
												<Checkbox 
													{...field}
													checked={field.value}
													onChange={(e) => field.onChange(e.target.checked)}
												/>
											}
										/>
									}
								/>
								<FormControlLabel
									label="Power Tailgate Required"
									control={
										<Controller 
											name="powerTailgateRequired"
											control={control}
											defaultValue={loadTemplateDetails ? loadTemplateDetails.PowerTailgateRequired : false}
											render={({ field }) => 
												<Checkbox 
													{...field}
													checked={field.value}
													onChange={(e) => field.onChange(e.target.checked)}
												/>
											}
										/>
									}
								/>
								<FormControlLabel
									label="Stackable Load"
									control={
										<Controller 
											name="stackableLoad"
											control={control}
											defaultValue={loadTemplateDetails ? loadTemplateDetails.StackableLoad : false}
											render={({ field }) => 
												<Checkbox 
													{...field}
													checked={field.value}
													onChange={(e) => field.onChange(e.target.checked)}
												/>
											}
										/>
									}
								/>
								<FormControlLabel
									label="Flatbed Tarped"
									control={
										<Controller 
											name="flatbedTarped"
											control={control}
											defaultValue={loadTemplateDetails ? loadTemplateDetails.FlatbedTarped : false}
											render={({ field }) => 
												<Checkbox 
													{...field}
													checked={field.value}
													onChange={(e) => field.onChange(e.target.checked)}
												/>
											}
										/>
									}
								/>
								<FormControlLabel
									label="Hazardous"
									control={
										<Controller 
											name="hazardous"
											control={control}
											defaultValue={loadTemplateDetails ? loadTemplateDetails.Hazardous : false}
											render={({ field }) => 
												<Checkbox 
													{...field}
													checked={field.value}
													onChange={(e) => field.onChange(e.target.checked)}
												/>
											}
										/>
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
				<br />
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<Typography sx={classes.formSubHeaders}>Pickup List</Typography>
						<Paper elevation={3}>
							<Button sx={classes.modalButton} variant="contained" onClick={handleOpenPickupModal}>Add</Button>
							<TableContainer sx={classes.tableStyle}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>City</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Pickup Ref #</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Pickup Date</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Pallet Count</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Weight</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Contact Info</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Instructions</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{pickups && pickups.length > 0 ?
										pickups.map((row) => (
											<TableRow key={row.id} onClick={() => openEditPickupForm(row)}>
												<TableCell align="left">{row.pickupCity}</TableCell>
												<TableCell align="left">{row.pickupRefNum}</TableCell>
												<TableCell align="left">{row.pickupDate ? dayjs(row.pickupDate).format('MMM DD, YYYY') : ''}</TableCell>
												<TableCell align="left">{row.pickupPalletCount}</TableCell>
												<TableCell align="left">{row.pickupWeight}</TableCell>
												<TableCell align="left">{row.pickupContactInfo}</TableCell>
												<TableCell align="left">{row.pickupInstructions}</TableCell>
											</TableRow>
										))
										: null}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Typography sx={classes.formSubHeaders}>Delivery List</Typography>
						<Paper elevation={3}>
							<Button sx={classes.modalButton} variant="contained" onClick={handleOpenDeliveryModal}>Add</Button>
							<TableContainer sx={classes.tableStyle}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>City</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Delivery Ref #</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Delivery Date</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Pallet Count</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Weight</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Contact Info</TableCell>
											<TableCell align="left" sx={{fontWeight: 'bold'}}>Instructions</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{deliveries && deliveries.length > 0 ?
										deliveries.map((row) => (
											<TableRow key={row.id} onClick={() => openEditDeliveryForm(row)}>
												<TableCell align="left">{row.deliveryCity}</TableCell>
												<TableCell align="left">{row.deliveryRefNum}</TableCell>
												<TableCell align="left">{row.deliveryDate ? dayjs(row.deliveryDate).format('MMM DD, YYYY') : ''}</TableCell>
												<TableCell align="left">{row.deliveryPalletCount}</TableCell>
												<TableCell align="left">{row.deliveryWeight}</TableCell>
												<TableCell align="left">{row.deliveryContactInfo}</TableCell>
												<TableCell align="left">{row.deliveryInstructions}</TableCell>
											</TableRow>
										))
										: null}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Grid>
				</Grid>
				{!loading ?
				<Button
					type="submit"
					variant="contained"
					sx={classes.createButton}
				> 
					Create
				</Button>
				: <div style={{textAlign: 'center'}}><CircularProgress /></div>}
			</form>
			</> 
			: <LinearProgress />}
			<Modal open={openPickupModal} onClose={handleClosePickupModal}>
				<Box sx={classes.modalStyle}>
					<Typography variant="h4" sx={{textAlign:'center'}}>Pickup Details</Typography>
					<form key={2} onSubmit={handleSubmit2(addPickup)}>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="pickupCity"
									control={control2}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="City" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors2.pickupCity ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors2.pickupCity?.message}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									name="pickupRefNum"
									control={control2}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="Pickup Ref #" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors2.pickupRefNum ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors2.pickupRefNum?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="pickupDate"
									control={control2}
									defaultValue=""
									render={({ field }) => 
										<FormControl fullWidth sx={classes.datePickerStyle}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													slotProps={{
														textField: {
															error: false,
														},
													}}
													id="pickupDate"
													{...field}
													error={errors2.pickupDate ? true : false}
													label="Pickup Date"
												/>
											</LocalizationProvider>
										</FormControl>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors2.pickupDate?.message}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									name="pickupPalletCount"
									control={control2}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="Pallet Count" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors2.pickupPalletCount ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors2.pickupPalletCount?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="pickupWeight"
									control={control2}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="Weight (Lbs)" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors2.pickupWeight ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors2.pickupWeight?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={12}>
								<Controller
									name="pickupContactInfo"
									control={control2}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="Contact Info" 
											variant="outlined" 
											multiline
											rows={4}
											margin="normal" 
											sx={classes.formInput}
											{...field} 
											error={errors2.pickupContactInfo ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors2.pickupContactInfo?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={12}>
								<Controller
									name="pickupInstructions"
									control={control2}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="Pickup Instructions" 
											variant="outlined" 
											multiline
											rows={4}
											margin="normal" 
											sx={classes.formInput}
											{...field} 
											error={errors2.pickupInstructions ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors2.pickupInstructions?.message}
								</Typography>
							</Grid>
						</Grid>
						<Button
							type="submit"
							variant="contained"
							sx={classes.addButton}
						> 
							Add
						</Button>
					</form>
				</Box>
			</Modal>
			<Modal open={openDeliveryModal} onClose={handleCloseDeliveryModal}>
				<Box sx={classes.modalStyle}>
					<Typography variant="h4" sx={{textAlign:'center'}}>Delivery Details</Typography>
					<form key={3} onSubmit={handleSubmit3(addDelivery)}>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="deliveryCity"
									control={control3}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="City" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors3.deliveryCity ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors3.deliveryCity?.message}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									name="deliveryRefNum"
									control={control3}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="Delivery Ref #" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors3.deliveryRefNum ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors3.deliveryRefNum?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="deliveryDate"
									control={control3}
									defaultValue=""
									render={({ field }) => 
										<FormControl fullWidth sx={classes.datePickerStyle}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													slotProps={{
														textField: {
															error: false,
														},
													}}
													id="deliveryDate"
													{...field}
													error={errors3.deliveryDate ? true : false}
													label="Delivery Date"
												/>
											</LocalizationProvider>
										</FormControl>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors3.deliveryDate?.message}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									name="deliveryPalletCount"
									control={control3}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="Pallet Count" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors3.deliveryPalletCount ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors3.deliveryPalletCount?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="deliveryWeight"
									control={control3}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="Weight (Lbs)" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors3.deliveryWeight ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors3.deliveryWeight?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={12}>
								<Controller
									name="deliveryContactInfo"
									control={control3}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="Contact Info" 
											variant="outlined" 
											multiline
											rows={4}
											margin="normal" 
											sx={classes.formInput}
											{...field} 
											error={errors3.deliveryContactInfo ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors3.deliveryContactInfo?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={12}>
								<Controller
									name="deliveryInstructions"
									control={control3}
									defaultValue=""
									render={({ field }) => 
										<TextField 
											label="Delivery Instructions" 
											variant="outlined" 
											multiline
											rows={4}
											margin="normal" 
											sx={classes.formInput}
											{...field} 
											error={errors3.deliveryInstructions ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors3.deliveryInstructions?.message}
								</Typography>
							</Grid>
						</Grid>
						<Button
							type="submit"
							variant="contained"
							sx={classes.addButton}
						> 
							Add
						</Button>
					</form>
				</Box>
			</Modal>
			<Modal open={openEditPickupModal} onClose={handleCloseEditPickupModal}>
				<Box sx={classes.modalStyle}>
					<Typography variant="h4" sx={{textAlign:'center'}}>Edit Pickup Details</Typography>
					<form key={4} onSubmit={handleSubmit4(editPickup)}>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="id"
									control={control4}
									defaultValue={currentPickup.id}
									render={({ field }) => 
										<TextField 
											type="hidden"
											variant="outlined" 
											margin="normal" 
											sx={{display: 'none'}} 
											{...field} 
										/>
									}
								/>
								<Controller
									name="pickupCity"
									control={control4}
									defaultValue={currentPickup.pickupCity}
									render={({ field }) => 
										<TextField 
											label="City" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors4.pickupCity ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors4.pickupCity?.message}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									name="pickupRefNum"
									control={control4}
									defaultValue={currentPickup.pickupRefNum}
									render={({ field }) => 
										<TextField 
											label="Pickup Ref #" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors4.pickupRefNum ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors4.pickupRefNum?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="pickupDate"
									control={control4}
									defaultValue={dayjs(currentPickup.pickupDate).isValid() ? dayjs(currentPickup.pickupDate) : ""}
									render={({ field }) => 
										<FormControl fullWidth sx={classes.datePickerStyle}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													slotProps={{
														textField: {
															error: false,
														},
													}}
													id="pickupDate"
													{...field}
													error={errors4.pickupDate ? true : false}
													label="Pickup Date"
												/>
											</LocalizationProvider>
										</FormControl>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors4.pickupDate?.message}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									name="pickupPalletCount"
									control={control4}
									defaultValue={currentPickup.pickupPalletCount}
									render={({ field }) => 
										<TextField 
											label="Pallet Count" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors4.pickupPalletCount ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors4.pickupPalletCount?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="pickupWeight"
									control={control4}
									defaultValue={currentPickup.pickupWeight}
									render={({ field }) => 
										<TextField 
											label="Weight (Lbs)" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors4.pickupWeight ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors4.pickupWeight?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={12}>
								<Controller
									name="pickupContactInfo"
									control={control4}
									defaultValue={currentPickup.pickupContactInfo}
									render={({ field }) => 
										<TextField 
											label="Contact Info" 
											variant="outlined" 
											multiline
											rows={4}
											margin="normal" 
											sx={classes.formInput}
											{...field} 
											error={errors4.pickupContactInfo ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors4.pickupContactInfo?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={12}>
								<Controller
									name="pickupInstructions"
									control={control4}
									defaultValue={currentPickup.pickupInstructions}
									render={({ field }) => 
										<TextField 
											label="Instructions" 
											variant="outlined" 
											multiline
											rows={4}
											margin="normal" 
											sx={classes.formInput}
											{...field} 
											error={errors4.pickupInstructions ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors4.pickupInstructions?.message}
								</Typography>
							</Grid>
						</Grid>
						<div style={{marginTop: 25}}>
							<Button
								type="submit"
								variant="contained"
								sx={classes.editButton}
							> 
								Save
							</Button>
							<Button
								variant="outlined"
								sx={classes.cancelButton}
								onClick={() => cancelEditPickup()}
							> 
								Cancel
							</Button>
							<Button
								variant="contained"
								sx={classes.deleteButton}
								color="error"
								onClick={() => deletePickup()}
							> 
								Delete
							</Button>
						</div>
					</form>
				</Box>
			</Modal>
			<Modal open={openEditDeliveryModal} onClose={handleCloseEditDeliveryModal}>
				<Box sx={classes.modalStyle}>
					<Typography variant="h4" sx={{textAlign:'center'}}>Edit Delivery Details</Typography>
					<form key={5} onSubmit={handleSubmit5(editDelivery)}>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="id"
									control={control5}
									defaultValue={currentDelivery.id}
									render={({ field }) => 
										<TextField 
											type="hidden"
											variant="outlined" 
											margin="normal" 
											sx={{display: 'none'}} 
											{...field} 
										/>
									}
								/>
								<Controller
									name="deliveryCity"
									control={control5}
									defaultValue={currentDelivery.deliveryCity}
									render={({ field }) => 
										<TextField 
											label="City" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors5.deliveryCity ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors5.deliveryCity?.message}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									name="deliveryRefNum"
									control={control5}
									defaultValue={currentDelivery.deliveryRefNum}
									render={({ field }) => 
										<TextField 
											label="Delivery Ref #" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors5.deliveryRefNum ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors5.deliveryRefNum?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="deliveryDate"
									control={control5}
									defaultValue={dayjs(currentDelivery.deliveryDate).isValid() ? dayjs(currentDelivery.deliveryDate) : ""}
									render={({ field }) => 
										<FormControl fullWidth sx={classes.datePickerStyle}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													slotProps={{
														textField: {
															error: false,
														},
													}}
													id="deliveryDate"
													{...field}
													error={errors5.deliveryDate ? true : false}
													label="Delivery Date"
												/>
											</LocalizationProvider>
										</FormControl>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors5.deliveryDate?.message}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									name="deliveryPalletCount"
									control={control5}
									defaultValue={currentDelivery.deliveryPalletCount}
									render={({ field }) => 
										<TextField 
											label="Pallet Count" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors5.deliveryPalletCount ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors5.deliveryPalletCount?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={6}>
								<Controller
									name="deliveryWeight"
									control={control5}
									defaultValue={currentDelivery.deliveryWeight}
									render={({ field }) => 
										<TextField 
											label="Weight (Lbs)" 
											variant="outlined" 
											margin="normal" 
											sx={classes.formInput} 
											{...field} 
											error={errors5.deliveryWeight ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors5.deliveryWeight?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={12}>
								<Controller
									name="deliveryContactInfo"
									control={control5}
									defaultValue={currentDelivery.deliveryContactInfo}
									render={({ field }) => 
										<TextField 
											label="Contact Info" 
											variant="outlined" 
											multiline
											rows={4}
											margin="normal" 
											sx={classes.formInput}
											{...field} 
											error={errors5.deliveryContactInfo ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors5.deliveryContactInfo?.message}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={classes.formRow}>
							<Grid item xs={12} sm={12}>
								<Controller
									name="deliveryInstructions"
									control={control5}
									defaultValue={currentDelivery.deliveryInstructions}
									render={({ field }) => 
										<TextField 
											label="Instructions" 
											variant="outlined" 
											multiline
											rows={4}
											margin="normal" 
											sx={classes.formInput}
											{...field} 
											error={errors5.deliveryInstructions ? true : false}
										/>
									}
								/>
								<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
									{errors5.deliveryInstructions?.message}
								</Typography>
							</Grid>
						</Grid>
						<div style={{marginTop: 25}}>
							<Button
								type="submit"
								variant="contained"
								sx={classes.editButton}
							> 
								Save
							</Button>
							<Button
								variant="outlined"
								sx={classes.cancelButton}
								onClick={() => cancelEditDelivery()}
							> 
								Cancel
							</Button>
							<Button
								variant="contained"
								sx={classes.deleteButton}
								color="error"
								onClick={() => deleteDelivery()}
							> 
								Delete
							</Button>
						</div>
					</form>
				</Box>
			</Modal>
		</Container>
        <Footer />
		</>
	);
}

export { OLDNewLoadPage };