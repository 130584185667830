import axiosInstance from '../_helpers/axiosInstance';

export const loadService = {
    createLoad,
    getLoadTemplatesList,
    getLoadTemplateDetails,
    getPickupTemplatesList,
    getPickupTemplateDetails,
    getDeliveryTemplatesList,
    getDeliveryTemplateDetails,
    getCompanyLoads,
    getLoadDetails,
    editLoadDetails,
    cancelLoad,
    deleteLoad,
    editPickupsDeliveries
};

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function createLoad(loadInfo){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/load/CreateLoad`,
        data: {
            CompanyId: loadInfo.companyId,
            UserId: loadInfo.userId,
            ShipmentType: loadInfo.shipmentType,
            EquipmentType: loadInfo.equipmentType,
            ServiceLevel: loadInfo.serviceLevel,
            TrailerType: loadInfo.trailerType,
            FirstPickupLocation: loadInfo.firstPickupLocation,
            LastDeliveryLocation: loadInfo.lastDeliveryLocation,
            PalletCount: loadInfo.palletCount,
            Weight: loadInfo.weight,
            PalletHeight: loadInfo.palletHeight,
            palletDimensions: loadInfo.palletDimensions,
            FirstPickupDate: loadInfo.firstPickupDate,
            LastDeliveryDate: loadInfo.lastDeliveryDate,
            GoodsClassification: loadInfo.goodsClassification,
            LoadDescription: loadInfo.loadDescription,
            GeneralComments: loadInfo.generalComments,
            PalletJackRequired: loadInfo.palletJackRequired,
            PowerTailgateRequired: loadInfo.powerTailgateRequired,
            StackableLoad: loadInfo.stackableLoad,
            FlatbedTarped: loadInfo.flatbedTarped,
            Hazardous: loadInfo.hazardous,
            SaveAsTemplate: loadInfo.saveAsTemplate,
            PickupList: loadInfo.PickupList,
            DeliveryList: loadInfo.DeliveryList
        }
    });
}

function getLoadTemplatesList(companyId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/load/TemplatesList?CompanyId=${companyId}`,
    });
}

function getLoadTemplateDetails(loadId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/load/TemplateDetails?LoadId=${loadId}`,
    });
}

function getPickupTemplatesList(companyId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/load/PickupTemplatesList?CompanyId=${companyId}`,
    });
}

function getPickupTemplateDetails(pickupId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/load/PickupTemplateDetails?PickupId=${pickupId}`,
    });
}

function getDeliveryTemplatesList(companyId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/load/DeliveryTemplatesList?CompanyId=${companyId}`,
    });
}

function getDeliveryTemplateDetails(deliveryId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/load/DeliveryTemplateDetails?DeliveryId=${deliveryId}`,
    });
}

function getCompanyLoads(companyId, dateRange){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/load/CompanyLoads?CompanyId=${companyId}&DateRange=${dateRange}`,
    });
}

function getLoadDetails(loadId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/load/Details?LoadId=${loadId}`,
    });
}

function editLoadDetails(loadInfo){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/load/EditLoadDetails`,
        data: {
            LoadId: loadInfo.id,
            ModifiedBy: loadInfo.modifiedBy,
            ShipmentType: loadInfo.shipmentType,
            EquipmentType: loadInfo.equipmentType,
            ServiceLevel: loadInfo.serviceLevel,
            TrailerType: loadInfo.trailerType,
            FreightCost: loadInfo.freightCost,
            PalletHeight: loadInfo.palletHeight,
            PalletDimensions: loadInfo.palletDimensions,
            GoodsClassification: loadInfo.goodsClassification,
            LoadDescription: loadInfo.loadDescription,
            GeneralComments: loadInfo.generalComments,
            PalletJackRequired: loadInfo.palletJackRequired,
            PowerTailgateRequired: loadInfo.powerTailgateRequired,
            StackableLoad: loadInfo.stackableLoad,
            FlatbedTarped: loadInfo.flatbedTarped,
            Hazardous: loadInfo.hazardous,
            FreightPO: loadInfo.freightPO
        }
    });
}

function cancelLoad(loadId, cancelledBy){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/load/CancelLoad`,
        data: {
            LoadId: loadId,
            CancelledBy: cancelledBy,
        }
    });
}

function deleteLoad(loadId, deletedBy){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/load/DeleteLoad`,
        data: {
            LoadId: loadId,
            DeletedBy: deletedBy,
        }
    });
}

function editPickupsDeliveries(pickups, deliveries, pickupsToDelete, deliveriesToDelete, loadId, modifiedBy){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/load/EditPickupsDeliveries`,
        data: {
            PickupList: pickups,
            DeliveryList: deliveries,
            PickupsToDelete: pickupsToDelete,
            DeliveriesToDelete: deliveriesToDelete,
            LoadId: loadId,
            ModifiedBy: modifiedBy
        }
    });
}