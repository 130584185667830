import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { quoteActions } from '../../_actions';
import { numberWithCommas, formatShipmentType, addCapSpace, capitalizeWordsPreservingAcronyms } from '../../_helpers';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, pdf } from '@react-pdf/renderer';
import DfqLogo from '../../_resources/DFQ-Sample-Logo.png';
import dayjs from 'dayjs';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';

const classes = {
    emailButton: {
        textTransform: 'none',
        float: 'right',
        marginBottom: 2
    }
};

const styles = StyleSheet.create({
    page: {
      backgroundColor: 'white',
      padding: 30
    },
    firstRow: {
        flexDirection: 'row',
        paddingBottom: 5,
        borderBottom: '3pt solid #2C88D9'
    },
    section: {
      flexGrow: 1,
      paddingLeft: 25
    },
    pageHeader: {
        fontSize: 22,
        marginBottom: 10
    },
    logo: {
        height: 100,
        width: 100,
    },
    dfqLogo: {
        height: 25,
        width: 75,
        position: 'absolute',
        top: -18,
        right: -25
    },
    bodyText: {
        fontSize: 10
    },
    bodySection: {
        borderBottom: '3pt solid #2C88D9'
    },
    bodyHeaderBox: {
        marginTop: 4,
        flexDirection: 'row',
    },  
    bodyHeader1: {
        fontSize: 14,
        backgroundColor: '#6DC119',
        color: 'white',
        padding: 2
    },
    bodyHeader2: {
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: 10
    },
    locationContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap', // Allows wrapping to the next row
        justifyContent: 'space-between', // Distributes the space between items
        marginBottom: 10, // Adds spacing at the bottom of the container
    },
    locationSection: {
        marginTop: 5,
        width: '48%', // Each section takes up about half of the row (adjust to your needs)
        marginBottom: 10, // Spacing between rows
        padding: 5, // Optional: adds padding inside each section
        borderWidth: 1, // Optional: adds a border around each section
        borderColor: '#ccc', // Optional: sets the border color
    },
    locationHeader: {
        fontSize: 11,
        fontWeight: 'bold',
        marginTop: 5
    },
    border: {
        border: '2pt solid black',
        padding: 5,
        width: '90%',
        marginBottom: 5
    },
    quoteInfoTitle: {
        marginTop: 10,
        marginBottom: 10
    },
    quoteBox: {
        border: '1pt solid grey',
        backgroundColor: '#CCFFCC',
        padding: 5,
        width: '80%'
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5
    },
    columnDescription: {
        width: '70%',
        fontSize: 10
    },
    columnData: {
        width: '30%',
        fontSize: 10
    },
    loadBox: {
        border: '1pt solid grey',
        backgroundColor: '#FFFFCC',
        padding: 5,
        width: '80%'
    },
    vendorBox: {
        border: '1pt solid grey',
        backgroundColor: '#CCE5FF',
        padding: 5,
        width: '80%'
    }
});

function ShippingInstructionsTab(){
    let { loadId } = useParams();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.quote.loading);
    const quoteInfoForPdf = useSelector(state => state.quote.quoteInfoForPdf);
    const companyLogo = useSelector(state => state.authentication.companyLogo);
    const user = useSelector(state => state.authentication.user);

    useEffect(() => { 
        dispatch(quoteActions.getQuoteInfoForPdf(loadId));
    }, []);

    const ShippingInstructions = () => (
        <Document title="Shipping Instructions">
            <Page size="A4" style={styles.page}>
                <View style={styles.firstRow}>
                    <Image src={DfqLogo} style={styles.dfqLogo}/>
                    <Image src={`data:image/${companyLogo.CompanyLogoFormat === 'jpeg' ? 'jpeg' : 'png'};base64,${companyLogo.CompanyLogo}`} style={styles.logo}/>
                    <View style={styles.section}>
                        <Text style={styles.pageHeader}>Vendor PO / Shipping Instructions</Text>
                        <Text style={styles.bodyText}>Co. Name: {quoteInfoForPdf.VendorCompanyName}</Text>
                        <Text style={styles.bodyText}>Attention: {quoteInfoForPdf.VendorName}</Text>
                        <Text style={styles.bodyText}>Phone: {quoteInfoForPdf.VendorPhoneNumber}</Text>
                        <Text style={styles.bodyText}>Email: {quoteInfoForPdf.VendorEmail}</Text>
                        <Text style={styles.bodyText}>Postal/Zip Code: {quoteInfoForPdf.VendorPostalZip}</Text>
                        <Text style={styles.bodyText}>City: {capitalizeWordsPreservingAcronyms(quoteInfoForPdf.VendorCity)}</Text>
                    </View>
                    <View style={{ marginRight: 50 }}>
                        <Text style={[styles.bodyText, { marginTop: 31 }]}>PO No: {quoteInfoForPdf.FreightPO ? quoteInfoForPdf.FreightPO : ' '}</Text>
                        <Text style={styles.bodyText}>Load ID: {quoteInfoForPdf.LoadId}</Text>
                        <Text style={styles.bodyText}>Date Issued: {quoteInfoForPdf.DateIssued ? dayjs(quoteInfoForPdf.DateIssued).format('MMM DD, YYYY') : ''}</Text>
                        {/* <Text style={styles.bodyText}>Type of Shipment: {formatShipmentType(quoteInfoForPdf.ShipmentType)}</Text>
                        <Text style={styles.bodyText}>Equipment Type: {addCapSpace(quoteInfoForPdf.EquipmentType)}</Text>
                        <Text style={styles.bodyText}>Trailer: {addCapSpace(quoteInfoForPdf.TrailerRequested)}</Text>
                        <Text style={styles.bodyText}>Service Type: {addCapSpace(quoteInfoForPdf.ServiceLevel)}</Text> */}
                        <Text style={styles.bodyText}>Total Price: ${numberWithCommas(quoteInfoForPdf.TotalQuote)}</Text>
                    </View>
                </View>
                <View style={styles.bodySection}>
                    <View style={styles.bodyHeaderBox}>
                        <Text style={styles.bodyHeader1}>PICK UP FROM:</Text>
                    </View>
                        <View style={styles.locationContainer}>
                            {quoteInfoForPdf.PickupDetailsList.map((pickup, i) => {
                                return (
                                    <View key={i} style={styles.locationSection}>
                                        <Text style={styles.locationHeader}>Pick-up # {i + 1}</Text>
                                        <View style={styles.border} wrap={false}>
                                            <Text style={styles.bodyText}>{pickup.companyName}</Text>
                                            <Text style={styles.bodyText}>{pickup.address1}</Text>
                                            <Text style={styles.bodyText}>{pickup.pickupCity}, {pickup.pickupStateProv} {pickup.pickupPostalZip}</Text>
                                            <Text style={styles.bodyText}>Contact: {pickup.contactName}</Text>
                                            <Text style={styles.bodyText}>Phone: ({pickup.contactPhoneNumber})</Text>
                                            <Text style={styles.bodyText}>Email: ({pickup.contactEmail})</Text>
                                        </View>
                                        <Text style={styles.bodyText}>Pick-up Date: {pickup.pickupDate ? dayjs(pickup.pickupDate).format('MMM DD, YYYY') : ''}</Text>
                                        <Text style={styles.bodyText}>Order No: {pickup.pickupRefNum}</Text>
                                        <Text style={styles.bodyText}>Number of Pallets: {pickup.pickupPalletCount}</Text>
                                        <Text style={styles.bodyText}>Approx Weight: {numberWithCommas(pickup.pickupWeight)} Lbs</Text>
                                        <Text style={styles.locationHeader}>Instructions:</Text>
                                        <View style={styles.border} wrap={false}>
                                            <Text style={styles.bodyText}>{pickup.pickupInstructions}</Text>
                                        </View>
                                    </View>  
                                );
                            })}
                        </View>
                </View>
                <View style={styles.bodySection}>
                    <View style={styles.bodyHeaderBox}>
                        <Text style={styles.bodyHeader1}>DELIVERY TO:</Text>
                    </View>
                    <View style={styles.locationContainer}>
                        {quoteInfoForPdf.DeliveryDetailsList.map((delivery, i) => {
                            return (
                                <View key={i} style={styles.locationSection}>
                                    <Text style={styles.locationHeader}>Delivery # {i + 1}</Text>
                                    <View style={styles.border} wrap={false}>
                                        <Text style={styles.bodyText}>{delivery.companyName}</Text>
                                        <Text style={styles.bodyText}>{delivery.address1}</Text>
                                        <Text style={styles.bodyText}>{delivery.deliveryCity}, {delivery.deliveryStateProv} {delivery.deliveryPostalZip}</Text>
                                        <Text style={styles.bodyText}>Contact: {delivery.contactName}</Text>
                                        <Text style={styles.bodyText}>Phone: ({delivery.contactPhoneNumber})</Text>
                                        <Text style={styles.bodyText}>Email: ({delivery.contactEmail})</Text>
                                    </View>
                                    <Text style={styles.bodyText}>Delivery Date: {delivery.deliveryDate ? dayjs(delivery.deliveryDate).format('MMM DD, YYYY') : ''}</Text>
                                    <Text style={styles.bodyText}>Order No: {delivery.deliveryRefNum}</Text>
                                    <Text style={styles.bodyText}>Number of Pallets: {delivery.deliveryPalletCount}</Text>
                                    <Text style={styles.bodyText}>Approx Weight: {numberWithCommas(delivery.deliveryWeight)} Lbs</Text>
                                    <Text style={styles.locationHeader}>Instructions:</Text>
                                    <View style={styles.border} wrap={false}>
                                        <Text style={styles.bodyText}>{delivery.deliveryInstructions}</Text>
                                    </View>
                                </View>  
                            );
                        })}
                    </View>
                </View>
                {/* <View style={styles.bodySection}>
                    <View style={styles.bodyHeaderBox}>
                        <Text style={[styles.bodyHeader1, { marginBottom: 10 }]}>Special Carrier Instructions:</Text>
                    </View>
                    <Text style={[styles.bodyText, { marginBottom: 10 }]}></Text>
                </View> */}
            </Page>
            <Page size="A4" style={styles.page}>
                <Text style={styles.pageHeader}>Quote Submitted by Trucking Vendor</Text>
                <View>
                    <Text style={styles.quoteInfoTitle}>Vendor:</Text>
                    <View style={styles.vendorBox}>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Company Name:</Text>
                            <Text style={styles.columnData}>{capitalizeWordsPreservingAcronyms(quoteInfoForPdf.VendorCompanyName)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Contact Name:</Text>
                            <Text style={styles.columnData}>{capitalizeWordsPreservingAcronyms(quoteInfoForPdf.VendorName)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Email:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.VendorEmail}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Phone Number:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.VendorPhoneNumber}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Postal / Zip Code:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.VendorPostalZip}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Location:</Text>
                            <Text style={styles.columnData}>{capitalizeWordsPreservingAcronyms(quoteInfoForPdf.VendorCity)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Submission Time:</Text>
                            <Text style={styles.columnData}>August 1, 2024 12:30 PM</Text>
                        </View>
                    </View>
                    <Text style={styles.quoteInfoTitle}>Quote:</Text>
                    <View style={styles.quoteBox}>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Quote No:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.QuoteId}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Your Best Base Price:</Text>
                            <Text style={styles.columnData}>${numberWithCommas(quoteInfoForPdf.BestPrice)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Total Surcharges (Fuel + carbon Tax):</Text>
                            <Text style={styles.columnData}>${numberWithCommas(quoteInfoForPdf.TotalSurcharges)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Total Extra Fees (Drop Fees):</Text>
                            <Text style={styles.columnData}>${numberWithCommas(quoteInfoForPdf.ExtraFees)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Total Quote:</Text>
                            <Text style={styles.columnData}>${numberWithCommas(quoteInfoForPdf.TotalQuote)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Your Currency:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.Currency}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Date Equipment Available:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.DateEquipmentAvailable ? dayjs(quoteInfoForPdf.DateEquipmentAvailable).format('MMM DD, YYYY') : ''}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Your Equipment Type:</Text>
                            <Text style={styles.columnData}>{addCapSpace(quoteInfoForPdf.EquipmentType)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Your Trailer Type:</Text>
                            <Text style={styles.columnData}>{addCapSpace(quoteInfoForPdf.TrailerType)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Your Transit Days:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.TransitDays}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Your Comments:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.Comments}</Text>
                        </View>
                    </View>
                    <Text style={styles.quoteInfoTitle}>Load:</Text>
                    <View style={styles.loadBox}>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Load ID:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.LoadId}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>PO No:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.FreightPO ? quoteInfoForPdf.FreightPO : ' '}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Quoting Closes:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.QuoteBy ? dayjs(quoteInfoForPdf.QuoteBy).format('MMM DD, YYYY h:mm A') : ''}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Pickup Location(s):</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.PickupList.map((pickup) => {return pickup + '; '})}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Delivery Location(s):</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.DeliveryList.map((delivery) => {return delivery + '; '})}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Crossing Border:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.CrossingBorder ? 'Yes' : 'No'}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Shipment Type:</Text>
                            <Text style={styles.columnData}>{formatShipmentType(quoteInfoForPdf.ShipmentType)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Total Pallets:</Text>
                            <Text style={styles.columnData}>{quoteInfoForPdf.TotalPallets}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Total Weight:</Text>
                            <Text style={styles.columnData}>{numberWithCommas(quoteInfoForPdf.TotalWeight)} Lbs</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Equipment Requested:</Text>
                            <Text style={styles.columnData}>{addCapSpace(quoteInfoForPdf.EquipmentType)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Trailer Requested:</Text>
                            <Text style={styles.columnData}>{addCapSpace(quoteInfoForPdf.TrailerRequested)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.columnDescription}>Service Type:</Text>
                            <Text style={styles.columnData}>{addCapSpace(quoteInfoForPdf.ServiceLevel)}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );

    const generatePDFBlob = async () => {
        const doc = pdf(<ShippingInstructions />); // Render the PDF document
        const blob = await doc.toBlob();  // Convert it to Blob
        return blob;
    };

    const emailShippingInstructions = async  () => {
        const pdfBlob = await generatePDFBlob();
        dispatch(quoteActions.sendShippingInstructionsEmail(pdfBlob, quoteInfoForPdf.VendorEmail, user.UserId, quoteInfoForPdf.LoadId));
    };

    return (
        <>
            {!loading && quoteInfoForPdf ?
                <>
                <Alert severity="info" style={{marginBottom: 20}}>
                    <Typography style={{ fontWeight: 'bold' }}>The following PDF ensures that products are delivered accurately, on time, and in compliance with the customer's specifications. This will help avoid delays, reduce the risk of errors, and enhance customer satisfaction by ensuring that the products reach the intended destination efficiently</Typography>
                </Alert>
                <Button
                    variant="contained"
                    sx={classes.emailButton}
                    startIcon={<EmailIcon />}
                    onClick={() => emailShippingInstructions()}
                > 
                    Email to Vendor<br />
                    & Copy to You
                </Button>
                <PDFViewer style={{ width:"100%", height:"100vh" }}>
                    <ShippingInstructions />
                </PDFViewer>
                </>
            : <LinearProgress />}
        </>
    );
}

export default ShippingInstructionsTab;