import { BrowserRouter } from 'react-router-dom'
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from './components/PrivateRoute';
import { LandingPage } from './pages/LandingPage';
import { LoginPage } from './pages/LoginPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { SignUpPage } from './pages/SignUpPage';
import { SignUpFromInvitePage } from './pages/SignUpFromInvitePage';
import { ActivateAccountPage } from './pages/ActivateAccountPage';
import { NotActivePage } from './pages/NotActivePage';
import { WelcomePage } from './pages/WelcomePage';
import { CurrentLoadsPage } from './pages/Loads/CurrentLoadsPage';
import { PastLoadsPage } from './pages/Loads/PastLoadsPage';
import { NewLoadPage } from './pages/Loads/NewLoadPage';
import { OLDNewLoadPage } from './pages/Loads/NewLoadPage'; // DELETE EVENTUALLY
import { ReviewLoadPage } from './pages/Loads/ReviewLoadPage';
import { LoadDetailsPage } from './pages/Loads/LoadDetailsPage';
import { AdminPage } from './pages/AdminPage';
import { HelpPage } from './pages/HelpPage';
import { SessionExpiredPage } from './pages/SessionExpiredPage';
import { CustomAlert } from './components/CustomAlert';

function App() {
	return (
		<BrowserRouter basename="/">
			<Routes>
				<Route exact path="/" element={<LandingPage />} title="Landing"/>
				<Route path="/login" element={<LoginPage />} title="Log In"/>
				<Route path="/forgotpassword" element={<ForgotPasswordPage />} title="Forgot Password"/>
				<Route path="/resetpassword/:key" element={<ResetPasswordPage />} title="Reset Password"/>
				<Route path="/signup" element={<SignUpPage />} title="Sign Up"/>
				<Route path="/signupfrominvite/:companyId" element={<SignUpFromInvitePage />} title="Sign Up From Invite"/>
				<Route path="/activateaccount/:key" element={<ActivateAccountPage />} title="Activate Account"/>
				<Route path="/notactive" element={<NotActivePage />} title="Not Active"/>
				<Route path="/welcome" element={<PrivateRoute><WelcomePage /></PrivateRoute>} title="Welcome"/>
				<Route path="/loads/current" element={<PrivateRoute><CurrentLoadsPage /></PrivateRoute>} title="Current Loads"/>
				<Route path="/loads/past" element={<PrivateRoute><PastLoadsPage /></PrivateRoute>} title="Past Loads"/>
				<Route path="/loads/new" element={<PrivateRoute><NewLoadPage /></PrivateRoute>} title="New Load"/>
				<Route path="/loads/oldnew" element={<PrivateRoute><OLDNewLoadPage /></PrivateRoute>} title="OLD New Load"/>
				<Route path="/loads/review" element={<PrivateRoute><ReviewLoadPage /></PrivateRoute>} title="Review Load"/>
				<Route path="/loads/details/:tabName/:loadId" element={<PrivateRoute><LoadDetailsPage /></PrivateRoute>} title="Load Details"/>
				<Route path="/admin/:tabIndex" element={<PrivateRoute><AdminPage /></PrivateRoute>} title="Admin"/>
				<Route path="/help" element={<PrivateRoute><HelpPage /></PrivateRoute>} title="Help"/>
				<Route path="/sessionexpired" element={<SessionExpiredPage />} title="Session Expired"/>
			</Routes>
			<CustomAlert />
		</BrowserRouter>
	);
}

export default App;
