export const formatShipmentType = shipmentType => {
    if(shipmentType === 'ltl'){
        return 'Less Than Truckload (LTL)';
    }
    else if(shipmentType === 'tlIntermodal'){
        return 'Truckload Intermodal (TLI)';
    }
    else if(shipmentType === 'tlRoad'){
        return 'Truck Load (TL)';
    }
};