import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


function CustomAlert(){

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };
    
    var alertState = useSelector((state) => state.alert);

    useEffect(()=>{
        setOpen(true);
    },[alertState])

    if (alertState.message) {
        return (
            <Snackbar
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={alertState.message}
                action={
                <React.Fragment>
                    
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
                }
            >
                <Alert severity={alertState.type} onClose={handleClose}  >
                    { alertState.message}
                </Alert>
            </Snackbar>);
    }
    return null;
}

export { CustomAlert };