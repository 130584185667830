export const activateAccountConstants = {
    VALIDATE_ACTIVATION_KEY_REQUEST: 'VALIDATE_ACTIVATION_KEY_REQUEST',
    VALIDATE_ACTIVATION_KEY_SUCCESS: 'VALIDATE_ACTIVATION_KEY_SUCCESS',
    VALIDATE_ACTIVATION_KEY_FAILURE: 'VALIDATE_ACTIVATION_KEY_FAILURE',
    
    RESEND_ACTIVATION_REQUEST: 'RESEND_ACTIVATION_REQUEST',
    RESEND_ACTIVATION_SUCCESS: 'RESEND_ACTIVATION_SUCCESS',
    RESEND_ACTIVATION_FAILURE: 'RESEND_ACTIVATION_FAILURE',

    RESEND_ACTIVATION_FROM_LOGIN_REQUEST: 'RESEND_ACTIVATION_FROM_LOGIN_REQUEST',
    RESEND_ACTIVATION_FROM_LOGIN_SUCCESS: 'RESEND_ACTIVATION_FROM_LOGIN_SUCCESS',
    RESEND_ACTIVATION_FROM_LOGIN_FAILURE: 'RESEND_ACTIVATION_FROM_LOGIN_FAILURE',
};