import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../../components/Header';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden',
		paddingTop: 5,
		paddingBottom: 5,
		paddingLeft: 5,
		paddingRight: 5
	},
	featureBox : {
		marginTop: '15%',
		marginLeft: '10%'
	},
	dataBox : {
		marginTop: '15%',
		marginLeft: '20%',
	},
	featureTitle: {
		fontWeight: 'bold',
		fontSize: 30,
	},
	featureDescription: {

	},
	dataTitle: {
		fontWeight: 'bold',
		fontSize: 20,
	},
	dataCircle1: {
		fontSize: '5rem',
		width: '10rem',

		backgroundColor: '#d5e7f7',
		color: '#4b5c6b',
		padding: '1rem',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		marginLeft: '10%',
		marginTop: '3%'
	},
	dataCircle2: {
		fontSize: '5rem',
		width: '10rem',

		backgroundColor: '#d1efec',
		color: '#788896',
		padding: '1rem',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		marginLeft: '10%',
		marginTop: '3%'
	},
	contentContainer: {
		marginTop: 5
	},
	headerIcon: {
		color: '#1EBBD7',
		fontSize: 60
	},
	headerText: {
		fontWeight: 'bold',
		fontSize: 20
	},
	analyticsBoxBodyText: {
		fontWeight: 'bold',
		fontSize: 20,
		color: 'white'
	},
	badge: {
		'& .MuiBadge-badge': {
			fontSize: '1.5rem',  // Adjust the font size of the badge content
			minWidth: '24px',  // Adjust the width of the badge
			height: '30px',    // Adjust the height of the badge
			padding: '0 9px',   // Add some padding if necessary
			backgroundColor: 'white',
			color: 'black'
		  }
	},
	analyticRow: {
		marginBottom: 1
	}
};

function WelcomePage() {
	const dispatch = useDispatch();
	const user = useSelector(state => state.authentication.user);
    const welcomeSummary = useSelector(state => state.authentication.welcomeSummary);
	const loading = useSelector(state => state.authentication.loading);

	useEffect(() => { 
		document.title = "DFQ - Welcome";
        dispatch(userActions.getWelcomeSummary(user.CompanyId));
    }, []);

	return (
		<>
		<CssBaseline />
		<Header />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Grid container spacing={3} sx={{ marginBottom: 3 }}>
				<Grid item xs={12} sm={4}>
					<Box borderRadius={4} bgcolor="#1EBBD7" p={3}>
						<Typography variant='h5' sx={{ color: 'white' }}>Welcome Back,</Typography>
						<Typography variant='h4' sx={{ fontWeight: 'bold', color: 'white' }}>{user.FirstName} {user.LastName}</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Box borderRadius={4} bgcolor="#1EBBD7" p={3} style={{height: '100%'}}>
						<Typography variant='h4' sx={{ fontWeight: 'bold', color: 'white' }}>{user.CompanyName}</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={4}>
					<Box borderRadius={4} bgcolor="#1EBBD7" p={4} style={{height: '100%'}}>
						<Stack alignItems="center" direction="row" gap={1} sx={{marginBottom: 5}}>
							<TrendingUpIcon style={{ color: 'white', fontSize: 60 }}/>
							<Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold', fontSize: 30 }}>Analytics</Typography>
						</Stack>
						<Grid container sx={classes.analyticRow}>
							<Grid item xs={10}>
								<Typography sx={classes.analyticsBoxBodyText}>Loads that need to be awarded:</Typography>
							</Grid>
							<Grid item xs={2}>
								{!loading ? <Badge badgeContent={welcomeSummary.LoadsToBeAwarded} showZero color="primary" sx={classes.badge}/> : <CircularProgress />}
							</Grid>
						</Grid>
						<Grid container sx={classes.analyticRow}>
							<Grid item xs={10}>
								<Typography sx={classes.analyticsBoxBodyText}>Loads created this week:</Typography>
							</Grid>
							<Grid item xs={2}>
								{!loading ? <Badge badgeContent={welcomeSummary.LoadsCreatedThisWeek} showZero color="primary" sx={classes.badge}/> : <CircularProgress />}
							</Grid>
						</Grid>
						<Grid container sx={classes.analyticRow}>
							<Grid item xs={10}>
								<Typography sx={classes.analyticsBoxBodyText}>Quotes received this week:</Typography>
							</Grid>
							<Grid item xs={2}>
								{!loading ? <Badge badgeContent={welcomeSummary.QuotesReceivedThisWeek} showZero color="primary" sx={classes.badge}/> : <CircularProgress />}
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Grid container spacing={3} sx={{marginBottom: 2}}>
						<Grid item xs={12} sm={6}>
							<Box borderRadius={4} bgcolor="white" p={4} style={{height: '100%'}}>
								<Stack alignItems="center" direction="row" gap={1}>
									<LocalShippingIcon style={classes.headerIcon}/>
									<Typography variant="body1" sx={classes.headerText}>Request Quote</Typography>
								</Stack>
								<Typography>This is where you will create loads that will be sent out to vendors. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati.</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box borderRadius={4} bgcolor="white" p={4} style={{height: '100%'}}>
								<Stack alignItems="center" direction="row" gap={1}>
									<AssignmentIcon style={classes.headerIcon}/>
									<Typography variant="body1" sx={classes.headerText}>Current Loads</Typography>
								</Stack>
								<Typography>This is where you will create loads that will be sent out to vendors. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati.</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<Box borderRadius={4} bgcolor="white" p={4} style={{height: '100%'}}>
								<Stack alignItems="center" direction="row" gap={1}>
									<AssignmentTurnedInIcon style={classes.headerIcon}/>
									<Typography variant="body1" sx={classes.headerText}>Past Loads</Typography>
								</Stack>
								<Typography>This is where you will create loads that will be sent out to vendors. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati.</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box borderRadius={4} bgcolor="white" p={4} style={{height: '100%'}}>
								<Stack alignItems="center" direction="row" gap={1}>
									<SupervisorAccountIcon style={classes.headerIcon}/>
									<Typography variant="body1" sx={classes.headerText}>Admin</Typography>
								</Stack>
								<Typography>This is where you will create loads that will be sent out to vendors. At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati.</Typography>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
		{/* <Footer /> */}
		</>
	);
}

export { WelcomePage };