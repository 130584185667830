import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { userActions } from '../../_actions';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
	formHeader: {
		textAlign: 'center',
		color: '#2c88d9',
		marginTop: '1%',
		fontWeight: 'bold'
	},
	formSubHeaders: {
		color: 'black',
		fontWeight: 'bold'
	},
	signUpForm: {
		marginLeft: '33%',
		marginRight: '33%',
		marginTop: '1%',
		marginBottom: '2%',
		padding: 2,
		width: '33%'
	},
	formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
	selectInput: {
		width: '100%',
		marginTop: 2,
	},
	signUpButton: {
		width: '100%',
		marginTop: 3,
		backgroundColor: '#308cdc',
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
	},
	reviewText: {
		marginLeft: '33%',
		marginRight: '33%',
		marginBottom: '5%',
		fontWeight: 'bold'
	}
};

const invalidDomains = ['gmail', 'yahoo', 'hotmail', 'outlook', 'icloud', 'aol', 'msn'];

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First name is required'),
    lastName: Yup.string()
        .required('Last name is required'),
	email: Yup.string()
        .required('Email is required')
		.matches(/.+@.+\..+/, 'Please enter a valid email')
		.test(
			'Unauthorized Email Domain',
			'An unauthorized domain was entered. Please use your work email and avoid personal email services',
			(value) => {
				const currentDomain = value.substring(
					value.indexOf('@') + 1,
					value.indexOf('.')
				);

				return !invalidDomains.includes(currentDomain);
			}
		),
	phoneNumber: Yup.string()
        .required('Phone number is required'),
	password: Yup.string()
        .required('Password is required')
		.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
        .max(100, 'Password must not exceed 100 characters'),
	confirmPassword: Yup.string()
        .required('Confirm password is required')
		.oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
});

function SignUpFromInvitePage() {
    let { companyId } = useParams();
	const dispatch = useDispatch();
	const loading = useSelector(state => state.authentication.loading);

	const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema)
    });

	useEffect(() => { 
		document.title = "DFQ - Sign Up From Invite";
    }, []);

	const onSubmit = data => {
        data.companyId = companyId;
        dispatch(userActions.signUpFromInvite(data));
    };

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Typography variant="h2" sx={classes.formHeader}>Sign Up</Typography>
			<Paper elevation={3} sx={classes.signUpForm}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Typography variant='h5' sx={classes.formSubHeaders}>User Info</Typography>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="firstName"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="First Name" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.firstName ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.firstName?.message}
							</Typography>
						</Grid>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="lastName"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Last Name" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.lastName ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.lastName?.message}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="email"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Email" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.email ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.email?.message}
							</Typography>
						</Grid>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="phoneNumber"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Phone Number" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.phoneNumber ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.phoneNumber?.message}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="password"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Password" 
										type="password" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.password ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.password?.message}
							</Typography>
						</Grid>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="confirmPassword"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Confirm Password" 
										type="password" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.confirmPassword ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.confirmPassword?.message}
							</Typography>
						</Grid>
					</Grid>
					{!loading &&        
						<>  
							<Button
								type="submit"
								variant="contained"
								sx={classes.signUpButton}
							> 
								Sign Up
							</Button>
						</>   
						}
					{loading && <CircularProgress sx={{marginLeft: '45%', marginTop: 2}}/>}
				</form>
			</Paper>
			<Typography sx={classes.reviewText}>Once your form is submitted, you will be sent an email to confirm your email address and activate your account.</Typography>
		</Container>
		<Footer />
		</>
	);
}

export { SignUpFromInvitePage };