import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { activateAccountActions } from '../../_actions';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

function ActivateAccountPage() {
    let { key } = useParams();
    const errorCode = useSelector(state => state.activateAccount.errorCode);
    const dispatch = useDispatch();
    
    useEffect(() => { 
        dispatch(activateAccountActions.validateActivationKey(key));
    }, []);

    const sendActivationLink = () => {
        dispatch(activateAccountActions.resendActivationKey(key))
    }

    return (
        <Container maxWidth='md'>
            <CssBaseline />
            <Grid 
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: 30 }}
            >
                
                <Grid container>
                    <Typography variant="h3" gutterBottom>
                        Account activation
                    </Typography>
                </Grid>
                <Grid container>
                    {errorCode === '"Activation link has expired"' ?
                        <Button variant="contained" onClick={() => {sendActivationLink()} }>
                            Resend activation link
                        </Button>
                        : null
                    }
                </Grid>
                <Grid container style={{marginTop: 20}}><Typography>Login after successful activation by clicking <Link to="/login">here</Link></Typography></Grid>
            </Grid>
        </Container>
    );
}

export { ActivateAccountPage };