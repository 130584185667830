export function capitalizeWordsPreservingAcronyms(str) {
    return str
        .split(' ') // Split the string into an array of words
        .map(word => {
            if (word === word.toUpperCase()) {
                // Preserve fully uppercase words like "DC"
                return word;
            } else {
                // Capitalize only the first letter of other words
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
        })
        .join(' '); // Join the array back into a single string
}