import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadActions } from '../../_actions';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { provinceList } from '../../_helpers';
import { stateList } from '../../_helpers';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import PickupTemplateSearch from './PickupTemplateSearch';
import NumericFormatCustom from '../NumericFormatCustom';
import { phoneNumberAutoFormat } from '../../_helpers';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const classes = {
	formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
	datePickerStyle: {
        marginTop: 2,
    },
    // ORIGINAL STYLING
	// modalStyle: {
	// 	position: 'absolute',
	// 	top: '50%',
	// 	left: '50%',
	// 	transform: 'translate(-50%, -50%)',
	// 	width: 600,
	// 	bgcolor: 'background.paper',
	// 	border: '2px solid #000',
	// 	boxShadow: 24,
	// 	p: 4,
	// },
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Default width for smaller screens
        maxWidth: 600, // Maximum width for larger screens
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '95vh', // Maximum height set to 95% of viewport height
        overflowY: 'auto', // Enable vertical scrolling if content overflows
        '@media (min-width: 600px)': { // Media query for larger screens
          width: 600,
        },
    },
    closeIcon: {
        float: 'right'
    },
	addButton: {
		textTransform: 'none',
		left: '40%',
		paddingLeft: 3,
		paddingRight: 3,
		marginTop: 3,
        backgroundColor: '#0D9276',
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	}
};

const validationSchema2 = Yup.object().shape({
    pickupCity: Yup.string()
        .required('City is required'),
	pickupRefNum: Yup.string()
        .required('Pickup ref # is required'),
    companyName: Yup.string()
        .required('Company name is required'),
    address1: Yup.string()
        .required('Address 1 is required'),
    pickupPostalZip: Yup.string()
        .required('Postal/Zip Code is required'),
    pickupStateProv: Yup.string()
        .required('Province/State is required'),
    pickupCountry: Yup.string()
        .required('Country is required'),
    contactName: Yup.string()
        .required('Contact Name is required'),
    contactEmail: Yup.string()
        .required('Contact Email is required'),
    contactPhoneNumber: Yup.string()
        .required('Contact Phone is required'),
	pickupDate: Yup.date()
        .required('Pickup date is required')
		.typeError('Date is required'),
	pickupPalletCount: Yup.number()
        .required('Pallet count is required')
		.typeError('Please enter a number')
		.test(
            '2-decimals',
            'Pallet count must contain 2 decimal points maximum',
            (number) => Number.isInteger(number * (10 ** 2))
        ),
	pickupWeight:  Yup.number()
        .required('Weight is required')
        .max(60000, 'Weight has exceeded the maximum limit')
		.typeError('Please enter a number'),
	pickupContactInfo: Yup.string()
        .required('Contact info is required'),
	// pickupInstructions: Yup.string()
    //     .required('Instructions is required'),
});

function PickupModal(props){
    const { openPickupModal, handleClosePickupModal, pickups, setPickups, validatePickups } = props;
    const [selectedCountry, setSelectedCountry] = useState('Canada');
    const [pickupTemplate, setPickupTemplate] = useState(null);
    const user = useSelector(state => state.authentication.user);
    const pickupTemplateDetails = useSelector(state => state.load.pickupTemplateDetails);
    const dispatch = useDispatch();

    const { control: control2, handleSubmit: handleSubmit2, reset: reset2, register: register2, watch: watch2, formState: { errors: errors2, submitCount: submitCount2 }, getValues: getValues2, setValue: setValue2, trigger: trigger2} = useForm({
        resolver: yupResolver(validationSchema2)
    });

    useEffect(() => { 
		reset2(pickupTemplateDetails);
    }, [pickupTemplateDetails]);

    useEffect(() => { 
		if(pickupTemplate){
			reset2();
			dispatch(loadActions.getPickupTemplateDetails(pickupTemplate.Id));
		}
    }, [pickupTemplate]);

    useEffect(() => { 
        if(selectedCountry){
            renderProvinceStateList();
        }
	}, [selectedCountry]);

    const renderProvinceStateList = () => {
		if(selectedCountry === 'Canada'){
			return provinceList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Code }>{ state.Name }</MenuItem>);
			});
		}else if(selectedCountry === 'USA'){
			return stateList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Code }>{ state.Name }</MenuItem>);
			});
		}
    };

    const onPhoneNumberChange = ({target: {value}}) => {
        let formattedNumber = phoneNumberAutoFormat(value);
        setValue2('contactPhoneNumber', formattedNumber);
    }

    const addPickup = data => {
        const updatePickups = [
			...pickups,
			{
				id: Math.floor(Math.random() * -1000),
                pickupRefNum: data.pickupRefNum,
                companyName: data.companyName,
                pickupDate: data.pickupDate ? data.pickupDate.toJSON() : "",
                pickupPalletCount: data.pickupPalletCount,
                pickupWeight: data.pickupWeight,
				pickupCity: data.pickupCity,
                address1: data.address1,
                address2: data.address2,
                pickupPostalZip: data.pickupPostalZip,
                pickupStateProv: data.pickupStateProv,
                pickupCountry: data.pickupCountry,
                contactName: data.contactName,
                contactPhoneNumber: data.contactPhoneNumber,
                contactEmail: data.contactEmail,
				pickupContactInfo: data.pickupContactInfo,
				pickupInstructions: data.pickupInstructions
			}
		];
		setPickups(updatePickups);
		// Resets the form data so next time we open the Modal the form is clear
		reset2();
        validatePickups(updatePickups);
		handleClosePickupModal();
    };

    const closeIconClick = () => {
        reset2();
        handleClosePickupModal();
    };

    return (
        <Modal open={openPickupModal} onClose={handleClosePickupModal}>
            <Box sx={classes.modalStyle}>
                <IconButton onClick={() => closeIconClick()} sx={classes.closeIcon}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h4" sx={{textAlign:'center'}}>Pickup Details</Typography>
                <PickupTemplateSearch  companyId={user.CompanyId} setPickupTemplate={setPickupTemplate}/>
                <form key={2} onSubmit={handleSubmit2(addPickup)}>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="pickupRefNum"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Pickup Ref #" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.pickupRefNum ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.pickupRefNum?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="pickupDate"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <FormControl fullWidth sx={classes.datePickerStyle}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                slotProps={{
                                                    textField: {
                                                        error: false,
                                                        size: 'small'
                                                    },
                                                }}
                                                id="pickupDate"
                                                {...field}
                                                error={errors2.pickupDate ? true : false}
                                                label="Pickup Date"
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.pickupDate?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="pickupWeight"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Total Weight (Lbs)" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.pickupWeight ? true : false}
                                        InputProps={{
                                            inputComponent: NumericFormatCustom,
                                        }}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.pickupWeight?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="pickupPalletCount"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Pallet Count" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.pickupPalletCount ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.pickupPalletCount?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="companyName"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Company Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.companyName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.companyName?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="pickupCity"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="City" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.pickupCity ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.pickupCity?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="address1"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Address 1" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.address1 ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.address1?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="address2"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Address 2" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.address2 ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.address2?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="pickupCountry"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Country" 
                                        variant="outlined" 
                                        margin="normal" 
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.pickupCountry ? true : false}
                                        onChange={(e) => {
                                            field.onChange(e); 
                                            setSelectedCountry(e.target.value); 
                                            setValue2('pickupStateProv', '');
                                            if (submitCount2 > 0)
                                                trigger2('pickupStateProv');
                                        }}
                                    >
                                        <MenuItem value="Canada">Canada</MenuItem>
                                        <MenuItem value="USA">USA</MenuItem>
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.pickupCountry?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="pickupStateProv"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Province/State" 
                                        variant="outlined" 
                                        margin="normal" 
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.pickupStateProv ? true : false}
                                    >
                                        {renderProvinceStateList()}
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.pickupStateProv?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="pickupPostalZip"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Postal/Zip Code" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.pickupPostalZip ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.pickupPostalZip?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactName"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.contactName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.contactName?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactPhoneNumber"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Phone" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.contactPhoneNumber ? true : false}
                                        onChange={onPhoneNumberChange}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.contactPhoneNumber?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactEmail"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Email" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors2.contactEmail ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.contactEmail?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="pickupContactInfo"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        label="Contact Info" 
                                        variant="outlined" 
                                        multiline
                                        rows={3}
                                        margin="normal" 
                                        sx={classes.formInput}
                                        {...field} 
                                        error={errors2.pickupContactInfo ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.pickupContactInfo?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="pickupInstructions"
                                control={control2}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        label="Pickup Instructions" 
                                        variant="outlined" 
                                        multiline
                                        rows={3}
                                        margin="normal" 
                                        sx={classes.formInput}
                                        {...field} 
                                        error={errors2.pickupInstructions ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors2.pickupInstructions?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={classes.addButton}
                        startIcon={<AddIcon sx={{marginRight: -0.5}}/>}
                    > 
                        Add
                    </Button>
                </form>
            </Box>
        </Modal>
    );
}

export default PickupModal;