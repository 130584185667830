export const locationConstants = {
    GET_ALL_LOCATIONS_REQUEST: 'GET_ALL_LOCATIONS_REQUEST',
    GET_ALL_LOCATIONS_SUCCESS: 'GET_ALL_LOCATIONS_SUCCESS',
    GET_ALL_LOCATIONS_FAILURE: 'GET_ALL_LOCATIONS_FAILURE',

    ADD_LOCATION_REQUEST: 'ADD_LOCATION_REQUEST',
    ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
    ADD_LOCATION_FAILURE: 'ADD_LOCATION_FAILURE',

    EDIT_LOCATION_REQUEST: 'EDIT_LOCATION_REQUEST',
    EDIT_LOCATION_SUCCESS: 'EDIT_LOCATION_SUCCESS',
    EDIT_LOCATION_FAILURE: 'EDIT_LOCATION_FAILURE',

    DELETE_LOCATION_REQUEST: 'EDIT_LOCATION_REQUEST',
    DELETE_LOCATION_SUCCESS: 'EDIT_LOCATION_SUCCESS',
    DELETE_LOCATION_FAILURE: 'EDIT_LOCATION_FAILURE',
};