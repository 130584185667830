import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions } from '../../_actions';
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormControl } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const classes = {
    formHeader: {
        fontSize: 20,
        paddingBottom: 1
    },
    formSection: {
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        marginBottom: 2,
        width: '50%'
    },
    formInput: {
        width: '100%',
    },
    datePickerStyle: {
        marginTop: 2,
    },
    postLoadForQuotingButton: {
        textTransform: 'none',
        width: '100%'
    },
    modalButton: {
        textTransform: 'none',
    }
};

const validationSchema = Yup.object().shape({
	quoteDateTime: Yup.date()
        .required('Quote date/time is required')
        .typeError('Quote date/time is required'),
});

function RequestQuoteTab(){
    let { loadId } = useParams();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.quote.loading);

    const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => { 

    }, []);

    const onSubmit = data => {
        data.loadId = loadId;
        delete data.email;
        dispatch(quoteActions.postLoadForQuoting(data));
        setOpen(false); // Close modal on submit
    };

    const individualQuoting = data => {
        data.loadId = loadId;
        dispatch(quoteActions.postLoadForIndividualQuoting(data));
        setOpen(false); // Close modal on submit
    };

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <div style={{ width: '100%' }}>
            <Alert severity="info" sx={{ marginBottom: 2 }}>
                <Typography>You are able to request a quote from all vendors in the relevant service area.</Typography>
            </Alert>
            <Alert severity="info" sx={{ marginBottom: 2 }}>
                <Typography>Or you can request a quote from a specific vendor by entering their email. Click on the "Post Load for Individual" button and you will be prompted to enter an email</Typography>
            </Alert>
            <Alert severity="info">
                <Typography>If you wanted to post a load without sending any emails. Click on the "Post Load for Individual" button and leave the email field blank</Typography>
            </Alert>
            <Typography sx={classes.formHeader}>Quote Required By</Typography>
            <Paper elevation={3} sx={classes.formSection}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="quoteDateTime"
                        control={control}
                        defaultValue=""
                        render={({ field }) => 
                            <FormControl fullWidth sx={classes.datePickerStyle}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        slotProps={{
                                            textField: {
                                                error: false,
                                                size: 'small'
                                            },
                                        }}
                                        id="quoteDateTime"
                                        {...field}
                                        error={errors.quoteDateTime ? true : false}
                                        label="Date and Time"
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        }
                    />
                    <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                        {errors.quoteDateTime?.message}
                    </Typography>
                    <Controller
                        name="quoteComments"
                        control={control}
                        defaultValue=""
                        render={({ field }) => 
                            <TextField 
                                label="Quoting Comments for Freight Vendors (Optional)" 
                                variant="outlined" 
                                multiline
                                rows={3}
                                margin="normal" 
                                sx={classes.formInput}
                                {...field} 
                                error={errors.quoteComments ? true : false}
                            />
                        }
                    />
                    <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                        {errors.quoteComments?.message}
                    </Typography>
                    {!loading ? 
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={classes.postLoadForQuotingButton}
                                startIcon={<UploadIcon />}
                            > 
                                Post Load for All Vendors
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                type="button"
                                variant="contained"
                                sx={classes.postLoadForQuotingButton}
                                startIcon={<UploadIcon />}
                                onClick={handleOpenModal}
                            > 
                                Post Load for Individual
                            </Button>
                        </Grid>
                    </Grid>
                    : <LinearProgress />}
                </form>
            </Paper>
            {/* Modal for sending to individual */}
            <Dialog open={open} onClose={handleCloseModal}>
                <DialogTitle>Post Load for Individual Vendor</DialogTitle>
                <DialogContent>
                    <form>
                        <Controller
                            name="quoteDateTime"
                            control={control}
                            defaultValue=""
                            render={({ field }) => 
                                <FormControl fullWidth sx={classes.datePickerStyle}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            slotProps={{
                                                textField: {
                                                    error: false,
                                                    size: 'small'
                                                },
                                            }}
                                            id="quoteDateTime"
                                            {...field}
                                            error={errors.quoteDateTime ? true : false}
                                            label="Date and Time"
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.quoteDateTime?.message}
                        </Typography>
                        <Controller
                            name="quoteComments"
                            control={control}
                            defaultValue=""
                            render={({ field }) => 
                                <TextField 
                                    label="Quoting Comments for Freight Vendors (Optional)" 
                                    variant="outlined" 
                                    multiline
                                    rows={3}
                                    margin="normal" 
                                    sx={classes.formInput}
                                    {...field} 
                                    error={errors.quoteComments ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.quoteComments?.message}
                        </Typography>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => 
                                <TextField 
                                    label="Email" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size='small'
                                    fullWidth
                                    {...field} 
                                    error={errors.email ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.email?.message}
                        </Typography>
                    </form>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button onClick={handleCloseModal} color="error" variant="outlined" sx={classes.modalButton}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(individualQuoting)} color="primary" variant="outlined" sx={classes.modalButton}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default RequestQuoteTab;