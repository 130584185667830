import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { formatShipmentType } from '../_helpers/formatShipmentType';

const classes = {
	filterOptions: {
		padding: 2,
		marginBottom: 2
	},
    formInput: {
        width: '100%',
    },
    datePickerStyle: {
        marginTop: 2,
        width: '100%',
    },
    selectInput: {
		width: '100%',
		marginTop: 2,
	},
    resetButton:{
		textTransform: 'none',
        marginTop: 2,
        '&.MuiButton-outlined': {
            border: '1px solid grey',
            color: 'grey'
          },
	},
};

const initialLoadNo = '';
const initialShipmentType = [];
const initialFrom = '';
const initialTo = '';
const initialPickupDate = '';
const initialDeliveryDate = '';
const initialPalletCount = '';
const initialCreatedTime = '';

function FilterOptions(props){
    const { showFilters, setAppliedFilters, setFilteredCompanyLoads, companyLoads } = props;
    const [filterLoadNo, setFilterLoadNo] = useState(initialLoadNo);
    const [filterPickupDate, setFilterPickupDate] = useState(initialPickupDate);
    const [filterDeliveryDate, setFilterDeliveryDate] = useState(initialDeliveryDate);
    const [filterShipmentType, setFilterShipmentType] = useState(initialShipmentType);
    const [filterFrom, setFilterFrom] = useState(initialFrom);
    const [filterTo, setFilterTo] = useState(initialTo);
    const [filterPalletCount, setFilterPalletCount] = useState(initialPalletCount);
    const [filterCreatedTime, setFilterCreatedTime] = useState(initialCreatedTime);

    useEffect(() => { 
        const updatedCompanyLoads = companyLoads
            .filter((load) => {
                if(initialLoadNo !== filterLoadNo){
                    addAppliedFilter('filterLoadNo');
                    const filterLoadNoRegEx = new RegExp(filterLoadNo.toString(), 'gi');
                    return load.Id.toString().match(filterLoadNoRegEx);
                }
                removeAppliedFilter('filterLoadNo');
                return true;
            })
            .filter((load) => {
                if(initialPickupDate !== filterPickupDate){
                    addAppliedFilter('pickupDate');
                    return dayjs(load.FirstPickupDate).toJSON() >= dayjs(filterPickupDate).toJSON();
                }
                removeAppliedFilter('pickupDate');
                return true;
            })
            .filter((load) => {
                if(initialDeliveryDate !== filterDeliveryDate){
                    addAppliedFilter('deliveryDate');
                    return dayjs(load.LastDeliveryDate).toJSON() <= dayjs(filterDeliveryDate).toJSON();
                }
                removeAppliedFilter('deliveryDate');
                return true;
            })
            .filter((load) => {
                if(filterShipmentType.length > 0){
                    addAppliedFilter('shipmentType');
                    return filterShipmentType.includes(load.ShipmentType);
                }
                removeAppliedFilter('shipmentType');
                return true;
            })
            .filter((load) => {
                if(initialFrom !== filterFrom){
                    addAppliedFilter('filterFrom');
                    const filterFromRegEx = new RegExp(filterFrom, 'gi');
                    return load.FirstPickupLocation.match(filterFromRegEx);
                }
                removeAppliedFilter('filterFrom');
                return true;
            })
            .filter((load) => {
                if(initialTo !== filterTo){
                    addAppliedFilter('filterTo');
                    const filterToRegEx = new RegExp(filterTo, 'gi');
                    return load.LastDeliveryLocation.match(filterToRegEx);
                }
                removeAppliedFilter('filterTo');
                return true;
            })
            .filter((load) => {
                if(initialPalletCount !== filterPalletCount){
                    addAppliedFilter('filterPalletCount');
                    return load.PalletCount >= filterPalletCount;
                }
                removeAppliedFilter('filterPalletCount');
                return true;
            })
            .filter((load) => {
                if(initialCreatedTime !== filterCreatedTime){
                    addAppliedFilter('createdOn');
                    return dayjs(load.CreatedOn).toJSON() >= dayjs(filterCreatedTime).toJSON();
                }
                removeAppliedFilter('createdOn');
                return true;
            });
            setFilteredCompanyLoads(updatedCompanyLoads);
    }, [filterLoadNo, filterPickupDate, filterDeliveryDate, filterShipmentType, filterFrom, filterTo, filterPalletCount, filterCreatedTime]);

    const addAppliedFilter = (filter) => {
        setAppliedFilters((current) => {
            if (current.includes(filter)){
                return current;
            }
            return [...current, filter];
        });
    };

    const removeAppliedFilter = (filter) => {
        setAppliedFilters((current) => current.filter((e) => e !== filter));
    };

    const resetFilters = () => {
        setAppliedFilters([]);
        setFilteredCompanyLoads(companyLoads);
        setFilterLoadNo(initialLoadNo);
        setFilterPickupDate(initialPickupDate);
        setFilterDeliveryDate(initialDeliveryDate);
        setFilterShipmentType(initialShipmentType);
        setFilterFrom(initialFrom);
        setFilterTo(initialTo);
        setFilterPalletCount(initialPalletCount)
        setFilterCreatedTime(initialCreatedTime);
    };

    const handleShipmentTypeChange = (event) => {
        const {
            target: { value },
          } = event;
          setFilterShipmentType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
    };

    return (
        <>
        {showFilters ? 
        <Paper elevation={3} sx={classes.filterOptions}>
            <Typography variant='h5'>Filter Options</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <TextField 
                        value={filterLoadNo}
                        onChange={(e) => setFilterLoadNo(e.target.value)}
                        label="Load No." 
                        variant="outlined" 
                        margin="normal" 
                        size="small"
                        sx={classes.formInput}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={filterPickupDate}
                            onChange={(newValue) => setFilterPickupDate(newValue)}
                            slotProps={{
                                textField: {
                                    error: false,
                                    size: 'small'
                                },
                            }}
                            //id="pickupDate"
                            label="Pickup Date"
                            sx={classes.datePickerStyle}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={filterDeliveryDate}
                            onChange={(newValue) => setFilterDeliveryDate(newValue)}
                            slotProps={{
                                textField: {
                                    error: false,
                                    size: 'small'
                                },
                            }}
                            //id="deliveryDate"
                            label="Delivery Date"
                            sx={classes.datePickerStyle}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="shipmentTypeLabel" sx={{marginTop: 2}}>Type of Shipment</InputLabel>
                        <Select 
                            multiple
                            value={filterShipmentType} 
                            onChange={handleShipmentTypeChange} 
                            labelId="shipmentTypeLabel" 
                            id="shipmentType" 
                            sx={classes.selectInput} 
                            label="Type of Shipment"
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={formatShipmentType(value)} />
                                    ))}
                              </Box>
                            )}
                        >
                            <MenuItem key="tlRoad" value="tlRoad">TL Road</MenuItem>
                            <MenuItem key="tlIntermodal" value="tlIntermodal">TL Intermodal</MenuItem>
                            <MenuItem key="ltl" value="ltl">LTL</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <TextField 
                        value={filterFrom}
                        onChange={(e) => setFilterFrom(e.target.value)}
                        label="From" 
                        variant="outlined" 
                        margin="normal" 
                        size="small"
                        sx={classes.formInput}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField 
                        value={filterTo}
                        onChange={(e) => setFilterTo(e.target.value)}
                        label="To" 
                        variant="outlined" 
                        margin="normal" 
                        size="small"
                        sx={classes.formInput}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField 
                        type="number"
                        value={filterPalletCount}
                        onChange={(e) => setFilterPalletCount(e.target.value)}
                        label="Pallets" 
                        variant="outlined" 
                        margin="normal" 
                        size="small"
                        sx={classes.formInput}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={filterCreatedTime}
                            onChange={(newValue) => setFilterCreatedTime(newValue)}
                            slotProps={{
                                textField: {
                                    error: false,
                                    size: 'small'
                                },
                            }}
                            //id="deliveryDate"
                            label="Created Time"
                            sx={classes.datePickerStyle}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Button onClick={resetFilters} variant="outlined" fontSize="small" sx={classes.resetButton} startIcon={<ReplayIcon />}>Clear Filters</Button>
        </Paper>
        : null}
        </>
    );
}

export default FilterOptions;