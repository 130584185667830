import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = {
    loggedIn: user !== null,
    loading: false,
    user,
    welcomeSummary: [],
    personalInfo: null,
    companyInfo: null,
    usersToManage: [],
    companyLogo: null,
    errorCode: null,
};

export function authentication(state = initialState, action) {
    switch(action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {};
        case userConstants.USER_LOGOUT:
                return {};
        case userConstants.SIGN_UP_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.SIGN_UP_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.SIGN_UP_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.SIGN_UP_FROM_INVITE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.SIGN_UP_FROM_INVITE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.SIGN_UP_FROM_INVITE_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.GET_WELCOME_SUMMARY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GET_WELCOME_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                welcomeSummary: action.welcomeSummary
            };
        case userConstants.GET_WELCOME_SUMMARY_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.GET_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GET_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                personalInfo: action.personalInfo
            };
        case userConstants.GET_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.UPDATE_PERSONAL_INFO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.UPDATE_PERSONAL_INFO_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.UPDATE_PERSONAL_INFO_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.GET_COMPANY_INFO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GET_COMPANY_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                companyInfo: action.companyInfo
            };
        case userConstants.GET_COMPANY_INFO_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.UPDATE_COMPANY_INFO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.UPDATE_COMPANY_INFO_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.UPDATE_COMPANY_INFO_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.INVITE_USER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.INVITE_USER_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.INVITE_USER_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.GET_USERS_TO_MANAGE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GET_USERS_TO_MANAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                usersToManage: action.usersToManage
            };
        case userConstants.GET_USERS_TO_MANAGE_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.SEND_CONTACT_US_FORM_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.SEND_CONTACT_US_FORM_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.SEND_CONTACT_US_FORM_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.EDIT_USER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.EDIT_USER_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.EDIT_USER_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.DELETE_USER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.DELETE_USER_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.GET_COMPANY_LOGO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GET_COMPANY_LOGO_SUCCESS:
            return {
                ...state,
                loading: false,
                companyLogo: action.companyLogo
            };
        case userConstants.GET_COMPANY_LOGO_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.RESET_MANAGE_USER_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.RESET_MANAGE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.RESET_MANAGE_USER_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.SEND_RESET_PASSWORD_LINK_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.SEND_RESET_PASSWORD_LINK_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.SEND_RESET_PASSWORD_LINK_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.VALIDATE_RESET_PASSWORD_KEY_REQUEST:
            return {
                ...state,
                loading: true,
                errorCode: null
            };
        case userConstants.VALIDATE_RESET_PASSWORD_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                errorCode: null
            };
        case userConstants.VALIDATE_RESET_PASSWORD_KEY_FAILURE:
            return {
                ...state,
                loading: false,
                errorCode: action.error
            };
        case userConstants.RESET_PASSWORD_WITH_KEY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.RESET_PASSWORD_WITH_KEY_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.RESET_PASSWORD_WITH_KEY_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}