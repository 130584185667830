import React, { useEffect, useState, useMemo } from 'react';
import { compareUtil } from '../../_helpers';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import DeliveryModal from './DeliveryModal';
import EditDeliveryModal from './EditDeliveryModal';

const headCells = [
    {id: 'deliveryRefNum', numeric: true, label: 'Delivery Ref #'},
    {id: 'companyName', numeric: false, label: 'Company Name'},
    {id: 'deliveryDate', numeric: false, label: 'Delivery Date'},
    {id: 'deliveryPalletCount', numeric: true, label: 'Pallet Count'},
    {id: 'deliveryWeight', numeric: true, label: 'Weight (Lbs)'},
    {id: 'deliveryCity', numeric: false, label: 'City'},
    {id: 'address1', numeric: false, label: 'Address 1'},
    {id: 'address2', numeric: false, label: 'Address 2'},
    {id: 'deliveryPostalZip', numeric: false, label: 'Postal/Zip Code'},
    {id: 'deliveryStateProv', numeric: false, label: 'Province/State'},
    {id: 'deliveryCountry', numeric: false, label: 'Country'},
    {id: 'contactName', numeric: false, label: 'Contact Name'},
    {id: 'contactPhoneNumber', numeric: false, label: 'Contact Phone'},
    {id: 'contactEmail', numeric: false, label: 'Contact Email'},
    {id: 'deliveryContactInfo', numeric: false, label: 'Contact Info'},
    {id: 'deliveryInstructions', numeric: false, label: 'Instructions'},
];

const classes = {
    tableSection: {
        marginBottom: 2
    },
    deliveryListHeader: {
        fontSize: 20
    },
	modalButton: {
		textTransform: 'none',
		margin: 1,
        backgroundColor: '#0D9276',
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	},
    tableStyle: {
        whiteSpace: 'nowrap',
        maxHeight: 335
    },
	tableHeaderStyle: {
		fontWeight: 'bold'
	}
};

function DeliveryList(props) {
    const { deliveries, setDeliveries, validateDeliveries } = props;
    // Next 3 are for opening/closing the modals used to add deliveries
    const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
	const handleOpenDeliveryModal = () => setOpenDeliveryModal(true);
	const handleCloseDeliveryModal = () => setOpenDeliveryModal(false);
    // Next 4 are for editing Deliveries
	const [openEditDeliveryModal, setOpenEditDeliveryModal] = useState(false);
	const handleOpenEditDeliveryModal = () => setOpenEditDeliveryModal(true);
	// const handleCloseEditDeliveryModal = () => setOpenEditDeliveryModal(false);
	const [currentDelivery, setCurrentDelivery] = useState({});
    // Standard React state variables
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('deliveryDate');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const openEditDeliveryForm = delivery => {
		handleOpenEditDeliveryModal();
		setCurrentDelivery({...delivery});
	};

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onRequestSort = (event, property) => {
        handleRequestSort(event, property);
    };

    const handleCloseEditDeliveryModal = (event, reason) => {
        if(reason && reason === "backdropClick") {
            return;
        }

        setOpenEditDeliveryModal(false);
    };
    
    return (
        <>
            <Typography sx={classes.deliveryListHeader}>Delivery List</Typography>
            <Paper elevation={3} sx={classes.tableSection}>
                <Button sx={classes.modalButton} variant="contained" startIcon={<AddIcon sx={{marginRight: -0.5}}/>} onClick={handleOpenDeliveryModal}>Add</Button>
                <TableContainer sx={classes.tableStyle}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={'left'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                            sx={classes.tableHeaderStyle}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deliveries && deliveries.length > 0 ?
                            compareUtil.stableSort(deliveries, compareUtil.getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow hover sx={{ cursor: 'pointer' }} key={row.id} onClick={() => openEditDeliveryForm(row)}>
                                    <TableCell align="left">{row.deliveryRefNum ? row.deliveryRefNum : ''}</TableCell>
                                    <TableCell align="left">{row.companyName}</TableCell>
                                    <TableCell align="left">{row.deliveryDate ? dayjs(row.deliveryDate).format('MMM DD, YYYY') : ''}</TableCell>
                                    <TableCell align="left">{row.deliveryPalletCount ? row.deliveryPalletCount : ''}</TableCell>
                                    <TableCell align="left">{row.deliveryWeight ? row.deliveryWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</TableCell>
                                    <TableCell align="left">{row.deliveryCity}</TableCell>
                                    <TableCell align="left">{row.address1}</TableCell>
                                    <TableCell align="left">{row.address2}</TableCell>
                                    <TableCell align="left">{row.deliveryPostalZip}</TableCell>
                                    <TableCell align="left">{row.deliveryStateProv}</TableCell>
                                    <TableCell align="left">{row.deliveryCountry}</TableCell>
                                    <TableCell align="left">{row.contactName}</TableCell>
                                    <TableCell align="left">{row.contactPhoneNumber}</TableCell>
                                    <TableCell align="left">{row.contactEmail}</TableCell>
                                    <TableCell align="left">{row.deliveryContactInfo}</TableCell>
                                    <TableCell align="left">{row.deliveryInstructions}</TableCell>
                                </TableRow>
                            ))
                            : null}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={deliveries.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <DeliveryModal openDeliveryModal={openDeliveryModal} handleCloseDeliveryModal={handleCloseDeliveryModal} deliveries={deliveries} setDeliveries={setDeliveries} validateDeliveries={validateDeliveries}/>
            <EditDeliveryModal openEditDeliveryModal={openEditDeliveryModal} handleCloseEditDeliveryModal={handleCloseEditDeliveryModal} deliveries={deliveries} setDeliveries={setDeliveries} currentDelivery={currentDelivery} validateDeliveries={validateDeliveries}/>
        </>
    );
}

export default DeliveryList;