import { EmailRounded } from '@mui/icons-material';
import axiosInstance from '../_helpers/axiosInstance';

export const userService = {
    login,
    logout,
    signUp,
    signUpFromInvite,
    getWelcomeSummary,
    validateActivationKey,
    resendActivationKey,
    getPersonalInfo,
    updatePersonalInfo,
    getCompanyInfo,
    updateCompanyInfo,
    resetPassword,
    inviteUser,
    getUsersToManage,
    sendContactUsForm,
    editUser,
    deleteUser,
    getCompanyLogo,
    resetManageUserPassword,
    sendResetPasswordLink,
    validateResetPasswordKey,
    resetPasswordWithKey
};

const API_URL = process.env.REACT_APP_API_ENDPOINT;
//const API_URL = 'https://localhost:7189/api';

function login(userID, password){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/user/LogIn`,
        data: {
            UserId: userID,
            Password: password
        }
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('jwtBearer');
    // localStorage.removeItem('refreshToken');
}

function signUp(data){
    var bodyFormData = new FormData();
    bodyFormData.append('FirstName', data.firstName);
    bodyFormData.append('LastName', data.lastName);
    bodyFormData.append('Email', data.email);
    bodyFormData.append('PhoneNumber', data.phoneNumber);
    bodyFormData.append('Password', data.password);
    bodyFormData.append('CompanyName', data.companyName);
    bodyFormData.append('CompanyCountry', data.companyCountry);
    bodyFormData.append('CompanyProvinceState', data.companyProvinceState);
    bodyFormData.append('CompanyCity', data.companyCity);
    bodyFormData.append('CompanyAddress', data.companyAddress);
    bodyFormData.append('CompanyPostalZip', data.companyPostalZip);
    bodyFormData.append('PrimaryContactPhone', data.primaryContactPhone);
    bodyFormData.append('PrimaryContactEmail', data.primaryContactEmail);
    bodyFormData.append('CompanyLogo', data.companyLogo[0] == null ? "" : data.companyLogo[0]);
    bodyFormData.append('LogoImageName', data.companyLogo[0] == null ? "" : data.companyLogo[0].name);

    const requestOptions = {
        method: 'POST',
        body: bodyFormData
    };

    return fetch(`${API_URL}/user/SignUp`, requestOptions);
}

function signUpFromInvite(data){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/user/SignUp/FromInvite`,
        data: {
            FirstName: data.firstName,
            LastName: data.lastName,
            Email: data.email,
            PhoneNumber: data.phoneNumber,
            Password: data.password,
            CompanyId: data.companyId,
        }
    });
}

function getWelcomeSummary(companyId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/user/GetWelcomeSummary?CompanyId=${companyId}`,
    });
}

function validateActivationKey(resetKey){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/ActivationKey/validate`,
        data: {
            ActivationKey: resetKey
        }
    });
}

function resendActivationKey(resetKey){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/ActivationKey/resend`,
        data: {
            ActivationKey: resetKey
        }
    });
}

function getPersonalInfo(email){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/user/GetPersonalInfo?Email=${email}`,
    });
}

function updatePersonalInfo(data){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/Update/PersonalInfo`,
        data: {
            Email: data.email,
            FirstName: data.firstName,
            LastName: data.lastName,
            PhoneNumber: data.phoneNumber,
        }
    });
}

function getCompanyInfo(email){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/user/GetCompanyInfo?Email=${email}`,
    });
}

function updateCompanyInfo(data){
    var token = localStorage.getItem('jwtBearer').replace(/^"(.*)"$/, '$1');

    var bodyFormData = new FormData();
    bodyFormData.append('EditorEmail', data.editorEmail);
    bodyFormData.append('CompanyName', data.companyName);
    bodyFormData.append('CompanyCountry', data.companyCountry);
    bodyFormData.append('CompanyProvinceState', data.companyProvinceState);
    bodyFormData.append('CompanyCity', data.companyCity);
    bodyFormData.append('CompanyAddress', data.companyAddress);
    bodyFormData.append('CompanyPostalZip', data.companyPostalZip);
    bodyFormData.append('Currency', data.currency);
    bodyFormData.append('PrimaryContactEmail', data.primaryContactEmail);
    bodyFormData.append('PrimaryContactPhone', data.primaryContactPhone);
    bodyFormData.append('CompanyLogo', data.companyLogo == null ? "" : data.companyLogo);
    bodyFormData.append('LogoImageName', data.companyLogo == null ? "" : data.companyLogo.name);

    const requestOptions = {
        method: 'PUT',
        body: bodyFormData,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${API_URL}/user/Update/CompanyInfo`, requestOptions);
}

function resetPassword(data){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/Password/Reset`,
        data: {
            Email: data.email,
            NewPassword: data.password,
        }
    });
}

function inviteUser(data){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/user/Invite`,
        data: {
            Email: data.email,
            InviteFromEmail: data.inviteFromEmail,
            CompanyId: data.companyId
        }
    });
}

function getUsersToManage(companyId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/user/GetUsersToManage?CompanyId=${companyId}`,
    });
}

function sendContactUsForm(data){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/user/ContactUs`,
        data: {
            FirstName: data.firstName,
            LastName: data.lastName,
            Email: data.email,
            Body: data.body
        }
    });
}

function editUser(data){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/EditUser`,
        data: {
            Id: data.id,
            Email: data.email,
            FirstName: data.firstName,
            LastName: data.lastName,
            PhoneNumber: data.phoneNumber
        }
    });
}

function deleteUser(userId){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/Delete`,
        data: {
            Id: userId
        }
    });
}

function getCompanyLogo(companyId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/user/GetCompanyLogo?CompanyId=${companyId}`,
    });
}

function resetManageUserPassword(email){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/user/ResetManageUserPassword`,
        data: {
            Email: email
        }
    });
}

function sendResetPasswordLink(data){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/password/sendResetLink`,
        data: {
            Email: data.email
        }
    });
}

function validateResetPasswordKey(resetKey){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/password/validateResetKey`,
        data: {
            ResetPasswordKey: resetKey
        }
    });
}

function resetPasswordWithKey(data, key){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/password/resetWithKey`,
        data: {
            ResetPasswordKey: key,
            NewPassword: data.password,
        }
    });
}