import { locationConstants } from '../_constants';
import { locationService } from '../_services';
import { alertActions } from './';

export const locationActions = {
    getLocationList,
    addLocation,
    editLocation,
    deleteLocation
};

function getLocationList(companyId) {
    return dispatch => {
        dispatch(request());
        locationService.getLocationList(companyId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        dispatch(alertActions.success('Found saved locations!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: locationConstants.GET_ALL_LOCATIONS_REQUEST } }
    function success(locationList) { return { type: locationConstants.GET_ALL_LOCATIONS_SUCCESS, locationList } }
    function failure() { return { type: locationConstants.GET_ALL_LOCATIONS_FAILURE } }
}

function addLocation(locationInfo) {
    return dispatch => {
        dispatch(request());
        locationService.addLocation(locationInfo)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Location was added!'));
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: locationConstants.ADD_LOCATION_REQUEST } }
    function success() { return { type: locationConstants.ADD_LOCATION_SUCCESS } }
    function failure() { return { type: locationConstants.ADD_LOCATION_FAILURE } }
}

function editLocation(locationInfo) {
    return dispatch => {
        dispatch(request());
        locationService.editLocation(locationInfo)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Location edited!'));
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: locationConstants.EDIT_LOCATION_REQUEST } }
    function success() { return { type: locationConstants.EDIT_LOCATION_SUCCESS } }
    function failure() { return { type: locationConstants.EDIT_LOCATION_FAILURE } }
}

function deleteLocation(locationId, modifiedBy) {
    return dispatch => {
        dispatch(request());
        locationService.deleteLocation(locationId, modifiedBy)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Location deleted!'));
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: locationConstants.DELETE_LOCATION_REQUEST } }
    function success() { return { type: locationConstants.DELETE_LOCATION_SUCCESS } }
    function failure() { return { type: locationConstants.DELETE_LOCATION_FAILURE } }
}