import { activateAccountConstants } from '../_constants';

const initialState = {
    validatingKey: false,
    requestingReset: false,
    keyValid: false,
    errorCode: null,
    activationKeyResetSuccess: false
}

export function activateAccount(state = initialState, action) {
    let newState = {...state};
    switch (action.type) {
        case activateAccountConstants.VALIDATE_ACTIVATION_KEY_REQUEST:
            newState.validatingKey = true;
            newState.activationKeyResetSuccess = false;
            newState.keyValid = false;
            newState.errorCode = null;
            return newState;
        case activateAccountConstants.VALIDATE_ACTIVATION_KEY_SUCCESS:
            newState.validatingKey = false;
            newState.keyValid = true;
            return newState;
        case activateAccountConstants.VALIDATE_ACTIVATION_KEY_FAILURE:
            newState.validatingKey = false;
            newState.keyValid = false;
            newState.errorCode = action.error;
            return newState;
        case activateAccountConstants.RESEND_ACTIVATION_REQUEST:
            newState.requestingReset = true;
            return newState;
        case activateAccountConstants.RESEND_ACTIVATION_SUCCESS:
            newState.requestingReset = false;
            newState.activationKeyResetSuccess = true;
            return newState;
        case activateAccountConstants.RESEND_ACTIVATION_KEY_FAILURE:
            newState.requestingReset = false;
            newState.activationKeyResetSuccess = false;
            newState.errorCode = action.error;
            return newState;
        case activateAccountConstants.RESEND_ACTIVATION_FROM_LOGIN_REQUEST:
                newState.requestingReset = true;
                return newState;
        case activateAccountConstants.RESEND_ACTIVATION_FROM_LOGIN_SUCCESS:
                newState.requestingReset = false;
                newState.activationKeyResetSuccess = true;
                return newState;
        case activateAccountConstants.RESEND_ACTIVATION_FROM_LOGIN_FAILURE:
                newState.requestingReset = false;
                newState.activationKeyResetSuccess = false;
                newState.errorCode = action.error;
                return newState;
        default:
            return state
    }
}