import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadActions } from '../../_actions';
import { provinceList } from '../../_helpers';
import { stateList } from '../../_helpers';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from '@mui/material';
import Button from '@mui/material/Button';
import DeliveryTemplateSearch from './DeliveryTemplateSearch';
import NumericFormatCustom from '../NumericFormatCustom';
import { phoneNumberAutoFormat } from '../../_helpers';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const classes = {
	formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
	datePickerStyle: {
        marginTop: 2,
    },
    // ORIGINAL STYLING
	// modalStyle: {
	// 	position: 'absolute',
	// 	top: '50%',
	// 	left: '50%',
	// 	transform: 'translate(-50%, -50%)',
	// 	width: 600,
	// 	bgcolor: 'background.paper',
	// 	border: '2px solid #000',
	// 	boxShadow: 24,
	// 	p: 4,
	// },
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Default width for smaller screens
        maxWidth: 600, // Maximum width for larger screens
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '95vh', // Maximum height set to 95% of viewport height
        overflowY: 'auto', // Enable vertical scrolling if content overflows
        '@media (min-width: 600px)': { // Media query for larger screens
          width: 600,
        },
    },
    closeIcon: {
        float: 'right'
    },
	addButton: {
		textTransform: 'none',
		left: '40%',
		paddingLeft: 3,
		paddingRight: 3,
		marginTop: 3,
        backgroundColor: '#0D9276',
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	}
};

const validationSchema3 = Yup.object().shape({
    deliveryCity: Yup.string()
        .required('City is required'),
	deliveryRefNum: Yup.string()
        .required('Delivery ref # is required'),
    companyName: Yup.string()
        .required('Company name is required'),
    address1: Yup.string()
        .required('Address 1 is required'),
    deliveryPostalZip: Yup.string()
        .required('Postal/Zip Code is required'),
    deliveryStateProv: Yup.string()
        .required('Province/State is required'),
    deliveryCountry: Yup.string()
        .required('Country is required'),
    contactName: Yup.string()
        .required('Contact Name is required'),
    contactEmail: Yup.string()
        .required('Contact Email is required'),
    contactPhoneNumber: Yup.string()
        .required('Contact Phone is required'),
	deliveryDate: Yup.date()
        .required('Delivery date is required')
		.typeError('Date is required'),
	deliveryPalletCount: Yup.number()
        .required('Pallet count is required')
		.typeError('Please enter a number')
		.test(
            'ends-with-0-or-.5',
            'Pallet count must contain 2 decimal points maximum',
            (number) => Number.isInteger(number * (10 ** 2))
        ),
	deliveryWeight:  Yup.number()
        .required('Weight is required')
        .max(60000, 'Weight has exceeded the maximum limit')
		.typeError('Please enter a number'),
	deliveryContactInfo: Yup.string()
        .required('Contact info is required'),
	// deliveryInstructions: Yup.string()
    //     .required('Instructions is required'),
});

function DeliveryModal(props){
    const { openDeliveryModal, handleCloseDeliveryModal, deliveries, setDeliveries, validateDeliveries } = props;
    const [selectedCountry, setSelectedCountry] = useState('Canada');
    const [deliveryTemplate, setDeliveryTemplate] = useState(null);
    const user = useSelector(state => state.authentication.user);
    const deliveryTemplateDetails = useSelector(state => state.load.deliveryTemplateDetails);
    const dispatch = useDispatch();

	const { control: control3, handleSubmit: handleSubmit3, reset: reset3, register: register3, watch: watch3, formState: { errors: errors3, submitCount: submitCount3 }, getValues: getValues3, setValue: setValue3, trigger: trigger3} = useForm({
        resolver: yupResolver(validationSchema3)
    });

    useEffect(() => { 
		reset3(deliveryTemplateDetails);
    }, [deliveryTemplateDetails]);

    useEffect(() => { 
		if(deliveryTemplate){
			reset3();
			dispatch(loadActions.getDeliveryTemplateDetails(deliveryTemplate.Id));
		}
    }, [deliveryTemplate]);

    useEffect(() => { 
        if(selectedCountry){
            renderProvinceStateList();
        }
	}, [selectedCountry]);

    const renderProvinceStateList = () => {
		if(selectedCountry === 'Canada'){
			return provinceList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Code }>{ state.Name }</MenuItem>);
			});
		}else if(selectedCountry === 'USA'){
			return stateList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Code }>{ state.Name }</MenuItem>);
			});
		}
    };

    const onPhoneNumberChange = ({target: {value}}) => {
        let formattedNumber = phoneNumberAutoFormat(value);
        setValue3('contactPhoneNumber', formattedNumber);
    }

	const addDelivery = data => {
		const updateDeliveries = [
			...deliveries,
			{
				id: Math.floor(Math.random() * -1000),
                deliveryRefNum: data.deliveryRefNum,
                companyName: data.companyName,
                deliveryDate: data.deliveryDate ? data.deliveryDate.toJSON() : "",
                deliveryPalletCount: data.deliveryPalletCount,
                deliveryWeight: data.deliveryWeight,
				deliveryCity: data.deliveryCity,
                address1: data.address1,
                address2: data.address2,
                deliveryPostalZip: data.deliveryPostalZip,
                deliveryStateProv: data.deliveryStateProv,
                deliveryCountry: data.deliveryCountry,
                contactName: data.contactName,
                contactPhoneNumber: data.contactPhoneNumber,
                contactEmail: data.contactEmail,
				deliveryContactInfo: data.deliveryContactInfo,
				deliveryInstructions: data.deliveryInstructions
			}
		];
		setDeliveries(updateDeliveries);
		// Resets the form data so next time we open the Modal the form is clear
		reset3();
        validateDeliveries(updateDeliveries);
		handleCloseDeliveryModal();
    };

    const closeIconClick = () => {
        reset3();
        handleCloseDeliveryModal();
    };

    return (
        <Modal open={openDeliveryModal} onClose={handleCloseDeliveryModal}>
            <Box sx={classes.modalStyle}>
                <IconButton onClick={() => closeIconClick()} sx={classes.closeIcon}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h4" sx={{textAlign:'center'}}>Delivery Details</Typography>
                <DeliveryTemplateSearch companyId={user.CompanyId} setDeliveryTemplate={setDeliveryTemplate}/>
                <form key={3} onSubmit={handleSubmit3(addDelivery)}>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryRefNum"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Delivery Ref #" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.deliveryRefNum ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.deliveryRefNum?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryDate"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <FormControl fullWidth sx={classes.datePickerStyle}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                slotProps={{
                                                    textField: {
                                                        error: false,
                                                        size: 'small'
                                                    },
                                                }}
                                                id="deliveryDate"
                                                {...field}
                                                error={errors3.deliveryDate ? true : false}
                                                label="Delivery Date"
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.deliveryDate?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryWeight"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Total Weight (Lbs)" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.deliveryWeight ? true : false}
                                        InputProps={{
                                            inputComponent: NumericFormatCustom,
                                        }}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.deliveryWeight?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryPalletCount"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Pallet Count" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.deliveryPalletCount ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.deliveryPalletCount?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="companyName"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Company Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.companyName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.companyName?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryCity"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="City" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.deliveryCity ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.deliveryCity?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="address1"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Address 1" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.address1 ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.address1?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="address2"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Address 2" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.address2 ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.address2?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryCountry"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Country" 
                                        variant="outlined" 
                                        margin="normal" 
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.deliveryCountry ? true : false}
                                        onChange={(e) => {
                                            field.onChange(e); 
                                            setSelectedCountry(e.target.value); 
                                            setValue3('deliveryStateProv', '');
                                            if (submitCount3 > 0)
                                                trigger3('deliveryStateProv');
                                        }}
                                    >
                                        <MenuItem value="Canada">Canada</MenuItem>
                                        <MenuItem value="USA">USA</MenuItem>
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.deliveryCountry?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryStateProv"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Province/State" 
                                        variant="outlined" 
                                        margin="normal" 
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.deliveryStateProv ? true : false}
                                    >
                                        {renderProvinceStateList()}
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.deliveryStateProv?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="deliveryPostalZip"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Postal/Zip Code" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.deliveryPostalZip ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.deliveryPostalZip?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactName"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.contactName ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.contactName?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactPhoneNumber"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Phone" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.contactPhoneNumber ? true : false}
                                        onChange={onPhoneNumberChange}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.contactPhoneNumber?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="contactEmail"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Contact Email" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors3.contactEmail ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.contactEmail?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="deliveryContactInfo"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        label="Contact Info" 
                                        variant="outlined" 
                                        multiline
                                        rows={3}
                                        margin="normal" 
                                        sx={classes.formInput}
                                        {...field} 
                                        error={errors3.deliveryContactInfo ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.deliveryContactInfo?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                                name="deliveryInstructions"
                                control={control3}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        label="Delivery Instructions" 
                                        variant="outlined" 
                                        multiline
                                        rows={3}
                                        margin="normal" 
                                        sx={classes.formInput}
                                        {...field} 
                                        error={errors3.deliveryInstructions ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors3.deliveryInstructions?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={classes.addButton}
                        startIcon={<AddIcon sx={{marginRight: -0.5}}/>}
                    > 
                        Add
                    </Button>
                </form>
            </Box>
        </Modal>
    );
}

export default DeliveryModal;