export const stateList = [
	{ Code: 'AL', Name: 'Alabama' },
	{ Code: 'AK', Name: 'Alaska' },
	{ Code: 'AZ', Name: 'Arizona' },
	{ Code: 'AR', Name: 'Arkansas' },
	{ Code: 'AS', Name: 'American Samoa' },
	{ Code: 'CA', Name: 'California' },
	{ Code: 'CO', Name: 'Colorado' },
	{ Code: 'CT', Name: 'Connecticut' },
	{ Code: 'DE', Name: 'Delaware' },
	{ Code: 'DC', Name: 'District of Columbia' },
	{ Code: 'FL', Name: 'Florida' },
	{ Code: 'GA', Name: 'Georgia' },
	{ Code: 'GU', Name: 'Guam' },
	{ Code: 'HI', Name: 'Hawaii' },
	{ Code: 'ID', Name: 'Idaho' },
	{ Code: 'IL', Name: 'Illinois' },
	{ Code: 'IN', Name: 'Indiana' },
	{ Code: 'IA', Name: 'Iowa' },
	{ Code: 'KS', Name: 'Kansas' },
	{ Code: 'KY', Name: 'Kentucky' },
	{ Code: 'LA', Name: 'Louisiana' },
	{ Code: 'ME', Name: 'Maine' },
	{ Code: 'MD', Name: 'Maryland' },
	{ Code: 'MA', Name: 'Massachusetts' },
	{ Code: 'MI', Name: 'Michigan' },
	{ Code: 'MN', Name: 'Minnesota' },
	{ Code: 'MS', Name: 'Mississippi' },
	{ Code: 'MO', Name: 'Missouri' },
	{ Code: 'MT', Name: 'Montana' },
	{ Code: 'NE', Name: 'Nebraska' },
	{ Code: 'NV', Name: 'Nevada' },
	{ Code: 'NH', Name: 'New Hampshire' },
	{ Code: 'NJ', Name: 'New Jersey' },
	{ Code: 'NM', Name: 'New Mexico' },
	{ Code: 'NY', Name: 'New York' },
	{ Code: 'NC', Name: 'North Carolina' },
	{ Code: 'ND', Name: 'North Dakota' },
	{ Code: 'MP', Name: 'Northern Mariana Islands' },
	{ Code: 'OH', Name: 'Ohio' },
	{ Code: 'OK', Name: 'Oklahoma' },
	{ Code: 'OR', Name: 'Oregon' },
	{ Code: 'PA', Name: 'Pennsylvania' },
	{ Code: 'PR', Name: 'Puerto Rico' },
	{ Code: 'RI', Name: 'Rhode Island' },
	{ Code: 'SC', Name: 'South Carolina' },
	{ Code: 'SD', Name: 'South Dakota' },
	{ Code: 'TN', Name: 'Tennessee' },
	{ Code: 'TX', Name: 'Texas' },
	{ Code: 'TT', Name: 'Trust Territories' },
	{ Code: 'UT', Name: 'Utah' },
	{ Code: 'VT', Name: 'Vermont' },
	{ Code: 'VA', Name: 'Virginia' },
	{ Code: 'VI', Name: 'Virgin Islands' },
	{ Code: 'WA', Name: 'Washington' },
	{ Code: 'WV', Name: 'West Virginia' },
	{ Code: 'WI', Name: 'Wisconsin' },
	{ Code: 'WY', Name: 'Wyoming' },
];