import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { LinearProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Footer from '../../components/Footer';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
		.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
        .max(100, 'Password must not exceed 100 characters'),
    confirmPassword: Yup.string()
        .required('Confirm password is required')
		.oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
});

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
    paperStyle: {
        padding: 4,
        width: '50%',
        marginLeft: '25%',
        marginTop: 10,
        marginBottom: 10
    },
    formHeader: {
        textAlign: 'center',
        color: '#308cdc'
    },
    passwordResetButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        marginTop: 1,
        width: '223px'
    },
    cancelButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        marginTop: 1,
        width: '223px'
    }
};

function ResetPasswordPage() {
    let { key } = useParams();
    const loading = useSelector(state => state.authentication.loading);
    const errorCode = useSelector(state => state.authentication.errorCode);
    const dispatch = useDispatch();

    const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema)
    });
    
    useEffect(() => { 
        document.title = "DFQ - Reset Password";
        dispatch(userActions.validateResetPasswordKey(key));
    }, []);

    const onSubmit = data => {
        console.log(data);
        dispatch(userActions.resetPasswordWithKey(data, key));
    };

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
            <Container>
                <Paper elevation={3} sx={classes.paperStyle}>
                    {!errorCode ?
                        <>
                        <Typography variant="h3" sx={classes.formHeader}>Reset Password</Typography>
                        <Typography sx={{textAlign: 'center'}}>Please enter your new password below</Typography>
                        <form onSubmit={handleSubmit(onSubmit)} style={{textAlign: 'center'}}>
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        label="Password" 
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        type="password"
                                        {...field} 
                                        error={errors.password ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.password?.message}
                            </Typography>
                            <Controller
                                name="confirmPassword"
                                control={control}
                                defaultValue=""
                                render={({ field }) => 
                                    <TextField 
                                        label="Confirm Password" 
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        type="password"
                                        {...field} 
                                        error={errors.confirmPassword ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.confirmPassword?.message}
                            </Typography>
                            {!loading ?
                            <>
                            <Button type="submit" variant="contained" sx={classes.passwordResetButton}>Update Password</Button>
                            <br />
                            <Link to="/"><Button color="error" variant="outlined" sx={classes.cancelButton}>Cancel</Button></Link>
                            </>
                            : <LinearProgress />}
                        </form>
                        </>
                    : <Alert severity="error">Sorry the password reset link you have used is invalid.  Please request another password reset link.</Alert>}
                </Paper>
            </Container>
		</Container>
		<Footer />
		</>
	);
}

export { ResetPasswordPage };