import { loadConstants } from '../_constants';

const initialState = {
    loading: false,
    loadTemplatesList: [],
    loadingTemplate: false,
    loadingTemplateList: false,
    loadTemplateDetails: null,
    pickupTemplatesList: [],
    pickupTemplateDetails: null,
    deliveryTemplatesList: [],
    deliveryTemplateDetails: null,
    companyLoads: [],
    loadDetails: null,
    pickupsToDelete: [],
    deliveriesToDelete: []
};

export function load(state = initialState, action) {
    switch(action.type) {
        case loadConstants.CREATE_LOAD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case loadConstants.CREATE_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loadTemplateDetails: null
            };
        case loadConstants.CREATE_LOAD_FAILURE:
            return {
                ...state,
                loading: false
            };
        case loadConstants.GET_LOAD_TEMPLATES_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case loadConstants.GET_LOAD_TEMPLATES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                loadTemplatesList: action.loadTemplatesList
            };
        case loadConstants.GET_LOAD_TEMPLATES_LIST_FAILURE:
            return {
                ...state,
                loading: false
            };
        case loadConstants.GET_LOAD_TEMPLATE_DETAILS_REQUEST:
            return {
                ...state,
                loadingTemplate: true
            };
        case loadConstants.GET_LOAD_TEMPLATE_DETAILS_SUCCESS:
            return {
                ...state,
                loadingTemplate: false,
                loadTemplateDetails: action.loadTemplateDetails
            };
        case loadConstants.GET_LOAD_TEMPLATE_DETAILS_FAILURE:
            return {
                ...state,
                loadingTemplate: false
            };
        case loadConstants.GET_PICKUP_TEMPLATES_LIST_REQUEST:
            return {
                ...state,
                loadingTemplateList: true
            };
        case loadConstants.GET_PICKUP_TEMPLATES_LIST_SUCCESS:
            return {
                ...state,
                loadingTemplateList: false,
                pickupTemplatesList: action.pickupTemplatesList
            };
        case loadConstants.GET_PICKUP_TEMPLATES_LIST_FAILURE:
            return {
                ...state,
                loadingTemplateList: false
            }
        case loadConstants.GET_PICKUP_TEMPLATE_DETAILS_REQUEST:
            return {
                ...state,
                loadingTemplate: true
            }
        case loadConstants.GET_PICKUP_TEMPLATE_DETAILS_SUCCESS:
            return {
                ...state,
                loadingTemplate: false,
                pickupTemplateDetails: action.pickupTemplateDetails
            }
        case loadConstants.GET_PICKUP_TEMPLATE_DETAILS_FAILURE:
            return {
                ...state,
                loadingTemplate: false
            }
        case loadConstants.GET_DELIVERY_TEMPLATES_LIST_REQUEST:
            return {
                ...state,
                loadingTemplateList: true
            };
        case loadConstants.GET_DELIVERY_TEMPLATES_LIST_SUCCESS:
            return {
                ...state,
                loadingTemplateList: false,
                deliveryTemplatesList: action.deliveryTemplatesList
            };
        case loadConstants.GET_DELIVERY_TEMPLATES_LIST_FAILURE:
            return {
                ...state,
                loadingTemplateList: false
            }
        case loadConstants.GET_DELIVERY_TEMPLATE_DETAILS_REQUEST:
            return {
                ...state,
                loadingTemplate: true
            }
        case loadConstants.GET_DELIVERY_TEMPLATE_DETAILS_SUCCESS:
            return {
                ...state,
                loadingTemplate: false,
                deliveryTemplateDetails: action.deliveryTemplateDetails
            }
        case loadConstants.GET_DELIVERY_TEMPLATE_DETAILS_FAILURE:
            return {
                ...state,
                loadingTemplate: false
            }
        case loadConstants.GET_COMPANY_LOADS_REQUEST:
            return {
                ...state,
                loading: true,
                loadDetails: null,
                companyLoads: []
            }
        case loadConstants.GET_COMPANY_LOADS_SUCCESS:
            return {
                ...state,
                loading: false,
                companyLoads: action.companyLoads
            }
        case loadConstants.GET_COMPANY_LOADS_FAILURE:
            return {
                ...state,
                loading: false
            }
        case loadConstants.GET_LOAD_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case loadConstants.GET_LOAD_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                loadDetails: action.loadDetails
            }
        case loadConstants.GET_LOAD_DETAILS_FAILURE:
            return {
                ...state,
                loading: false
            }
        case loadConstants.EDIT_LOAD_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case loadConstants.EDIT_LOAD_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case loadConstants.EDIT_LOAD_DETAILS_FAILURE:
            return {
                ...state,
                loading: false
            }
        case loadConstants.CANCEL_LOAD_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case loadConstants.CANCEL_LOAD_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case loadConstants.CANCEL_LOAD_FAILURE:
            return {
                ...state,
                loading: false
            }
        case loadConstants.DELETE_LOAD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case loadConstants.DELETE_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case loadConstants.DELETE_LOAD_FAILURE:
            return {
                ...state,
                loading: false
            }
        case loadConstants.EDIT_PICKUPS_AND_DELIVERIES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case loadConstants.EDIT_PICKUPS_AND_DELIVERIES_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case loadConstants.EDIT_PICKUPS_AND_DELIVERIES_FAILURE:
            return {
                ...state,
                loading: false
            }
        case loadConstants.ADD_PICKUP_TO_DELETE:
            return {
                ...state,
                pickupsToDelete: [...state.pickupsToDelete, action.pickupToDelete]
            }
        case loadConstants.ADD_DELIVERY_TO_DELETE:
            return {
                ...state,
                deliveriesToDelete: [...state.deliveriesToDelete, action.deliveryToDelete]
            }
        case loadConstants.CLEAR_PICKUPS_DELIVERIES_TO_DELETE:
            return {
                ...state,
                pickupsToDelete: [],
                deliveriesToDelete: []
            }
        default:
            return state
    }
}