import axiosInstance from "./axiosInstance";
import { userActions } from "../_actions";
import { alertActions } from "../_actions";

const API_URL = process.env.REACT_APP_API_ENDPOINT;
  
const setupInterceptors = (store) => {

   /**
       * This interceptor's job is to attach the bearer token to every request that requires it so that we don't have
       * to do that explicitly for every service call.
   */
    axiosInstance.interceptors.request.use(
        (config) => {
            //get JWT Token if one exists
            const jwt = localStorage.getItem('jwtBearer');
            if (jwt) {
                // if it does exist then prep it and set it as the bearer token but only if we're not refreshing the token
                // or authenticating the user
                let noQuotesJwt = jwt.replace(/["]+/g, '');
                if(config.url !== "/user/Login"){
                    config.headers.Authorization = 'Bearer ' + noQuotesJwt;
                }
            }
            config.headers["Content-Type"] = 'application/json';
            return config;
        },
        (error) => {
            console.error(error);
            return Promise.reject(error);
        }
    );

    const { dispatch } = store;

    /**
        * This interceptor's responsibility is to detect an expired JWT token. This will be
        * completely transparent to the application.
     */
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            console.error(err);
            const originalConfig = err.config;

            if (originalConfig.url !== API_URL + "/user/LogIn" && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    window.history.pushState({}, undefined, `/sessionexpired`);
                    window.location.reload();
                }
            }
            return Promise.reject(err);
        }
    );
};

export default setupInterceptors;