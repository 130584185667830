import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, Controller  } from "react-hook-form";
import { numberWithCommas } from '../../_helpers';
import { Typography } from '@mui/material';
import NumericFormatCustom from '../NumericFormatCustom';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { FormControl, InputAdornment } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const classes = {
    formSubHeaders: {
        fontSize: 20,
        paddingBottom: 1
    },
    formSection: {
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        marginBottom: 2
    },
    datePickerStyle: {
        marginTop: 2,
    },
    selectInput: {
		width: '100%',
		marginTop: 2,
	},
    toolTipStyle: {
        color: '#2c88d9',
    }
};

const palletDimensionOptions = ['40"x48"', '48"x20"', '24"x40"', 'custom'];

function LoadInfo(){
    const [customPalletDimensions, setCustomPalletDimensions] = useState(false);
    const loading = useSelector(state => state.load.loading);
    const loadDetails = useSelector(state => state.load.loadDetails);

    const { control, register, reset, formState: { errors, submitCount }, setValue } = useFormContext();

    useEffect(() => { 
        if(loadDetails){
            palletDimensionOptions.includes(loadDetails.PalletDimensions) ? setCustomPalletDimensions(false) : setCustomPalletDimensions(true);
        }
    }, []);

    const IconWithTooltip = () => (
        <Tooltip arrow title="Click to view pallet dimension options" placement="top" sx={classes.toolTipStyle}>
            <IconButton onClick={resetCustomPalletDimensions}>
                <InfoIcon />
            </IconButton>
        </Tooltip>
    );

    const resetCustomPalletDimensions = () => {
        setCustomPalletDimensions(false);
        setValue('palletDimensions', '');
    };

    return (
        <>
            {!loading && loadDetails ?
            <>
            <Typography sx={classes.formSubHeaders}>Load Information</Typography>
            <Paper elevation={3} sx={classes.formSection}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="id"
                            control={control}
                            defaultValue={loadDetails ? loadDetails.Id : ""}
                            disabled
                            render={({ field }) => 
                                <TextField 
                                    label="Load No." 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.id ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.id?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="status"
                            control={control}
                            defaultValue={loadDetails ? loadDetails.LoadStatus : ""}
                            disabled
                            render={({ field }) => 
                                <TextField 
                                    label="Load Status" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.status ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.status?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="palletCount"
                            control={control}
                            defaultValue={loadDetails ? loadDetails.PalletCount : ""}
                            disabled
                            render={({ field }) => 
                                <TextField 
                                    label="Load Pallets" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.palletCount ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.palletCount?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="weight"
                            control={control}
                            defaultValue={loadDetails ? numberWithCommas(loadDetails.Weight) : ""}
                            disabled
                            render={({ field }) => 
                                <TextField 
                                    label="Load Weight (Lbs)" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.weight ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.weight?.message}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="quoteRequiredBy"
                            control={control}
                            defaultValue={loadDetails && loadDetails.QuoteRequiredBy ? dayjs(loadDetails.QuoteRequiredBy).format('MM/DD/YYYY h:mm A') : ''}
                            disabled
                            render={({ field }) => 
                                <TextField 
                                    label="Quote Required By" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.pickups ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.quoteRequiredBy?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="freightCost"
                            control={control}
                            defaultValue={loadDetails ? numberWithCommas(loadDetails.FreightCost) : ""}
                            render={({ field }) => 
                                <TextField 
                                    label="Freight Cost" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.freightCost ? true : false}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        inputComponent: NumericFormatCustom,
                                    }}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.freightCost?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="pickups"
                            control={control}
                            defaultValue={loadDetails ? loadDetails.Pickups : ""}
                            disabled
                            render={({ field }) => 
                                <TextField 
                                    label="Pickups" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.pickups ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.pickups?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="deliveries"
                            control={control}
                            defaultValue={loadDetails ? loadDetails.Deliveries : ""}
                            disabled
                            render={({ field }) => 
                                <TextField 
                                    label="Deliveries" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.deliveries ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.deliveries?.message}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="goodsClassification"
                            control={control}
                            defaultValue={loadDetails ? loadDetails.GoodsClassification : ""}
                            render={({ field }) => 
                                <FormControl fullWidth size="small">
                                    <InputLabel id="goodsClassificationLabel" sx={{marginTop: 2}}>Classification of Goods</InputLabel>
                                    <Select labelId="goodsClassificationLabel" id="goodsClassification" sx={classes.selectInput} {...field} error={errors.goodsClassification ? true : false} label="Classification of Goods">
                                        <MenuItem value="50">50 - Durable freight that fits on a standard 40" x 48" pallet</MenuItem>
                                        <MenuItem value="55">55 - Bricks, cement, hardwood flooring, construction materials</MenuItem>
                                        <MenuItem value="60">60 - Car accessories, car parts</MenuItem>
                                        <MenuItem value="65">65 - Car accessories and parts, boxed books, bottled drinks</MenuItem>
                                        <MenuItem value="70">70 - Car accessories and parts, auto engines, food items</MenuItem>
                                        <MenuItem value="77.5">77.5 - Tires, bathroom fixtures</MenuItem>
                                        <MenuItem value="85">85 - Crated machinery, cast iron stoves</MenuItem>
                                        <MenuItem value="92.5">92.5 - Computers, monitors, refrigerators</MenuItem>
                                        <MenuItem value="100">100 - Car covers, canvas, boat covers, wine cases, caskets</MenuItem>
                                        <MenuItem value="110">110 - Cabinets, framed art, table saws</MenuItem>
                                        <MenuItem value="125">125 - Small home appliances</MenuItem>
                                        <MenuItem value="150">150 - Auto sheet metal, bookcases</MenuItem>
                                        <MenuItem value="175">175 - Clothing, couches, stuffed furniture</MenuItem>
                                        <MenuItem value="200">200 - Sheet metal parts, aluminum tables, packaged mattresses, aircraft parts</MenuItem>
                                        <MenuItem value="250">250 - Mattresses and box springs, plasma TVs, bamboo furniture</MenuItem>
                                        <MenuItem value="300">300 - Model boats, assembled chairs, tables, wood cabinets</MenuItem>
                                        <MenuItem value="400">400 - Deer antlers</MenuItem>
                                        <MenuItem value="500">500 - Gold dust, ping pong balls</MenuItem>
                                        <MenuItem value="999">999 - Other</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.goodsClassification?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="palletHeight"
                            control={control}
                            defaultValue={loadDetails ? loadDetails.PalletHeight : ""}
                            render={({ field }) => 
                                <TextField 
                                    label="Pallet Height" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.palletHeight ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.palletHeight?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {!customPalletDimensions ? 
                            <>
                            <Controller
                                name="palletDimensions"
                                control={control}
                                defaultValue={loadDetails ? loadDetails.PalletDimensions : ""}
                                render={({ field }) => 
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="palletDimensionsLabel" sx={{marginTop: 2}}>Pallet Dimensions</InputLabel>
                                        <Select labelId="palletDimensionsLabel" id="palletDimensions" sx={classes.selectInput} {...field} error={errors.palletDimensions ? true : false} label="Pallet Dimensions"
                                            onChange={(e) => {
                                                if(e.target.value === 'custom'){
                                                    setCustomPalletDimensions(true);
                                                    setValue('palletDimensions', '');
                                                }else{
                                                    setValue('palletDimensions', e.target.value);
                                                }
                                            }}
                                        >
                                            {palletDimensionOptions.map((dimension) => (
                                                <MenuItem key={dimension} value={dimension}>{dimension}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.palletDimensions?.message}
                            </Typography>
                            </>
                        :
                            <>
                            <Controller
                                name="palletDimensions"
                                control={control}
                                defaultValue={loadDetails ? loadDetails.PalletDimensions : ""}
                                render={({ field }) => 
                                    <TextField 
                                        label="Pallet Dimensions" 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconWithTooltip />
                                                </InputAdornment>
                                            )
                                        }}
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        sx={{width: '100%'}} 
                                        {...field} 
                                        error={errors.palletDimensions ? true : false}
                                    />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.palletDimensions?.message}
                        </Typography>
                        </>
                    }
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="loadDescription"
                            control={control}
                            defaultValue={loadDetails ? loadDetails.LoadDescription : ""}
                            render={({ field }) => 
                                <TextField 
                                    label="Load Description" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.loadDescription ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.loadDescription?.message}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                        <Controller
                            name="freightPO"
                            control={control}
                            defaultValue={loadDetails ? loadDetails.FreightPO ? loadDetails.FreightPO : "" : ""}
                            render={({ field }) => 
                                <TextField 
                                    label="Freight PO" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    type="number"
                                    sx={{width: '100%'}} 
                                    {...field} 
                                    error={errors.freightPO ? true : false}
                                />
                            }
                        />
                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                            {errors.freightPO?.message}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
            </>
            : null}
        </>
    );
}

export default LoadInfo;