import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
	formHeader: {
		textAlign: 'center',
		color: '#2c88d9',
		marginTop: '1%',
		fontWeight: 'bold'
	},
	formSubHeaders: {
		color: 'black',
		fontWeight: 'bold'
	},
	signUpForm: {
		marginLeft: '33%',
		marginRight: '33%',
		marginTop: '1%',
		marginBottom: '2%',
		padding: 2,
		width: '33%'
	},
	formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
	selectInput: {
		width: '100%',
		marginTop: 2,
	},
	signUpButton: {
		width: '100%',
		marginTop: 3,
		backgroundColor: '#308cdc',
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
	},
	reviewText: {
		marginLeft: '33%',
		marginRight: '33%',
		marginBottom: '5%',
		fontWeight: 'bold'
	}
};

const invalidDomains = ['gmail', 'yahoo', 'hotmail', 'outlook', 'icloud', 'aol', 'msn'];

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First name is required'),
    lastName: Yup.string()
        .required('Last name is required'),
	email: Yup.string()
        .required('Email is required')
		.matches(/.+@.+\..+/, 'Please enter a valid email')
		.test(
			'Unauthorized Email Domain',
			'An unauthorized domain was entered. Please use your work email and avoid personal email services',
			(value) => {
				const currentDomain = value.substring(
					value.indexOf('@') + 1,
					value.indexOf('.')
				);

				return !invalidDomains.includes(currentDomain);
			}
		),
	phoneNumber: Yup.string()
        .required('Phone number is required'),
	password: Yup.string()
        .required('Password is required')
		.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
        .max(100, 'Password must not exceed 100 characters'),
	confirmPassword: Yup.string()
        .required('Confirm password is required')
		.oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
	companyName: Yup.string()
        .required('Company name is required'),
    companyCountry: Yup.string()
        .required('Country is required'),
	companyProvinceState: Yup.string()
        .required('Province/State is required'),
	companyCity: Yup.string()
        .required('City is required'),
	companyAddress: Yup.string()
        .required('Company address is required'),
	companyPostalZip: Yup.string()
        .required('Postal/zip code is required'),
	primaryContactPhone: Yup.string()
        .required('Phone number is required'),
	primaryContactEmail: Yup.string()
        .required('Email is required')
		.matches(/.+@.+\..+/, 'Please enter a valid email')
		.test(
			'Unauthorized Email Domain',
			'An unauthorized domain was entered. Please use your work email and avoid personal email services',
			(value) => {
				const currentDomain = value.substring(
					value.indexOf('@') + 1,
					value.indexOf('.')
				);

				return !invalidDomains.includes(currentDomain);
			}
		),
	companyLogo: Yup.mixed()
		.test(
			'File Size',
			'File size is too large',
			(value) => {
				if(value){
					console.log(value);
					console.log('File size is: ' + value[0].size);
					return value && value[0].size < 2000000; //2MB
				}else{
					return true;
				}
			}
		)
		.test(
			'File Type',
			'We only support jpg or png files',
			(value) => {
				if(value){
					console.log('File type is: ' + value[0].type);
					return value && value[0].type === 'image/jpeg' || value[0].type === 'image/png'; 
				}else{
					return true;
				}
			}
		),
});

const provinceList = [
	{ Code: 'AB', Name: 'Alberta' },
	{ Code: 'BC', Name: 'British Columbia' },
	{ Code: 'MB', Name: 'Manitoba' },
	{ Code: 'NB', Name: 'New Brunswick' },
	{ Code: 'NL', Name: 'Newfoundland and Labrador' },
	{ Code: 'NS', Name: 'Nova Scotia' },
	{ Code: 'ON', Name: 'Ontario' },
	{ Code: 'PE', Name: 'Prince Edward Island' },
	{ Code: 'QC', Name: 'Quebec' },
	{ Code: 'SK', Name: 'Saskatchewan' },
	{ Code: 'NT', Name: 'Northwest Territories' },
	{ Code: 'NU', Name: 'Nunavut' },
	{ Code: 'YT', Name: 'Yukon' },
];

const stateList = [
	{ Code: 'AL', Name: 'Alabama' },
	{ Code: 'AK', Name: 'Alaska' },
	{ Code: 'AZ', Name: 'Arizona' },
	{ Code: 'AR', Name: 'Arkansas' },
	{ Code: 'AS', Name: 'American Samoa' },
	{ Code: 'CA', Name: 'California' },
	{ Code: 'CO', Name: 'Colorado' },
	{ Code: 'CT', Name: 'Connecticut' },
	{ Code: 'DE', Name: 'Delaware' },
	{ Code: 'DC', Name: 'District of Columbia' },
	{ Code: 'FL', Name: 'Florida' },
	{ Code: 'GA', Name: 'Georgia' },
	{ Code: 'GU', Name: 'Guam' },
	{ Code: 'HI', Name: 'Hawaii' },
	{ Code: 'ID', Name: 'Idaho' },
	{ Code: 'IL', Name: 'Illinois' },
	{ Code: 'IN', Name: 'Indiana' },
	{ Code: 'IA', Name: 'Iowa' },
	{ Code: 'KS', Name: 'Kansas' },
	{ Code: 'KY', Name: 'Kentucky' },
	{ Code: 'LA', Name: 'Louisiana' },
	{ Code: 'ME', Name: 'Maine' },
	{ Code: 'MD', Name: 'Maryland' },
	{ Code: 'MA', Name: 'Massachusetts' },
	{ Code: 'MI', Name: 'Michigan' },
	{ Code: 'MN', Name: 'Minnesota' },
	{ Code: 'MS', Name: 'Mississippi' },
	{ Code: 'MO', Name: 'Missouri' },
	{ Code: 'MT', Name: 'Montana' },
	{ Code: 'NE', Name: 'Nebraska' },
	{ Code: 'NV', Name: 'Nevada' },
	{ Code: 'NH', Name: 'New Hampshire' },
	{ Code: 'NJ', Name: 'New Jersey' },
	{ Code: 'NM', Name: 'New Mexico' },
	{ Code: 'NY', Name: 'New York' },
	{ Code: 'NC', Name: 'North Carolina' },
	{ Code: 'ND', Name: 'North Dakota' },
	{ Code: 'MP', Name: 'Northern Mariana Islands' },
	{ Code: 'OH', Name: 'Ohio' },
	{ Code: 'OK', Name: 'Oklahoma' },
	{ Code: 'OR', Name: 'Oregon' },
	{ Code: 'PA', Name: 'Pennsylvania' },
	{ Code: 'PR', Name: 'Puerto Rico' },
	{ Code: 'RI', Name: 'Rhode Island' },
	{ Code: 'SC', Name: 'South Carolina' },
	{ Code: 'SD', Name: 'South Dakota' },
	{ Code: 'TN', Name: 'Tennessee' },
	{ Code: 'TX', Name: 'Texas' },
	{ Code: 'TT', Name: 'Trust Territories' },
	{ Code: 'UT', Name: 'Utah' },
	{ Code: 'VT', Name: 'Vermont' },
	{ Code: 'VA', Name: 'Virginia' },
	{ Code: 'VI', Name: 'Virgin Islands' },
	{ Code: 'WA', Name: 'Washington' },
	{ Code: 'WV', Name: 'West Virginia' },
	{ Code: 'WI', Name: 'Wisconsin' },
	{ Code: 'WY', Name: 'Wyoming' },
];

function SignUpPage() {
	const [selectedCountry, setSelectedCountry] = useState('Canada');
	const [fileValue, setFileValue] = useState('');
	const dispatch = useDispatch();
	const loading = useSelector(state => state.authentication.loading);

	const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema)
    });

	useEffect(() => { 
		document.title = "DFQ - Sign up";
		if(selectedCountry){
            renderProvinceStateList();
        }
    }, [selectedCountry]);

	const renderProvinceStateList = () => {
		if(selectedCountry === 'Canada'){
			return provinceList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Name }>{ state.Name }</MenuItem>);
			});
		}else if(selectedCountry === 'USA'){
			return stateList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Name }>{ state.Name }</MenuItem>);
			});
		}
    }

	const onSubmit = data => {
		//console.log(data);
        dispatch(userActions.signUp(data));
    };

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Typography variant="h2" sx={classes.formHeader}>Sign Up</Typography>
			<Paper elevation={3} sx={classes.signUpForm}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Typography variant='h5' sx={classes.formSubHeaders}>User Info</Typography>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="firstName"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="First Name" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.firstName ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.firstName?.message}
							</Typography>
						</Grid>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="lastName"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Last Name" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.lastName ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.lastName?.message}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="email"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Email" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.email ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.email?.message}
							</Typography>
						</Grid>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="phoneNumber"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Phone Number" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.phoneNumber ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.phoneNumber?.message}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="password"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Password" 
										type="password" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.password ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.password?.message}
							</Typography>
						</Grid>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="confirmPassword"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Confirm Password" 
										type="password" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.confirmPassword ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.confirmPassword?.message}
							</Typography>
						</Grid>
					</Grid>
					<Typography variant='h5' sx={classes.formSubHeaders}>Company Info</Typography>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="companyName"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Company Name" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.companyName ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.companyName?.message}
							</Typography>
						</Grid>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="companyCountry"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<FormControl fullWidth>
										<InputLabel id="countryLabel" sx={{marginTop: 1}}>Country</InputLabel>
										<Select size="small" labelId="countryLabel" id="country" sx={classes.selectInput} {...field} 
											onChange={(e) => {
												field.onChange(e); 
												setSelectedCountry(e.target.value); 
												setValue('companyProvinceState', '');
												if (submitCount > 0)
													trigger('companyProvinceState');
											}}
											error={errors.companyCountry ? true : false} label="Country"
										>
											<MenuItem value="Canada">Canada</MenuItem>
											<MenuItem value="USA">USA</MenuItem>
										</Select>
									</FormControl>
								}
                            />
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.companyCountry?.message}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="companyProvinceState"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<FormControl fullWidth>
										<InputLabel id="provinceStateLabel" sx={{marginTop: 1}}>Province/State</InputLabel>
										<Select size="small" labelId="provinceStateLabel" id="companyProvinceState" sx={classes.selectInput} {...field} error={errors.companyProvinceState ? true : false} label="Province/State">
											{renderProvinceStateList()}
										</Select>
									</FormControl>
								}
                            />
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.companyProvinceState?.message}
							</Typography>
						</Grid>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="companyCity"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Company City" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.companyCity ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.companyCity?.message}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="companyAddress"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Company Address" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.companyAddress ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.companyAddress?.message}
							</Typography>
						</Grid>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="companyPostalZip"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Company Postal/Zip Code" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.companyPostalZip ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.companyPostalZip?.message}
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="primaryContactPhone"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Primary Contact Phone" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.primaryContactPhone ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.primaryContactPhone?.message}
							</Typography>
						</Grid>
						<Grid item  xs={12} sm={6}>
							<Controller
								name="primaryContactEmail"
								control={control}
								defaultValue=""
								render={({ field }) => 
									<TextField 
										label="Primary Contact Email" 
										variant="outlined" 
										margin="normal" 
										size="small"
										sx={classes.formInput} 
										{...field} 
										error={errors.primaryContactEmail ? true : false}
									/>
								}
							/>
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.primaryContactEmail?.message}
							</Typography>
						</Grid>
					</Grid>
					<Typography variant='h5' sx={classes.formSubHeaders}>Company Logo</Typography>
					<br />
					<Grid container spacing={1} sx={classes.formRow}>
						<Grid item  xs={12} sm={6}>
							<Controller
                                name="companyLogo"
                                control={control}
                                defaultValue=""
                                render={({ field }) =>
                                    <input value={fileValue} accept="image/*" id="companyLogo" name="companyLogo" type="file" 
                                            onChange={(event)=> { setFileValue(event.target.value); field.onChange(event.target.files);}}
                                            error={errors.companyLogo ? 1 : 0} />
                                }
                            />
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.companyLogo?.message}
							</Typography>
						</Grid>
					</Grid>
					{!loading &&        
						<>  
							<Button
								type="submit"
								variant="contained"
								sx={classes.signUpButton}
							> 
								Sign Up
							</Button>
						</>   
						}
					{loading && <CircularProgress />}
				</form>
			</Paper>
			<Typography sx={classes.reviewText}>Once your form is submitted, you will be sent an email to confirm your email address and activate your account</Typography>
		</Container>
		<Footer />
		</>
	);
}

export { SignUpPage };